import {
  IconDefinition,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

type PowerFunction = {
  label?: string;
  icon?: IconDefinition;
  flowAlias?: string;
  voices?: PowerFunction[];
  nested?: PowerFunction[];
  additionalParams?: any;
};

type Props = {
  powerFunction: PowerFunction;
  updateSelectedTextWithFlowRes: any;
};

const EditorPowerFunction = ({
  powerFunction,
  updateSelectedTextWithFlowRes,
}: Props) => {
  const [showNested, setShowNested] = useState(false);
  const [hideNestedTimer, setHideNestedTimer] = useState(null);

  const { flowAlias, label, icon, voices, nested, additionalParams } =
    powerFunction;

  const delayedHideNested = () => {
    const hideTimer = setTimeout(() => {
      setShowNested(false);
    }, 300);
    // @ts-ignore
    setHideNestedTimer(hideTimer);
  };

  const handleMouseEnter = () => {
    if (nested) {
      setShowNested(true);
      hideNestedTimer && clearTimeout(hideNestedTimer);
    }
  };

  return (
    <div
      onClick={() =>
        flowAlias &&
        updateSelectedTextWithFlowRes(flowAlias, { ...additionalParams })
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => nested && delayedHideNested()}
      className="relative flex cursor-pointer flex-row items-center justify-between rounded-lg p-2 hover:bg-gray-100"
    >
      <div className="flex flex-row items-center space-x-2">
        {icon && <FontAwesomeIcon icon={icon} className="w-3" />}
        <span>{label}</span>
      </div>
      {nested && (
        <FontAwesomeIcon
          icon={faChevronRight}
          className="w-1.5 text-gray-700"
        />
      )}
      {nested && showNested && (
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={() => delayedHideNested()}
          className="absolute -top-1 left-full w-36 translate-x-2.5 rounded-md border border-gray-200 bg-white p-1"
        >
          {!!voices?.length && (
            <div className="mb-1 border-b border-gray-200 pb-1">
              <div className="cursor-default px-2 py-1 text-xs text-gray-500">
                Your brand voices
              </div>
              {voices.map((voiceFunction, index) => (
                <EditorPowerFunction
                  key={index}
                  powerFunction={voiceFunction}
                  updateSelectedTextWithFlowRes={updateSelectedTextWithFlowRes}
                />
              ))}
            </div>
          )}
          {nested.map((childFunction, index) => (
            <EditorPowerFunction
              key={index}
              powerFunction={childFunction}
              updateSelectedTextWithFlowRes={updateSelectedTextWithFlowRes}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default EditorPowerFunction;
