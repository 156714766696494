import { useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import NavBar from "./components/NavBar";
import FlowsPage from "./pages/FlowsPage";
import KnowledgeBasePage from "./pages/KnowledgeBasePage";
import VoicesPage from "./pages/VoicesPage";
import VoiceDetailPage from "./pages/VoiceDetailPage";
import ContentListPage from "./pages/ContentListPage";
import LoginPage from "./pages/LoginPage";
import { AuthContext } from "./providers/AuthProvider";
import ProtectedRoute from "./components/utils/ProtectedRoute";
import CreateFlowPage from "./pages/CreateFlowPage";
import FlowDetailPage from "./pages/FlowDetailPage";
import SettingsPage from "./pages/SettingsPage";
import HomePage from "./pages/HomePage";
import ChatPage from "./pages/ChatPage";
import PlansTab from "./components/settings/PlansTab";
import ProfileTab from "./components/settings/ProfileTab";
import IntegrationsTab from "./components/settings/IntegrationsTab";

const App = () => {
  const { isAdmin } = useContext(AuthContext);

  const modelitToken = localStorage.getItem("modelit-token");

  return (
    <Router>
      <Routes>
        <Route
          element={
            <ProtectedRoute isAllowed={!modelitToken} redirectPath="/home" />
          }
        >
          <Route path="/login" element={<LoginPage />} />
        </Route>
        <Route
          element={
            <ProtectedRoute isAllowed={!!modelitToken} redirectPath="/login" />
          }
        >
          <Route path="flows/:flowAlias" element={<FlowDetailPage />} />
          <Route path="content/:userContentId" element={<FlowDetailPage />} />
          <Route path="chat" element={<ChatPage />} />
          <Route path="chat/:chatThreadId" element={<ChatPage />} />
          <Route path="/*" element={<NavBar />}>
            <Route path="" element={<Navigate to="/home" />} />
            <Route path="home" element={<HomePage />} />
            <Route path="flows" element={<FlowsPage />} />
            <Route path="knowledge" element={<KnowledgeBasePage />}>
              <Route index element={<Navigate to="" />} />
              <Route
                path="integrations/:userIntegrationId"
                element={<KnowledgeBasePage />}
              />
              <Route path="*" element={<Navigate to="" />} />
            </Route>
            <Route path="voices" element={<VoicesPage />} />
            <Route path="voices/:id" element={<VoiceDetailPage />} />
            <Route path="content" element={<ContentListPage />} />
            <Route path="settings" element={<SettingsPage />}>
              <Route index element={<Navigate to="/settings/plans" />} />
              <Route path="plans" element={<PlansTab />} />
              <Route path="integrations" element={<IntegrationsTab />} />
              <Route path="profile" element={<ProfileTab />} />
              <Route path="*" element={<Navigate to="plans" />} />
            </Route>
            <Route element={<ProtectedRoute isAllowed={isAdmin} />}>
              <Route path="create-flow" element={<CreateFlowPage />} />
            </Route>
            <Route path="*" element={<Navigate to="/home" />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
