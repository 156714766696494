import { useEditor } from "@tiptap/react";
import Color from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import ListItem from "@tiptap/extension-list-item";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import BubbleMenu from "@tiptap/extension-bubble-menu";
import { Markdown } from "tiptap-markdown";
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'

type Props = {
  onEditorChange: any;
}

export const useCreateEditor = ({ onEditorChange }: Props) => {
  return useEditor({
    editorProps: {
      attributes: {
        class:
          "h-full w-full text-black px-2 pt-2 pb-20 outline-none !whitespace-pre-wrap",
      },
    },
    extensions: [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle,
      StarterKit,
      Underline,
      Link,
      BubbleMenu.configure({
        // @ts-ignore
        element: document.querySelector(".menu"),
      }),
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      Markdown.configure({
        linkify: true,
        breaks: false,
        transformPastedText: true,
        transformCopiedText: true,
      }),
    ],
    // enablePasteRules: false,
    content: "",
    parseOptions: {
      preserveWhitespace: "full",
    },
    onUpdate: ({ editor }) => {
      onEditorChange(editor);
    },
  });
} 