// @ts-nocheck
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBold,
  faItalic,
  faUnderline,
  faRotateLeft,
  faRotateRight,
  faLink,
  faChainBroken,
  faFont,
  faListUl,
  faListOl,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";

const MenuBarButton = ({ children, ...props }: any) => {
  return (
    <button
      className={`cursor-pointer rounded-md px-1 py-2 text-sm ${
        props.isActive
          ? "bg-gray-200 hover:bg-gray-100"
          : "text-gray-700 hover:bg-gray-100"
      }`}
      {...props}
    >
      {children}
    </button>
  );
};

const textSizes = ["Normal", "Heading 1", "Heading 2", "Heading 3"];
const textSizeOptions = textSizes.map((textSize) => ({
  value: textSize,
  label: textSize,
}));

const MenuBar = ({
  contentName,
  setContentName,
  editor,
  hideStyleButtons,
}: any) => {
  const navigate = useNavigate();
  const [selectedTextColor, setSelectedTextColor] = useState(null);

  const handleTextSizeChange = (e: any) => {
    switch (e.value) {
      case "Normal":
        editor.chain().focus().setParagraph().run();
        break;
      case "Heading 1":
        editor.chain().focus().toggleHeading({ level: 1 }).run();
        break;
      case "Heading 2":
        editor.chain().focus().toggleHeading({ level: 2 }).run();
        break;
      case "Heading 3":
        editor.chain().focus().toggleHeading({ level: 3 }).run();
        break;
    }
  };

  let currentTextSize = { value: "Normal", label: "Normal" };
  if (editor) {
    if (editor.isActive("heading", { level: 1 })) {
      currentTextSize = { value: "Heading 1", label: "Heading 1" };
    } else if (editor.isActive("heading", { level: 2 })) {
      currentTextSize = { value: "Heading 2", label: "Heading 2" };
    } else if (editor.isActive("heading", { level: 3 })) {
      currentTextSize = { value: "Heading 3", label: "Normal" };
    }
  }

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    if (url === null) {
      return;
    }

    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
      return;
    }

    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  const handleNameChange = (e) => {
    setContentName(e.target.value);
  };

  return (
    <div
      className={`flex flex-col justify-between border-b border-none border-gray-200 lg:flex-row lg:border-solid ${
        !hideStyleButtons && "!border-solid"
      }`}
    >
      <div className="flex items-center justify-between border-b border-gray-300 lg:justify-start lg:border-none">
        <FontAwesomeIcon
          icon={faArrowLeftLong}
          className="ml-6 w-4 cursor-pointer text-gray-500 hover:text-gray-700"
          onClick={() => navigate("/")}
        />
        <input
          className="p-4 text-center text-gray-600 outline-none lg:text-left"
          onChange={handleNameChange}
          value={contentName}
        />
        <div className="w-10 lg:hidden" />
      </div>
      <div
        className={`hidden flex-row flex-wrap items-center justify-center space-x-1 py-2 sm:px-2 lg:flex ${
          !hideStyleButtons && "!flex"
        }`}
      >
        <MenuBarButton
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          <FontAwesomeIcon icon={faRotateLeft} className="w-3 sm:w-6" />
        </MenuBarButton>
        <MenuBarButton
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          <FontAwesomeIcon icon={faRotateRight} className="w-3 sm:w-6" />
        </MenuBarButton>
        <div className="h-3/4 w-px bg-gray-300" />
        <Select
          options={textSizeOptions}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "white",
            },
          })}
          classNames={{
            container: () =>
              "!flex !bg-gray-50 !border-none !text-gray-700 !text-xs sm:!text-sm !rounded-md !outline-none",
            control: () =>
              "!border-none !cursor-pointer hover:!bg-gray-100 !w-24 sm:!w-32",
            option: () => "!text-gray-700 !cursor-pointer hover:!bg-sky-50",
            indicatorSeparator: () => "!hidden",
          }}
          required={true}
          defaultValue={textSizeOptions[0]}
          onChange={handleTextSizeChange}
          value={currentTextSize}
        />
        <div className="h-3/4 w-px bg-gray-300" />
        <div className="relative cursor-pointer rounded-md px-1 py-2 text-sm hover:bg-gray-100">
          <FontAwesomeIcon
            icon={faFont}
            className="w-3 sm:w-6"
            color={selectedTextColor}
          />
          <input
            className="absolute left-0 top-0 z-10 h-full w-full cursor-pointer opacity-0"
            type="color"
            onInput={(event) => {
              const color = event.target.value;
              editor.chain().focus().setColor(color).run();
              setSelectedTextColor(color);
            }}
            value={editor.getAttributes("textStyle").color}
          />
        </div>
        <MenuBarButton
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          isActive={editor.isActive("bold")}
        >
          <FontAwesomeIcon icon={faBold} className="w-3 sm:w-6" />
        </MenuBarButton>
        <MenuBarButton
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          isActive={editor.isActive("italic")}
        >
          <FontAwesomeIcon icon={faItalic} className="w-3 sm:w-6" />
        </MenuBarButton>
        <MenuBarButton
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={!editor.can().chain().focus().toggleUnderline().run()}
          isActive={editor.isActive("underline")}
        >
          <FontAwesomeIcon icon={faUnderline} className="w-3 sm:w-6" />
        </MenuBarButton>
        <div className="h-3/4 w-px bg-gray-300" />
        <MenuBarButton onClick={setLink} isActive={editor.isActive("link")}>
          <FontAwesomeIcon icon={faLink} className="w-3 sm:w-6" />
        </MenuBarButton>
        <MenuBarButton
          onClick={() => editor.chain().focus().unsetLink().run()}
          disabled={!editor.isActive("link")}
        >
          <FontAwesomeIcon icon={faChainBroken} className="w-3 sm:w-6" />
        </MenuBarButton>
        <div className="h-3/4 w-px bg-gray-300" />
        <MenuBarButton
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          isActive={editor.isActive("bulletList")}
        >
          <FontAwesomeIcon icon={faListUl} className="w-3 sm:w-6" />
        </MenuBarButton>
        <MenuBarButton
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          isActive={editor.isActive("orderedList")}
        >
          <FontAwesomeIcon icon={faListOl} className="w-3 sm:w-6" />
        </MenuBarButton>
      </div>
    </div>
  );
};

export default MenuBar;
