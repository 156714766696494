import { useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Editor } from "@tiptap/core";
import FlowForm from "../components/flowDetail/FlowForm";
import Tiptap from "../components/utils/Tiptap";
import debounce from "lodash.debounce";
import {
  GET_FLOW,
  GET_LATEST_FLOW_STEP_LOGS_FOR_CONTENT,
  GET_USER_CONTENT,
  GET_VOICES,
} from "../graphql/queries";
import {
  Flow,
  FlowStepLogEdge,
  UserContent,
  VoiceEdge,
} from "../graphql/types";
import { CREATE_USER_CONTENT, UPDATE_USER_CONTENT } from "../graphql/mutations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import MenuBar from "../components/flowDetail/MenuBar";
import PowerFlowList from "../components/flowDetail/PowerFlowList";
import { useCreateEditor } from "../utils/editorUtils";

const FlowDetailPage = () => {
  const { flowAlias, userContentId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showForm, setShowForm] = useState(true);
  const [showPowerFlowList, setShowPowerFlowList] = useState(false);
  const [userContent, setUserContent] = useState<UserContent>();
  const [contentName, setContentName] = useState<string>("Untitled");
  const [currentFlowStepLogId, setCurrentFlowStepLogId] = useState<
    string | null | undefined
  >();
  const [sessionDataHash, setSessionDataHash] = useState({});

  const [getUserContent] = useLazyQuery(GET_USER_CONTENT);
  const [getLatestFlowStepLogsForContent] = useLazyQuery(
    GET_LATEST_FLOW_STEP_LOGS_FOR_CONTENT,
  );
  const [getFlow, { data: flowData }] = useLazyQuery(GET_FLOW);
  const [getVoices, { data: voicesData }] = useLazyQuery(GET_VOICES);
  const [createUserContent] = useMutation(CREATE_USER_CONTENT);
  const [updateUserContent] = useMutation(UPDATE_USER_CONTENT);

  useEffect(() => {
    const asyncHandler = async () => {
      getVoices();
      const validContentId = userContentId || searchParams.get("contentId");
      if (validContentId) {
        const userContentRes = await getUserContent({
          variables: { id: validContentId },
        });
        const flowAliasFromContent =
          userContentRes?.data?.userContent?.lastFlowStep?.flow?.alias;
        const flowStepLogRes = await getLatestFlowStepLogsForContent({
          variables: {
            flowAlias: flowAliasFromContent,
            userContentId: validContentId,
          },
        });
        const restoredSessionData = {};
        flowStepLogRes?.data?.getLatestFlowStepLogsForContent?.edges?.forEach(
          (flowStepLogEdge: FlowStepLogEdge) => {
            const {
              id,
              flowStep,
              inputJson,
              generatedOutputJson,
              finalOutputJson,
              rating,
            } = flowStepLogEdge.node;
            const flowAliasFromLog = flowStep?.flow?.alias;
            // @ts-ignore
            restoredSessionData[flowAliasFromLog] =
              // @ts-ignore
              restoredSessionData[flowAliasFromLog] || {};
            // @ts-ignore
            restoredSessionData[flowAliasFromLog][flowStep.referenceId] = {
              flowStepLogId: id,
              input: inputJson,
              generatedOutput: generatedOutputJson,
              finalOutput: finalOutputJson,
              rating,
            };
          },
        );
        getFlow({ variables: { alias: flowAliasFromContent } });
        const content = userContentRes.data.userContent;
        setUserContent(content);
        setContentName(content.name);
        setSessionDataHash(restoredSessionData);
      } else if (flowAlias) {
        getFlow({ variables: { alias: flowAlias } });
      }
    };
    asyncHandler();
  }, []);

  const flow = flowData?.flow as Flow;
  const voiceEdges = voicesData?.voices?.edges as [VoiceEdge];

  const changeHandler = async ({
    editor,
    flow,
    contentName,
    currentFlowStepLogId,
  }: {
    editor: Editor;
    flow: Flow;
    contentName: string;
    currentFlowStepLogId: string | null | undefined;
  }) => {
    if (!flow) return;
    const output = editor?.storage?.markdown?.getMarkdown();

    if (output !== "") {
      const urlParams = new URLSearchParams(window.location.search);
      const contentId = urlParams.get("contentId");
      const validContentId = contentId || userContentId;
      if (validContentId) {
        await updateUserContent({
          variables: {
            input: {
              id: validContentId,
              name: contentName || undefined,
              output: output,
              lastFlowStepId: flow.flowStepIds[0],
            },
          },
        });
      } else {
        const newUserContent = await createUserContent({
          variables: {
            input: {
              output: output,
              name: contentName,
              lastFlowStepId: flow.flowStepIds[0],
              flowStepLogIds: currentFlowStepLogId
                ? [currentFlowStepLogId]
                : [],
            },
          },
        });
        setSearchParams(
          `contentId=${newUserContent.data.createUserContent.id}`,
        );
      }
    }
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    [],
  );

  const editor = useCreateEditor({
    onEditorChange: (editor: Editor) => {
      debouncedChangeHandler({
        editor,
        flow: flow!!,
        contentName,
        currentFlowStepLogId,
      });
    },
  });

  useEffect(() => {
    debouncedChangeHandler({
      editor: editor!!,
      flow: flow!!,
      contentName,
      currentFlowStepLogId,
    });
  }, [contentName, currentFlowStepLogId]);

  return (
    <div className="flex h-full flex-col">
      <MenuBar
        contentName={contentName}
        setContentName={setContentName}
        editor={editor}
        hideStyleButtons={showForm}
      />
      <div className="flex flex-1 flex-row overflow-hidden pb-16 lg:pb-0">
        <div
          className={`${
            showForm ? "lg:w-1/2" : "!w-0 !overflow-hidden"
          } relative flex w-full border-gray-200 transition-all duration-500 lg:border-r`}
        >
          {showPowerFlowList ? (
            <PowerFlowList
              getFlow={getFlow}
              setShowPowerFlowList={setShowPowerFlowList}
            />
          ) : (
            <FlowForm
              editor={editor}
              flow={flow}
              voiceEdges={voiceEdges}
              sessionDataHash={sessionDataHash}
              currentFlowStepLogId={currentFlowStepLogId}
              setCurrentFlowStepLogId={setCurrentFlowStepLogId}
              setShowPowerFlowList={setShowPowerFlowList}
              setShowForm={setShowForm}
            />
          )}
        </div>
        <div
          className={`${
            showForm ? "lg:w-1/2" : "w-full"
          } relative flex w-0 transition-all duration-500`}
        >
          <span
            onClick={() => setShowForm(!showForm)}
            className="absolute -left-3.5 top-5 z-10 hidden h-7 w-7 cursor-pointer items-center justify-center rounded-full border border-gray-100 bg-white text-gray-500 shadow-md hover:bg-gray-50 hover:text-gray-600 lg:flex"
          >
            <FontAwesomeIcon
              icon={showForm ? faChevronLeft : faChevronRight}
              className="h-3.5"
            />
          </span>
          <Tiptap
            editor={editor}
            initialContent={userContent?.output}
            voiceEdges={voiceEdges}
          />
        </div>
      </div>
      <div className="fixed bottom-6 left-1/2 -ml-20 flex w-40 justify-between rounded-full border border-gray-200 px-2 py-1 text-gray-500 lg:hidden">
        <button
          onClick={() => setShowForm(true)}
          className={`z-20 flex-1 cursor-pointer pl-2 text-left hover:text-gray-600 ${
            showForm && "text-modelit-purple"
          }`}
        >
          Inputs
        </button>
        <button
          onClick={() => setShowForm(false)}
          className={`z-20 flex-1 cursor-pointer pr-1 text-right hover:text-gray-600 ${
            !showForm && "text-modelit-purple"
          }`}
        >
          Content
        </button>
        <div
          className={`absolute left-0 top-0 h-full w-1/2 rounded-full border-none bg-modelit-purple opacity-25 transition-all duration-500 ${
            !showForm && "translate-x-20"
          }`}
        />
      </div>
    </div>
  );
};

export default FlowDetailPage;
