import { useMutation } from "@apollo/client";
import { useState } from "react";
import Select from "react-select";
import { CREATE_SUPPORT_TICKET } from "../../graphql/mutations";
import { useForm } from "react-hook-form";
import Loading from "../utils/Loading";
import { toast } from "sonner";

type Props = {
  closeModal: () => void;
};

const supportOptions = {
  feedback: {
    label: "Feedback",
    value: "feedback",
  },
  feature_request: {
    label: "Feature request",
    value: "feature_request",
  },
  bug_report: {
    label: "Bug report",
    value: "bug_report",
  },
};

const ContactSupportForm = ({ closeModal }: Props) => {
  const { register, handleSubmit } = useForm();
  const [requestType, setRequestType] = useState(
    Object.values(supportOptions)[0].value,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [createSupportTicket] = useMutation(CREATE_SUPPORT_TICKET, {
    onError(err) {
      setIsLoading(false);
    },
  });

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const res = await createSupportTicket({
      variables: {
        input: {
          status: "open",
          subject: data.description,
          ticketType: requestType,
        },
      },
    });
    if (res?.data?.createSupportTicket) {
      toast.success("Your request has been submitted.");
    }
    setIsLoading(false);
    closeModal();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-5 text-xs">
      <div className="flex flex-col">
        <label className="mb-2 font-semibold">Request type</label>
        <Select
          options={Object.values(supportOptions)}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#bae6fd",
              primary25: "#f0f9ff",
            },
          })}
          classNames={{
            container: () =>
              "!flex-1 !bg-gray-50 !border !border-gray-300 !text-gray-700 !text-sm !rounded-md",
            control: () => "!border-none !cursor-pointer",
            option: () => "!text-gray-700 !cursor-pointer",
          }}
          required={true}
          defaultValue={Object.values(supportOptions)[0]}
          onChange={(e: any) => setRequestType(e.value)}
        />
      </div>
      <div className="flex flex-col">
        <div className="mb-2 font-semibold">Description</div>
        <textarea
          className="rounded-lg border border-gray-300 p-2.5 outline-none"
          rows={2}
          placeholder="Provide details here"
          {...register("description", { required: true })}
        />
      </div>
      <div className="flex flex-row items-end justify-end space-x-2">
        <button
          onClick={closeModal}
          className="flex flex-row items-center justify-center rounded-lg border border-modelit-purple px-4 py-2 font-medium text-modelit-purple hover:bg-gray-50 disabled:pointer-events-none disabled:opacity-75"
          disabled={isLoading}
        >
          <div className="flex flex-row space-x-2">
            <span>Cancel</span>
          </div>
        </button>
        <button
          type="submit"
          className="flex flex-row items-center justify-center rounded-lg border border-modelit-purple bg-modelit-purple px-4 py-2 font-medium text-white hover:bg-indigo-700 disabled:pointer-events-none disabled:opacity-75"
          disabled={isLoading}
        >
          <div className="flex flex-row items-center justify-center space-x-2">
            <span>Submit</span>
            {isLoading && <Loading size="sm" />}
          </div>
        </button>
      </div>
    </form>
  );
};

export default ContactSupportForm;
