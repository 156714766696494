import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_USER_INTEGRATIONS } from "../../graphql/queries";
import Loading from "../utils/Loading";
import Modal from "../utils/Modal";
import { DeleteFormType, UserIntegrationEdge } from "../../graphql/types";
import Table from "../utils/Table";
import DeleteForm from "../modals/DeleteForm";

const IntegrationsTab = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletionId, setDeletionId] = useState<string>();

  const { loading, data } = useQuery(GET_USER_INTEGRATIONS);

  if (loading) return <Loading />;

  const tableData = data?.userIntegrations?.edges?.map(
    (userIntegrationEdge: UserIntegrationEdge) => ({
      rowData: {
        Source: (
          <div className="flex h-6 w-6 items-center justify-center">
            <img
              src={`/integrations/${
                userIntegrationEdge.node.integration?.iconString ||
                "google-drive-icon.png"
              }`}
            />
          </div>
        ),
        Account: userIntegrationEdge.node.accountName,
      },
      ellipsisPopup: (close: any) => (
        <div className="absolute -bottom-1 -right-4 z-40 flex max-w-fit translate-y-full flex-col rounded-md border bg-white p-2 text-sm shadow-md">
          <div
            onClick={() => {
              close();
              setDeletionId(userIntegrationEdge.node.id);
              setIsDeleteModalOpen(true);
            }}
            className="flex flex-1 cursor-pointer rounded-md py-2 pl-2 pr-20 hover:bg-gray-50"
          >
            Delete
          </div>
        </div>
      ),
    }),
  );

  return (
    <div className="flex flex-1 overflow-auto">
      <Table data={tableData} />
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete integration"
        size="sm"
      >
        <DeleteForm
          // @ts-ignore
          deletionId={deletionId}
          deleteFormType={DeleteFormType.DELETE_USER_INTEGRATION}
          closeModal={() => setIsDeleteModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default IntegrationsTab;
