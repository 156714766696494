import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { toast } from "sonner";
import {
  CREATE_UPCOMING_INVOICE,
  UPDATE_SUBSCRIPTION,
} from "../../graphql/mutations";
import Loading from "../utils/Loading";
import { GET_COMPANY_SUBSCRIPTION } from "../../graphql/queries";

type Props = {
  priceNode?: any;
  ctaText: string | null;
  planName: string;
  isYearly: boolean;
  closeModal: () => void;
};

const InvoiceForm = ({
  priceNode,
  ctaText,
  planName,
  isYearly,
  closeModal,
}: Props) => {
  const [createUpcomingInvoice, { loading, data: invoiceData }] = useMutation(
    CREATE_UPCOMING_INVOICE,
    {
      refetchQueries: [GET_COMPANY_SUBSCRIPTION],
    },
  );
  const [updateSubscription] = useMutation(UPDATE_SUBSCRIPTION, {
    refetchQueries: [GET_COMPANY_SUBSCRIPTION],
  });

  useEffect(() => {
    createUpcomingInvoice({
      variables: { priceId: priceNode?.id },
    });
  }, [priceNode]);

  if (loading) {
    return <Loading />;
  }

  const handleSubmit = async () => {
    if (!priceNode?.unitAmount) {
      await updateSubscription({
        variables: {
          input: {
            cancelAtPeriodEnd: true,
          },
        },
      });
    } else {
      await updateSubscription({
        variables: {
          input: {
            priceId: priceNode.id,
          },
        },
      });
    }
    closeModal();
  };

  return (
    <div className="flex flex-col space-y-4 py-2 text-sm">
      <div className="flex flex-row justify-between space-x-2 border-b border-gray-300 pb-4 text-gray-500">
        <div className="font-semibold">PLAN TYPE</div>
        <div>{planName}</div>
      </div>
      {invoiceData?.createUpcomingInvoice?.total !== undefined && (
        <div className="flex flex-row justify-between space-x-2 border-b border-gray-300 pb-4 text-gray-500">
          <div className="font-semibold">TOTAL</div>
          <div>
            ${Math.floor(invoiceData.createUpcomingInvoice.total / 100)}/
            {isYearly ? "year" : "month"}
          </div>
        </div>
      )}
      {invoiceData?.createUpcomingInvoice?.amountDue !== undefined && (
        <div className="flex flex-row justify-between space-x-2 border-b border-gray-300 pb-4 font-semibold">
          <div>Due today</div>
          <div>
            ${Math.floor(invoiceData.createUpcomingInvoice.amountDue / 100)}/
            {isYearly ? "year" : "month"}
          </div>
        </div>
      )}
      <div className="flex flex-row pt-4">
        <button
          onClick={handleSubmit}
          className="flex flex-1 cursor-pointer items-center justify-center rounded-lg bg-modelit-purple py-2 font-medium text-white hover:bg-indigo-700"
        >
          {ctaText}
        </button>
      </div>
    </div>
  );
};

export default InvoiceForm;
