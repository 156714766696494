import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import Popup from "./Popup";

type Props = {
  data: any;
};

const Table = ({ data }: Props) => {
  if (!data || !Array.isArray(data) || !data.length) return null;

  const headerKeys = Object.keys(data[0].rowData);
  const numColumns = headerKeys.length;

  const headers = headerKeys.map((headerKey: string, index: number) => (
    <th
      key={index}
      className={`flex flex-1 w-1/${numColumns + 1} font-semibold`}
    >
      {headerKey}
    </th>
  ));

  const rows = data.map((elem: any, elemIndex: number) => {
    const { rowData, rowOnClick, ellipsisPopup } = elem;
    return (
      <tr
        key={elemIndex}
        className={`flex h-12 flex-1 items-center border-b border-gray-200 hover:bg-gray-50 ${
          rowOnClick && "cursor-pointer"
        }`}
        onClick={rowOnClick}
      >
        <td className="w-10 items-center justify-center" />
        {headerKeys.map((headerKey: string, headerIndex: number) => (
          <td
            key={headerIndex}
            className={`flex h-full flex-1 items-center w-1/${
              numColumns + 1
            } max-w-10 line-clamp-1 overflow-x-hidden whitespace-nowrap`}
          >
            {rowData[headerKey]}
          </td>
        ))}
        <td className="flex w-10 justify-end pr-4 text-gray-600">
          <Popup popupFunction={ellipsisPopup}>
            <div className="z-10 cursor-pointer rounded-full px-2 py-0.5 hover:bg-gray-200 hover:text-gray-700">
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                size="lg"
                className="text-gray-500"
              />
            </div>
          </Popup>
        </td>
      </tr>
    );
  });

  return (
    <table className="flex text-xs">
      <thead className="flex">
        <tr className="flex flex-1 items-center border-b border-gray-200 pb-2 text-gray-500">
          <th className="flex w-10 items-center justify-center" />
          {headers}
          <th className="flex w-10" />
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

export default Table;
