import { useContext } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import logo from "../../assets/logo.png";
import googleLogo from "../../assets/google-logo.png";

const LoginForm = () => {
  const { logIn } = useContext(AuthContext);

  return (
    <div className="relative flex flex-1 flex-col">
      <img src={logo} className="absolute left-4 top-6 h-6" />
      <div className="flex flex-1 flex-col items-center justify-center">
        <div className="mb-1 text-2xl font-semibold">Sign up</div>
        <div className="mb-5 text-sm">
          Start creating on-brand content for free
        </div>
        <div
          className="flex w-3/4 cursor-pointer flex-row items-center justify-center space-x-4 rounded-xl border border-modelit-purple py-2 hover:bg-gray-50"
          onClick={logIn}
        >
          <img src={googleLogo} className="h-4" />
          <span>Sign up with Google</span>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
