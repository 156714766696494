import { useState } from "react";

type Props = {
  tooltipComponent: React.ReactNode;
  children: React.ReactNode;
};

const Tooltip = ({ tooltipComponent, children }: Props) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <div>
      <div
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        className="relative"
      >
        {children}
        {isShown && tooltipComponent}
      </div>
    </div>
  );
};

export default Tooltip;
