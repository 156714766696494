import { useState } from "react";

const CreateFlowMetafieldRow = ({ metafieldHash, metafieldKey }: any) => {
  const [metafield, setMetafield] = useState(metafieldHash.label);

  const handleChange = (e: any) => {
    const value = e.target.value;
    metafieldHash[metafieldKey] = value;
    setMetafield(value);
  };

  return (
    <div className="flex flex-row border-b border-dashed border-gray-300 py-2 text-xs">
      <div className="flex w-1/4 items-center break-words border-r border-gray-300 text-sm">
        {metafieldKey}
      </div>
      <textarea
        rows={4}
        className="ml-4 flex-1 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-modelit-navy"
        value={metafield}
        onChange={handleChange}
        required={true}
      />
    </div>
  );
};

export default CreateFlowMetafieldRow;
