import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GET_USER_CONTENTS } from "../../graphql/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Loading from "../utils/Loading";
import { DeleteFormType, UserContentEdge } from "../../graphql/types";
import Table from "../utils/Table";
import NoContentIcon from "../../assets/contentList/no-content-icon.png";
import { timeAgo } from "../../utils/textHelpers";
import Modal from "../utils/Modal";
import DeleteForm from "../modals/DeleteForm";

type Props = {
  isHomePage?: boolean;
};

const ContentList = ({ isHomePage }: Props) => {
  const navigate = useNavigate();
  const [contentSearchInput, setContentSearchInput] = useState<string>("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletionId, setDeletionId] = useState<string>();
  const [
    getUserContents,
    { loading: loadingContentList, data: userContentData },
  ] = useLazyQuery(GET_USER_CONTENTS);

  useEffect(() => {
    getUserContents();
  }, []);

  const handleContentSearchInputChange = (e: any) => {
    setContentSearchInput(e.target.value);
  };

  const handleContentSearchInputKeyDown = async (e: any) => {
    if (e.keyCode === 13) {
      await getUserContents({
        variables: { search: contentSearchInput },
      });
    }
  };

  const contentList = isHomePage
    ? userContentData?.userContents?.edges?.slice(0, 3)
    : userContentData?.userContents?.edges;

  const tableData = contentList?.map((userContent: UserContentEdge) => ({
    rowData: {
      Name: userContent.node.name || "Untitled",
      Flow: userContent.node.lastFlowStep?.flow.name,
      "Last modified": timeAgo(
        userContent.node.updatedAt || userContent.node.createdAt,
      ),
    },
    rowOnClick: () => navigate(`/content/${userContent.node.id}`),
    ellipsisPopup: (close: any) => (
      <div className="absolute -bottom-1 -right-5 z-40 flex max-w-fit translate-y-full flex-col rounded-md border bg-white p-2 text-sm shadow-md">
        <div
          onClick={() => {
            close();
            setDeletionId(userContent.node.id);
            setIsDeleteModalOpen(true);
          }}
          className="flex flex-1 cursor-pointer rounded-md py-2 pl-2 pr-20 hover:bg-gray-50"
        >
          Delete
        </div>
      </div>
    ),
  }));

  const showNoContentMessage = !loadingContentList && !tableData?.length;

  return (
    <div className="flex flex-1 flex-col space-y-6">
      <div
        className={`flex flex-col items-start justify-between lg:flex-row ${
          isHomePage && "lg:items-center"
        }`}
      >
        <div className="flex flex-col space-y-1">
          <span
            className={`text-2xl font-medium ${
              !isHomePage && "hidden lg:flex"
            }`}
          >
            {isHomePage ? "Recent Content" : "Content"}
          </span>
          {!isHomePage && (
            <span className="text-sm">
              Revisit content you've previously created in your flows.
            </span>
          )}
        </div>
        <div className="my-3 flex w-1/3 w-full flex-row items-center space-x-1 overflow-hidden rounded-md border border-gray-300 px-2 lg:w-auto">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="h-3 w-3 text-gray-300"
          />
          <input
            className="w-3/4 rounded-md px-1 py-2 text-sm placeholder-gray-400 outline-none md:w-auto"
            placeholder="Search files"
            value={contentSearchInput}
            onChange={handleContentSearchInputChange}
            onKeyDown={handleContentSearchInputKeyDown}
          />
        </div>
      </div>
      {loadingContentList ? <Loading /> : <Table data={tableData} />}
      {showNoContentMessage && (
        <div className="flex flex-1 flex-col items-center justify-center space-y-1 text-center">
          <img src={NoContentIcon} className="h-20" />
          <div className="pt-1 text-lg font-medium">
            You haven't generated any content yet
          </div>
          <div className="text-gray-600">
            Start from a flow to create new content
          </div>
        </div>
      )}
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete content"
        size="sm"
      >
        <DeleteForm
          // @ts-ignore
          deletionId={deletionId}
          deleteFormType={DeleteFormType.DELETE_USER_CONTENT}
          closeModal={() => setIsDeleteModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default ContentList;
