import { useState } from "react";
import { Flow, VoiceEdge } from "../../graphql/types";
import FlowStepForm from "./FlowStepForm";
import { Editor } from "@tiptap/react";
import { ReactComponent as MenuIcon } from "../../assets/flowDetail/menu-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

type Props = {
  editor?: Editor | null;
  flow: Flow;
  voiceEdges: [VoiceEdge];
  sessionDataHash: any;
  currentFlowStepLogId?: string | null;
  setCurrentFlowStepLogId: any;
  setShowPowerFlowList: any;
  setShowForm: any;
};

const FlowForm = ({
  editor,
  flow,
  voiceEdges,
  sessionDataHash,
  currentFlowStepLogId,
  setCurrentFlowStepLogId,
  setShowPowerFlowList,
  setShowForm,
}: Props) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const sortedFlowSteps = flow?.flowSteps?.edges
    ?.filter((edge) => flow?.flowStepIds.includes(edge.node.id))
    .sort(
      (a, b) =>
        flow?.flowStepIds.indexOf(a.node.id) -
        flow?.flowStepIds.indexOf(b.node.id),
    );
  const isMultistep = sortedFlowSteps && sortedFlowSteps?.length > 1;

  const setToPreviousStep = () => {
    setCurrentStepIndex(currentStepIndex - 1);
  };

  const setToNextStep = () => {
    setCurrentStepIndex(currentStepIndex + 1);
  };

  const flowStepLadder = !!isMultistep && (
    <div className="flex flex-row items-center space-x-2">
      {sortedFlowSteps.map((sortedFlowStep, index) => (
        <div className="flex flex-row items-center space-x-2">
          <span className={`${index === currentStepIndex && "font-medium"}`}>
            {sortedFlowStep.node.name}
          </span>
          {index !== sortedFlowSteps?.length - 1 && (
            <FontAwesomeIcon
              icon={faArrowRight}
              className="h-4 text-gray-500"
            />
          )}
        </div>
      ))}
    </div>
  );

  return (
    <div className="flex flex-1 overflow-y-auto px-6 py-4">
      <div className="flex flex-1 flex-col space-y-10">
        <div className="flex flex-col font-medium">
          <div className="flex flex-row items-center justify-between text-3xl">
            <div>{flow?.name}</div>
            <button
              onClick={() => setShowPowerFlowList(true)}
              className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-gray-400 hover:bg-gray-500"
            >
              <MenuIcon className="h-3.5 w-3.5" />
            </button>
          </div>
        </div>
        {!!sortedFlowSteps && !!sortedFlowSteps.length && (
          <FlowStepForm
            flowStep={sortedFlowSteps[currentStepIndex].node}
            flowAlias={flow.alias}
            sessionDataHash={sessionDataHash}
            hasPreviousStep={!!sortedFlowSteps[currentStepIndex - 1]}
            hasNextStep={!!sortedFlowSteps[currentStepIndex + 1]}
            setToPreviousStep={setToPreviousStep}
            setToNextStep={setToNextStep}
            currentFlowStepLogId={currentFlowStepLogId}
            setCurrentFlowStepLogId={setCurrentFlowStepLogId}
            flowStepLadder={flowStepLadder}
            voiceEdges={voiceEdges}
            editor={editor}
            setShowForm={setShowForm}
          />
        )}
      </div>
    </div>
  );
};

export default FlowForm;
