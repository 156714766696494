import React from "react";
import ReactDOM from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import App from "./App";
import AuthProvider from "./providers/AuthProvider";
import IntegrationProvider from "./providers/IntegrationProvider";
import { Toaster } from "sonner";
import * as amplitude from "@amplitude/analytics-browser";
import { library } from "@fortawesome/fontawesome-svg-core";
import * as SolidIcons from "@fortawesome/free-solid-svg-icons";
import {
  IconDefinition as SolidIconDefinition,
  IconPrefix as SolidIconPrefix,
  IconPack as SolidIconPack,
} from "@fortawesome/free-solid-svg-icons";
import apolloClient from "./graphql/apolloClient";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

type SolidIconDefinitionOrPack = SolidIconDefinition | SolidIconPack;

interface ImportedSolidIcons {
  [key: string]: SolidIconPrefix | SolidIconDefinitionOrPack;
}

const solidIconList = Object.keys(SolidIcons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => (SolidIcons as ImportedSolidIcons)[icon]);

library.add(...(solidIconList as SolidIconDefinitionOrPack[]));

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// @ts-ignore
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, null, {
  defaultTracking: {
    pageViews: true,
  },
});

const app = initializeApp(firebaseConfig);
getAuth(app);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Toaster richColors position="top-center" />
    <ApolloProvider client={apolloClient}>
      <AuthProvider>
        <IntegrationProvider>
          <App />
        </IntegrationProvider>
      </AuthProvider>
    </ApolloProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
