import { useContext, useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faChevronDown,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@apollo/client";
import { GET_COMPANY_USAGE } from "../graphql/queries";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { AuthContext } from "../providers/AuthProvider";
import Popup from "./utils/Popup";
import { ReactComponent as HomeIcon } from "../assets/navbar/home-icon.svg";
import { ReactComponent as FlowIcon } from "../assets/navbar/flow-icon.svg";
import { ReactComponent as ContentIcon } from "../assets/navbar/content-icon.svg";
import { ReactComponent as KnowledgeIcon } from "../assets/navbar/knowledge-icon.svg";
import { ReactComponent as VoiceIcon } from "../assets/navbar/voice-icon.svg";
import { ReactComponent as ChatIcon } from "../assets/navbar/chat-icon.svg";
import logo from "../assets/logo.png";
import { WEB_APP_URL } from "../config/constants";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Modal from "./utils/Modal";
import AddDocumentForm from "./modals/AddDocumentForm";
import { AddDocumentType } from "../graphql/types";
import ContactSupportForm from "./modals/ContactSupportForm";
import Tooltip from "./utils/Tooltip";
import RequestFlowCreationForm from "./modals/RequestFlowCreationForm";

const tabData = {
  // Needs to be above voices for the regex matching
  voiceDetail: {
    title: "Voices",
    icon: null,
    path: "/voices/",
    modalTitle: "Add to voice",
    addDocumentType: AddDocumentType.ADD_TO_VOICE,
    hidden: true,
  },
  settings: {
    title: "Settings",
    icon: null,
    path: "/settings/plans",
    modalTitle: null,
    addDocumentType: null,
    hidden: true,
  },
  home: {
    title: "Home",
    icon: <HomeIcon />,
    path: "/home",
    modalTitle: null,
    addDocumentType: null,
    hidden: false,
  },
  flows: {
    title: "Flows",
    icon: <FlowIcon />,
    path: "/flows",
    modalTitle: null,
    addDocumentType: null,
    hidden: false,
    requestFlowCreation: true,
  },
  content: {
    title: "Content",
    icon: <ContentIcon />,
    path: "/content",
    modalTitle: null,
    addDocumentType: null,
    hidden: false,
  },
  knowledge: {
    title: "Knowledge",
    icon: <KnowledgeIcon />,
    path: "/knowledge",
    modalTitle: "Add to knowledge",
    addDocumentType: AddDocumentType.NEW_KNOWLEDGE_DOC,
    hidden: false,
  },
  voices: {
    title: "Voices",
    icon: <VoiceIcon />,
    path: "/voices",
    modalTitle: "Create a voice",
    addDocumentType: AddDocumentType.NEW_VOICE,
    hidden: false,
  },
  chat: {
    title: "Chat",
    icon: <ChatIcon />,
    path: "/chat",
    modalTitle: null,
    addDocumentType: null,
    hidden: false,
  },
};

const adminTabData = {
  "create-flow": {
    title: "Create Flow",
    icon: faPenToSquare,
    path: "/create-flow",
  },
};

const NavBar = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;

  const { firebaseUser, isAdmin, logOut } = useContext(AuthContext);
  const [currentRoute, setCurrentRoute] = useState(pathname);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isRequestFlowCreationModalOpen, setIsRequestFlowCreationModalOpen] =
    useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [queryParameters] = useSearchParams();
  const { data: companyUsageData } = useQuery(GET_COMPANY_USAGE);

  const handleTabClick = (path: string) => {
    if (isMobileMenuOpen) {
      toggleMobileMenu();
    }
    navigate(path);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const pathname = location.pathname;
    setCurrentRoute(pathname);
  }, [location]);

  const handleAuthClick = () => {
    logOut();
  };

  const remainingFlowsCount =
    companyUsageData?.company?.usage?.aiAction?.periodLimit !== null &&
    companyUsageData?.company?.usage?.aiAction?.periodUsage !== null &&
    companyUsageData?.company?.usage?.aiAction?.periodLimit -
      companyUsageData?.company?.usage?.aiAction?.periodUsage;

  const tabComponents = Object.values(tabData).map((tab, index) => {
    if (tab.hidden) return null;
    const selectedStyles = currentRoute.includes(tab.path)
      ? "bg-indigo-100 !text-modelit-purple shadow-sm"
      : "";
    return (
      <li
        key={index}
        onClick={() => handleTabClick(tab.path)}
        className={`flex cursor-pointer items-center justify-center space-x-4 rounded-md py-2 pl-4 hover:bg-gray-100 ${selectedStyles}`}
      >
        {tab.icon}
        <span className="w-52 flex-1">{tab.title}</span>
      </li>
    );
  });

  const adminTabComponents = Object.keys(adminTabData).map((routeName) => {
    // @ts-ignore
    const tab = adminTabData[routeName];
    const selectedStyles = currentRoute.includes(routeName)
      ? "bg-indigo-100 !text-modelit-purple shadow-sm"
      : "";
    return (
      <li
        key={routeName}
        onClick={() => handleTabClick(routeName)}
        className={`cursor-pointer space-x-4 rounded-md py-2 pl-4 pr-20 hover:bg-gray-100 ${selectedStyles}`}
      >
        <FontAwesomeIcon icon={tab.icon} />
        <span className="w-52 flex-1">{tab.title}</span>
      </li>
    );
  });

  const currentTabData = Object.values(tabData).find((tab) =>
    currentRoute.includes(tab.path),
  );

  const userName = firebaseUser
    ? `${firebaseUser.displayName && firebaseUser.displayName.split(" ")[0]}'s`
    : "My";

  const popupFunction = (close: any) => (
    <div className="absolute -right-2 top-0 z-50 flex max-w-fit -translate-y-full flex-col rounded-md border bg-white p-2 text-sm shadow-md">
      <div
        className="flex flex-1 cursor-pointer rounded-md py-2 pl-2 pr-20 hover:bg-gray-50"
        onClick={() => {
          close();
          if (isMobileMenuOpen) {
            toggleMobileMenu();
          }
          navigate("/settings/plans");
        }}
      >
        Settings
      </div>
      <div
        className="flex flex-1 cursor-pointer rounded-md py-2 pl-2 pr-2 hover:bg-gray-50"
        onClick={() => {
          close();
          if (isMobileMenuOpen) {
            toggleMobileMenu();
          }
          setIsContactModalOpen(true);
        }}
      >
        Contact support
      </div>
      <div
        className="flex flex-1 cursor-pointer rounded-md py-2 pl-2 pr-20 text-red-500 hover:bg-gray-50"
        onClick={handleAuthClick}
      >
        Log out
      </div>
    </div>
  );

  const tooltipComponent = (
    <div className="absolute -left-4 -top-1 z-50 flex w-48 -translate-x-5 -translate-y-full rounded-md border bg-gray-700 p-2 text-xs text-white shadow-md">
      Because you have a Basic account, you have a limited number of flows you
      can run each month.
    </div>
  );

  return (
    <div className="flex h-full flex-1 overflow-hidden">
      {isMobileMenuOpen && (
        <div
          onClick={toggleMobileMenu}
          className="fixed bottom-0 left-0 right-0 top-0 z-30 bg-black opacity-25"
        />
      )}
      <div
        className={`fixed left-0 top-0 z-40 flex h-full flex-col justify-between overflow-hidden border-r border-gray-200 bg-white lg:static lg:w-80 ${
          isMobileMenuOpen ? "w-80" : "w-0"
        } transition-all duration-500`}
      >
        <a
          href={`${WEB_APP_URL}/${queryParameters.toString()}`}
          className="flex px-4 py-6"
        >
          <img src={logo} className="h-6" />
        </a>
        <ul className="flex flex-1 flex-col space-y-4 p-4">{tabComponents}</ul>
        {isAdmin && (
          <ul className="flex flex-col space-y-4 px-4">{adminTabComponents}</ul>
        )}
        {remainingFlowsCount !== false && !isNaN(remainingFlowsCount) && (
          <div className="m-4 flex flex-col space-y-4 rounded-lg border border-gray-300 p-4 text-sm">
            <div className="flex flex-row items-center space-x-2">
              <span>{remainingFlowsCount} flows remaining</span>
              <Tooltip tooltipComponent={tooltipComponent}>
                <div className="flex h-3 w-3 items-center justify-center rounded-full border border-gray-500">
                  <FontAwesomeIcon
                    icon={faInfo}
                    className="h-2 text-gray-500"
                  />
                </div>
              </Tooltip>
            </div>
            <button
              onClick={() => {
                navigate(`/settings/plans`);
              }}
              className="cursor-pointer rounded-xl bg-modelit-purple py-2.5 text-center font-medium text-white hover:bg-indigo-700"
            >
              Upgrade account
            </button>
          </div>
        )}
        <div className="flex flex-row items-center justify-between border-t border-gray-200 p-4">
          <div className="flex items-center space-x-3">
            {firebaseUser ? (
              <img
                src={firebaseUser.photoURL}
                className="h-6 w-6 rounded-full"
              />
            ) : (
              <FontAwesomeIcon
                icon={faUserCircle}
                className="w-8 text-gray-600"
                size="xl"
              />
            )}
            <span className="text-sm">{`${userName} Workspace`}</span>
          </div>
          <Popup popupFunction={popupFunction}>
            <FontAwesomeIcon
              icon={faChevronDown}
              className="w-8 cursor-pointer text-gray-600 hover:text-black"
            />
          </Popup>
        </div>
      </div>
      <div className="flex flex-1 flex-col overflow-auto">
        <div className="flex items-center justify-between border-b border-gray-300 px-5 py-4 font-semibold lg:hidden">
          <button
            onClick={toggleMobileMenu}
            className="cursor-pointer text-gray-600 hover:text-black focus:outline-none"
          >
            <FontAwesomeIcon icon={faBars} className="w-6" />
          </button>
          <span className="text-lg">{currentTabData?.title}</span>
          <div className="w-6">
            {currentTabData?.modalTitle && (
              <div
                onClick={() => setIsAddModalOpen(true)}
                className="cursor-pointer text-sm text-modelit-purple hover:text-indigo-700"
              >
                Add
              </div>
            )}
            {
              // @ts-ignore
              currentTabData?.requestFlowCreation && (
                <div
                  onClick={() => setIsRequestFlowCreationModalOpen(true)}
                  className="cursor-pointer text-sm text-modelit-purple hover:text-indigo-700"
                >
                  Add
                </div>
              )
            }
          </div>
          {currentTabData?.addDocumentType && (
            <Modal
              isOpen={isAddModalOpen}
              onClose={() => setIsAddModalOpen(false)}
              title={currentTabData.modalTitle}
              size="md"
            >
              <AddDocumentForm
                addDocumentType={currentTabData.addDocumentType}
                closeModal={() => setIsAddModalOpen(false)}
              />
            </Modal>
          )}
          {
            // @ts-ignore
            currentTabData?.requestFlowCreation && (
              <Modal
                isOpen={isRequestFlowCreationModalOpen}
                onClose={() => setIsRequestFlowCreationModalOpen(false)}
                title={"Create custom flow"}
                size="md"
              >
                <RequestFlowCreationForm />
              </Modal>
            )
          }
        </div>
        <Modal
          isOpen={isContactModalOpen}
          onClose={() => setIsContactModalOpen(false)}
          title="Contact support"
          size="md"
        >
          <ContactSupportForm closeModal={() => setIsContactModalOpen(false)} />
        </Modal>
        <Outlet />
      </div>
    </div>
  );
};

export default NavBar;
