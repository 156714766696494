export const extractDomainFromURL = (url: string) => {
  let domain = "";
  var domainRegex = /^(?:https?:\/\/)?(?:www\.)?([^:/\n.]+)/;
  var match = url.match(domainRegex);
  if (match && match.length > 1) {
    domain = match[1];
  }
  return domain.charAt(0).toUpperCase() + domain.substring(1);
};

export const timeAgo = (datetime: string) => {
  if (!datetime || datetime === "") return "-";

  const date = new Date(datetime);
  const now = new Date();
  const secondsPast = (now.getTime() - date.getTime()) / 1000;

  if (secondsPast < 60){
    const second = Math.floor(secondsPast);
    return second > 1 ? second + " seconds ago" : "1 second ago";
  }
  if (secondsPast < 3600){
    const minute = Math.floor(secondsPast/60);
    return minute > 1 ? minute + " minutes ago" : "1 minute ago";
  }
  if (secondsPast <= 86400){
    const hour = Math.floor(secondsPast/3600);
    return hour > 1 ? hour + " hours ago" : "1 hour ago";
  }
  if (secondsPast > 86400){
    const day = Math.floor(secondsPast/86400);
    return day > 1 ? day + " days ago" : "Yesterday";
  }
}

export const interpolateString = (str: string, obj: any): any => {
  return str.replace(/#\{([^}]+)\}/g, (match, path) => {
    const parts = path.split(".");
    let currentObj = obj;

    for (let part of parts) {
      if (currentObj[part] !== undefined) {
        currentObj = currentObj[part];
      } else if (part === 'output') {
        currentObj = (currentObj["finalOutput"] || currentObj["generatedOutput"]).value;
      } else {
        return match;
      }
    }

    return currentObj;
  });
}