import { useState } from "react";
import Select from "react-select";
import { ParameterType } from "../../graphql/types";

const CreateFlowInputRow = ({ inputFieldProps }: any) => {
  const [label, setLabel] = useState(inputFieldProps.label);
  const [description, setDescription] = useState(inputFieldProps.description);
  const [type, setType] = useState(inputFieldProps.type);
  const [isArray, setIsArray] = useState(inputFieldProps.isArray);
  const [isRequired, setIsRequired] = useState(inputFieldProps.isRequired);
  const [defaultValue, setDefaultValue] = useState(
    inputFieldProps.defaultValue,
  );
  const [placeholder, setPlaceholder] = useState(inputFieldProps.placeholder);
  const [prefix, setPrefix] = useState(inputFieldProps.prefix);
  const [ordering, setOrdering] = useState(inputFieldProps.ordering);

  const handleChange = (fieldName: string, e: any) => {
    const value = e.target ? e.target.value : e.value;
    switch (fieldName) {
      case "label":
        inputFieldProps[fieldName] = value;
        setLabel(value);
        break;
      case "description":
        inputFieldProps[fieldName] = value;
        setDescription(value);
        break;
      case "type":
        inputFieldProps[fieldName] = value;
        setType(value);
        break;
      case "isArray":
        inputFieldProps[fieldName] = value === "true";
        setIsArray(value === "true");
        break;
      case "isRequired":
        inputFieldProps[fieldName] = value === "true";
        setIsRequired(value === "true");
        break;
      case "defaultValue":
        inputFieldProps[fieldName] = value;
        setDefaultValue(value);
        break;
      case "placeholder":
        inputFieldProps[fieldName] = value;
        setPlaceholder(value);
        break;
      case "prefix":
        inputFieldProps[fieldName] = value;
        setPrefix(value);
        break;
      case "ordering":
        inputFieldProps[fieldName] = value;
        setOrdering(value);
        break;
      default:
        break;
    }
  };

  const typeOptions = Object.values(ParameterType).map((parameterType) => ({
    value: parameterType,
    label: parameterType.toLowerCase(),
  }));

  return (
    <div className="flex flex-row border-b border-dashed border-gray-300 py-2 text-xs">
      <div className="flex w-1/4 flex-col items-center justify-center break-words border-r border-gray-300 text-sm">
        <div>{inputFieldProps.key}</div>
        <input
          className="w-10 rounded-lg border border-gray-300 p-1 text-center text-sm text-modelit-navy"
          type="number"
          value={ordering}
          onChange={(e) => handleChange("ordering", e)}
          required={true}
        />
      </div>
      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 flex-col">
          <div className="flex flex-row items-center p-2">
            <label className="w-1/4">Label:</label>
            <input
              className="flex-1 rounded-lg border border-gray-300 bg-gray-50 p-1 text-sm text-modelit-navy"
              value={label}
              onChange={(e) => handleChange("label", e)}
              required={true}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row items-center p-2">
            <label className="w-1/4">Description:</label>
            <input
              className="flex-1 rounded-lg border border-gray-300 bg-gray-50 p-1 text-sm text-modelit-navy"
              value={description}
              onChange={(e) => handleChange("description", e)}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row items-center p-2">
            <label className="w-1/4">Type:</label>
            <Select
              options={typeOptions}
              classNames={{
                container: (state) =>
                  "!flex-1 !bg-gray-50 !border !border-gray-300 !text-modelit-navy !text-sm !rounded-lg",
                control: (state) => "!border-none",
                option: (state) => "!text-modelit-navy",
              }}
              required={true}
              onChange={(e) => handleChange("type", e)}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row items-center p-2">
            <label className="w-1/4">Is Array?:</label>
            <div className="flex flex-1 items-center justify-center">
              <div className="flex flex-row space-x-4">
                <label className="flex items-center space-x-1">
                  <input
                    type="radio"
                    value="true"
                    checked={isArray}
                    onChange={(e) => handleChange("isArray", e)}
                    required={true}
                  />
                  <span>Yes</span>
                </label>
                <label className="flex items-center space-x-1">
                  <input
                    type="radio"
                    value="false"
                    checked={!isArray}
                    onChange={(e) => handleChange("isArray", e)}
                    required={true}
                  />
                  <span>No</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row items-center p-2">
            <label className="w-1/4">Is Required?:</label>
            <div className="flex flex-1 items-center justify-center">
              <div className="flex flex-row space-x-4">
                <label className="flex items-center space-x-1">
                  <input
                    type="radio"
                    value="true"
                    checked={isRequired}
                    onChange={(e) => handleChange("isRequired", e)}
                  />
                  <span>Yes</span>
                </label>
                <label className="flex items-center space-x-1">
                  <input
                    type="radio"
                    value="false"
                    checked={!isRequired}
                    onChange={(e) => handleChange("isRequired", e)}
                  />
                  <span>No</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row items-center p-2">
            <label className="w-1/4">Default Value:</label>
            <input
              className="flex-1 rounded-lg border border-gray-300 bg-gray-50 p-1 text-sm text-modelit-navy"
              value={defaultValue}
              onChange={(e) => handleChange("defaultValue", e)}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row items-center p-2">
            <label className="w-1/4">Placeholder:</label>
            <input
              className="flex-1 rounded-lg border border-gray-300 bg-gray-50 p-1 text-sm text-modelit-navy"
              value={placeholder}
              onChange={(e) => handleChange("placeholder", e)}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row items-center p-2">
            <label className="w-1/4">Prefix:</label>
            <input
              className="flex-1 rounded-lg border border-gray-300 bg-gray-50 p-1 text-sm text-modelit-navy"
              value={prefix}
              onChange={(e) => handleChange("prefix", e)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFlowInputRow;
