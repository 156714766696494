import { useLazyQuery } from "@apollo/client";
import { createContext, useContext, useEffect } from "react";
import { GET_INTEGRATIONS } from "../graphql/queries";
import { IntegrationEdge } from "../graphql/types";
import { AuthContext } from "./AuthProvider";

type ContextType = {
  integrationEdges?: IntegrationEdge[];
};

// @ts-ignore
export const IntegrationContext = createContext<ContextType>(null);

type Props = {
  children: React.ReactNode;
};

const IntegrationProvider = ({ children }: Props) => {
  const { user } = useContext(AuthContext);
  const [getIntegrations, { data: integrationData }] =
    useLazyQuery(GET_INTEGRATIONS);

  const integrationEdges = integrationData?.integrations?.edges;

  useEffect(() => {
    if (user) {
      getIntegrations();
    }
  }, [user]);

  return (
    <IntegrationContext.Provider value={{ integrationEdges }}>
      {children}
    </IntegrationContext.Provider>
  );
};

export default IntegrationProvider;
