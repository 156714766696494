import { useContext } from "react";
import { AuthContext } from "../../providers/AuthProvider";

const ProfileTab = () => {
  const { firebaseUser } = useContext(AuthContext);
  let firstName = "";
  let lastName = "";
  if (firebaseUser?.displayName) {
    [firstName, lastName] = firebaseUser?.displayName?.split(" ");
  }
  return (
    <div className="flex max-w-2xl flex-1 flex-col space-y-6 py-4">
      <div className="flex flex-col space-y-2">
        <span className="text-sm font-medium">Profile image</span>
        <img src={firebaseUser?.photoURL} className="h-28 w-28 rounded-full" />
      </div>
      <div className="flex flex-row space-x-2">
        <div className="flex flex-1 flex-col space-y-1">
          <span className="text-sm font-medium">First Name</span>
          <input
            className="rounded-lg border border-gray-300 p-2.5 text-sm outline-none"
            value={firstName}
            disabled
          />
        </div>
        <div className="flex flex-1 flex-col space-y-1">
          <span className="text-sm font-medium">Last Name</span>
          <input
            className="rounded-lg border border-gray-300 p-2.5 text-sm outline-none"
            value={lastName}
            disabled
          />
        </div>
      </div>
      <div className="flex flex-col space-y-1">
        <span className="text-sm font-medium">Email</span>
        <input
          className="rounded-lg border border-gray-300 p-2.5 text-sm outline-none"
          value={firebaseUser?.email}
          disabled
        />
      </div>
    </div>
  );
};

export default ProfileTab;
