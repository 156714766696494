import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import Select from "react-select";
import { useMutation } from "@apollo/client";
import { CREATE_FLOW } from "../graphql/mutations";
import CreateFlowStepSection from "../components/createFlow/CreateFlowStepSection";
import { VisibilityType } from "../graphql/types";

const CreateFlowPage = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const navigate = useNavigate();
  const [flowSteps, setFlowSteps] = useState<any>([]);
  const [createFlow] = useMutation(CREATE_FLOW);

  const onSubmit = async (data: any) => {
    const transformedFlowSteps = flowSteps.map((flowStep: any) => {
      const {
        referenceId,
        name,
        description,
        action,
        params,
        inputFieldHash,
        metafieldHash,
      } = flowStep;
      const inputSpec = Object.values(inputFieldHash)
        .sort((a: any, b: any) => a.ordering - b.ordering)
        .map((inputHash: any) => {
          delete inputHash.ordering;
          return inputHash;
        });
      const parsedMetafieldHash = Object.keys(metafieldHash).reduce(
        (accum: any, key: any) => {
          // @ts-ignore
          const parsedValue = JSON.parse(metafieldHash[key]);
          // @ts-ignore
          accum[key] = parsedValue;
          return accum;
        },
        {},
      );

      return {
        referenceId,
        name,
        description,
        action,
        params: params,
        inputSpec,
        metafields: parsedMetafieldHash,
      };
    });

    const transformedFlowTags = getValues("flowTags")?.map(
      (flowTag: string) => ({ name: flowTag }),
    );

    createFlow({
      variables: {
        input: {
          ...data,
          flowSteps: transformedFlowSteps,
          flowTags: transformedFlowTags,
          isAdmin: true // TODO: Remove when enabling users to create their own flows
        },
      },
      onCompleted: (res) => {
        if (res?.createFlow?.id) {
          navigate("/flows");
        }
      },
    });
  };

  const handleAddFlowStep = () => {
    const newFlowSteps = [
      ...flowSteps,
      {
        referenceId: "",
        name: "",
        description: "",
        params: {
          systemPrompt: "",
          prompt: "",
        },
        inputFieldHash: {},
        metafieldHash: {},
      },
    ];
    setFlowSteps(newFlowSteps);
  };

  const visibilityOptions = Object.values(VisibilityType).map(
    (visibilityType) => ({
      value: visibilityType,
      label: visibilityType,
    }),
  );

  const flowFields = [
    {
      key: "alias",
      title: "Alias",
      required: true,
      input: (
        <input
          className="rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-modelit-navy"
          {...register("alias", { required: true })}
        />
      ),
    },
    {
      key: "name",
      title: "Name",
      required: true,
      input: (
        <input
          className="rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-modelit-navy"
          {...register("name", { required: true })}
        />
      ),
    },
    {
      key: "description",
      title: "Description",
      required: true,
      input: (
        <textarea
          rows={2}
          className="rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-modelit-navy"
          {...register("description", { required: true })}
        />
      ),
    },
    {
      key: "flowTags",
      title: "Tags",
      input: (
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <TagsInput
              value={value || []}
              onChange={onChange}
              placeHolder="type and press Enter to add tags"
              classNames={{
                input: "!flex-1 !p-0 !text-sm !text-modelit-navy",
                tag: "!bg-gray-200 !rounded-lg !p-1 !text-sm",
              }}
            />
          )}
          name={"flowTags"}
        />
      ),
    },
    {
      key: "visibility",
      title: "Visibility",
      required: true,
      input: (
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              // @ts-ignore
              options={visibilityOptions}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#bae6fd",
                  primary25: "#f0f9ff",
                },
              })}
              classNames={{
                container: () =>
                  "!flex-1 !bg-gray-50 !border !border-gray-300 !text-gray-700 !text-sm !rounded-md",
                control: () => "!border-none !cursor-pointer",
                option: () => "!text-gray-700 !cursor-pointer",
              }}
              required={true}
              defaultValue={VisibilityType.VISIBLE}
              onChange={(e: any) => {
                if (e) {
                  onChange(e.value);
                } else {
                  onChange(null);
                }
              }}
            />
          )}
          name={"visibility"}
        />
      ),
    },
  ];

  const errorMessage = (key: string) => {
    if (!errors[key]) return;
    return (
      <div className="text-right text-sm italic text-red-500">
        This is a required field.
      </div>
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex h-full w-full flex-row overflow-x-auto px-20 px-5 py-8"
    >
      <div className="flex w-[32rem] w-full flex-none flex-col justify-between p-0">
        <div className="space-y-6">
          {flowFields.map((flowField) => (
            <div key={flowField.key} className="flex flex-1 flex-col">
              <label className="mb-1">
                {flowField.title}
                {flowField.required && <span className="text-red-500">*</span>}
              </label>
              {flowField.input}
              {errorMessage(flowField.key)}
            </div>
          ))}
          <div
            className="h-10 w-full cursor-pointer rounded-lg bg-modelit-sky px-4 py-2 text-center font-bold text-white hover:bg-blue-500"
            onClick={handleAddFlowStep}
          >
            Add Flow Step
          </div>
        </div>
        <button
          type="submit"
          className="h-10 w-full rounded-lg bg-modelit-purple px-4 py-2 font-bold text-white hover:bg-indigo-700"
        >
          Create Flow!
        </button>
      </div>
      {flowSteps.map((flowStep: any, index: number) => (
        <div
          key={index}
          className="flex w-[32rem] flex-none overflow-y-auto p-6"
        >
          <CreateFlowStepSection flowStep={flowStep} />
        </div>
      ))}
    </form>
  );
};

export default CreateFlowPage;
