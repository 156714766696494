import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpLong,
  faWandSparkles,
  faTextWidth,
  faMicrophone,
} from "@fortawesome/free-solid-svg-icons";
import EditorPowerFunction from "./EditorPowerFunction";
import { VoiceEdge } from "../../graphql/types";

type Props = {
  updateSelectedTextWithFlowRes: any;
  voiceEdges: [VoiceEdge];
};

const EditorPowerFunctionContainer = ({ updateSelectedTextWithFlowRes, voiceEdges }: Props) => {
  const [input, setInput] = useState("");

  const handleInputKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      updateSelectedTextWithFlowRes("adapt_writing", { prompt: input });
    }
  };

  const powerFunctions = [
    {
      label: "Improve writing",
      icon: faWandSparkles,
      flowAlias: "improve_writing", 
    },
    {
      label: "Change tone",
      icon: faMicrophone,
      voices: voiceEdges.map(voiceEdge => ({
        label: voiceEdge.node.name,
        flowAlias: "rephrase_writing",
        additionalParams: { voice: voiceEdge.node.id }
      })),
      nested: [
        {
          label: "Casual",
          flowAlias: "rephrase_writing",
          additionalParams: { voice: "casual" }
        },
        {
          label: "Conversational",
          flowAlias: "rephrase_writing",
          additionalParams: { voice: "conversational" }
        },
        {
          label: "Engaging",
          flowAlias: "rephrase_writing",
          additionalParams: { voice: "engaging" }
        }
      ]
    },
    {
      label: "Change length",
      icon: faTextWidth,
      nested: [
        {
          label: "Make shorter",
          flowAlias: "shorten_writing",
        },
        {
          label: "Make longer",
          flowAlias: "lengthen_writing",
        }
      ]
    },
  ];

  const powerFunctionComponents = powerFunctions.map((powerFunction, index) => (
    <EditorPowerFunction
      key={index}
      powerFunction={powerFunction}
      updateSelectedTextWithFlowRes={updateSelectedTextWithFlowRes}
    />
  ));

  return (
    <div className="flex flex-col rounded-md bg-white w-48 p-2 text-xs border border-gray-200">
      <div className="flex flex-row items-center justify-between pb-2 border-b border-gray-200">
        <input
          className="placeholder-gray-400 outline-none w-5/6"
          placeholder="Tell Modelit how to edit"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleInputKeyDown}
        />
        {input && (
          <button
            onClick={() => updateSelectedTextWithFlowRes("adapt_writing", { prompt: input })}
            className="flex h-3.5 w-3.5 items-center justify-center rounded-full bg-modelit-purple hover:bg-indigo-700 cursor-pointer"
          >
            <FontAwesomeIcon
              icon={faArrowUpLong}
              className="h-2.5 w-2.5 text-white"
            />
          </button>
        )}
      </div>
      <div className="pt-1">
        {powerFunctionComponents}
      </div>
    </div>
  );
}

export default EditorPowerFunctionContainer;