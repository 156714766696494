// @ts-nocheck
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { TagsInput } from "react-tag-input-component";
import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  GET_KNOWLEDGE_DOCUMENTS,
  GET_VOICE,
  GET_VOICES,
  GET_COMPANY_USAGE,
  GET_USER_INTEGRATIONS,
} from "../../graphql/queries";
import {
  CREATE_KNOWLEDGE_DOCUMENT,
  CREATE_VOICE,
  CREATE_VOICE_DOCUMENT,
  CREATE_USER_INTEGRATION,
} from "../../graphql/mutations";
import { AddDocumentType, DocumentSourceType } from "../../graphql/types";
import { toast } from "sonner";
import Loading from "../utils/Loading";
import Modal from "../utils/Modal";
import RequestForm from "./RequestForm";
import { useParams } from "react-router-dom";
import { IntegrationContext } from "../../providers/IntegrationProvider";

type Props = {
  addDocumentType: AddDocumentType;
  closeModal: () => void;
};

const UrlForm = ({ register }) => (
  <div className="my-5 space-y-5">
    <div className="flex flex-col">
      <div className="mb-2 font-semibold">Enter URL</div>
      <input
        className="rounded-lg border border-gray-300 p-2.5 outline-none"
        {...register("source", { required: true })}
      />
    </div>
  </div>
);

const TextForm = ({ register }) => (
  <div className="my-5 space-y-5">
    <div className="flex flex-col">
      <div className="mb-2 font-semibold">Enter text</div>
      <textarea
        className="rounded-lg border border-gray-300 p-2.5 outline-none"
        rows={2}
        {...register("source", { required: true })}
      />
    </div>
    <div className="flex flex-col">
      <div className="mb-2 font-semibold">Name this source</div>
      <input
        className="rounded-lg border border-gray-300 p-2.5 outline-none"
        {...register("docName", { required: true })}
      />
    </div>
  </div>
);

const FileForm = ({ register }) => null;

const ExternalForm = ({
  integrationEdges,
  setIsLoading,
  closeModal,
  setRequestedFieldName,
}) => {
  const [createUserIntegration] = useMutation(CREATE_USER_INTEGRATION, {
    refetchQueries: [GET_KNOWLEDGE_DOCUMENTS, GET_USER_INTEGRATIONS],
    onError(err) {
      setIsLoading(false);
    },
  });

  const handleClick = async (integrationId) => {
    setIsLoading(true);
    const res = await createUserIntegration({
      variables: {
        input: {
          integration: integrationId,
        },
      },
    });
    if (res?.data?.createUserIntegration?.id) {
      toast.success("Integration successful");
    }
    setIsLoading(false);
    closeModal();
  };

  return (
    <div className="flex flex-col">
      <div className="mb-2 font-semibold">
        Select source to import files from
      </div>
      <div className="flex flex-row flex-wrap items-center justify-center space-x-4">
        {integrationEdges.map((integrationEdge) => {
          let requestedFieldName = "requestedGoogleDrive";
          if (integrationEdge.node.name === "Notion") {
            requestedFieldName = "requestedNotion";
          } else if (integrationEdge.node.name === "Slack") {
            requestedFieldName = "requestedSlack";
          }
          return (
            <div
              className="flex h-16 w-16 cursor-pointer flex-col items-center justify-center rounded-full border border-gray-200 hover:shadow"
              onClick={() => {
                // setRequestedFieldName(requestedFieldName);
                handleClick(integrationEdge.node.id);
              }}
            >
              <img
                src={`/integrations/${integrationEdge.node?.iconString}`}
                className="h-10"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const sourceOptions = {
  [DocumentSourceType.URL]: {
    title: "Enter url",
    caption: "We will scan your website",
    sourceType: DocumentSourceType.URL,
    form: UrlForm,
    active: true,
  },
  [DocumentSourceType.TEXT]: {
    title: "Enter text",
    caption: "Copy and paste content",
    sourceType: DocumentSourceType.TEXT,
    form: TextForm,
    active: true,
  },
  [DocumentSourceType.EXTERNAL]: {
    title: "Import file",
    caption: "Import files from a third party",
    sourceType: DocumentSourceType.EXTERNAL,
    form: ExternalForm,
    active: true,
  },
  [DocumentSourceType.FILE]: {
    title: "Upload file",
    caption: "Upload a .pdf or .docx file",
    sourceType: DocumentSourceType.FILE,
    form: FileForm,
    active: false,
  },
};

const AddDocumentForm = ({ addDocumentType, closeModal }: Props) => {
  const { id: voiceId } = useParams();
  const sourceTypes = Object.keys(sourceOptions);
  const [selectedSourceType, setSelectedSourceType] = useState(sourceTypes[0]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [requestedFieldName, setRequestedFieldName] = useState(null);
  const { integrationEdges } = useContext(IntegrationContext);
  const { register, handleSubmit } = useForm();

  const [createKnowledgeDocument] = useMutation(CREATE_KNOWLEDGE_DOCUMENT, {
    refetchQueries: [GET_KNOWLEDGE_DOCUMENTS, GET_COMPANY_USAGE],
    onError(err) {
      setIsLoading(false);
    },
  });
  const [createVoice] = useMutation(CREATE_VOICE, {
    refetchQueries: [GET_VOICES, GET_COMPANY_USAGE],
    onError(err) {
      setIsLoading(false);
    },
  });
  const [createVoiceDocument] = useMutation(CREATE_VOICE_DOCUMENT, {
    refetchQueries: [
      { query: GET_VOICE, variables: { id: voiceId } },
      { query: GET_COMPANY_USAGE },
    ],
    onError(err) {
      setIsLoading(false);
    },
  });

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    let res;
    let successMessage;
    switch (addDocumentType) {
      case AddDocumentType.NEW_KNOWLEDGE_DOC:
        res = await createKnowledgeDocument({
          variables: {
            input: {
              name: data.docName,
              sourceType: selectedSourceType,
              source: data.source,
              tags: selectedTags,
            },
          },
        });
        successMessage = "Knowledge document has been created";
        break;
      case AddDocumentType.NEW_VOICE:
        res = await createVoice({
          variables: {
            input: {
              name: data.voiceName,
              voiceDocuments: [
                {
                  name: data.docName,
                  sourceType: selectedSourceType,
                  source: data.source,
                },
              ],
            },
          },
        });
        successMessage = "Voice has been created";
        break;
      case AddDocumentType.ADD_TO_VOICE:
        res = await createVoiceDocument({
          variables: {
            input: {
              voice: voiceId,
              name: data.docName,
              sourceType: selectedSourceType,
              source: data.source,
            },
          },
        });
        successMessage = "Source has been added to voice";
        break;
      default:
        break;
    }
    if (
      res?.data?.createKnowledgeDocument?.id ||
      res?.data?.createVoice?.id ||
      res?.data?.createVoiceDocument?.id
    ) {
      toast.success(successMessage);
    }
    setIsLoading(false);
    closeModal();
  };

  let buttonLabel = "Add to knowledge";
  if (addDocumentType === AddDocumentType.NEW_VOICE) {
    buttonLabel = "Create voice";
  } else if (addDocumentType === AddDocumentType.ADD_TO_VOICE) {
    buttonLabel = "Add to voice";
  }

  const nameComponent = addDocumentType === AddDocumentType.NEW_VOICE && (
    <div className="flex flex-col">
      <div className="mb-2 font-semibold">Name this voice</div>
      <input
        className="rounded-lg border border-gray-300 p-2.5 outline-none"
        {...register("voiceName", { required: true })}
      />
    </div>
  );

  const tagsComponent = addDocumentType === AddDocumentType.NEW_KNOWLEDGE_DOC &&
    selectedSourceType !== DocumentSourceType.EXTERNAL && (
      <div className="flex flex-col">
        <div className="mb-2 font-semibold">Tags</div>
        <TagsInput
          value={selectedTags}
          onChange={setSelectedTags}
          name="tags"
          placeHolder="Type and press Enter to add a tag"
          classNames={{
            input: "!flex-1 !p-0 !text-xs",
            tag: "!border !border-gray-300 !bg-gray-100 !rounded-xl !pl-2 !py-1 !text-xs",
          }}
        />
      </div>
    );

  const sourceOptionComponents = Object.values(sourceOptions).map(
    (sourceOption, index) => {
      const { title, caption, sourceType, active } = sourceOption;
      const isSelected = sourceType === selectedSourceType;
      return (
        <div
          key={index}
          className={`relative mb-2 flex flex-1 cursor-pointer flex-col overflow-hidden rounded-xl border p-3 hover:bg-gray-50 ${
            isSelected ? "border-modelit-purple" : "border-gray-200"
          } ${
            !active &&
            "pointer-events-none hidden bg-gray-50 opacity-75 sm:flex"
          }`}
          onClick={() => setSelectedSourceType(sourceType)}
        >
          {!active && (
            <div className="absolute -right-6 top-1 z-10 rotate-[45deg] bg-indigo-400 px-6 text-[8px] font-medium text-white">
              Soon!
            </div>
          )}
          <div className="mb-2 flex flex-row items-center justify-between">
            <div className="text-sm font-semibold">{title}</div>
            {isSelected && (
              <div className="flex h-3.5 w-3.5 items-center justify-center rounded-full bg-modelit-purple">
                <FontAwesomeIcon icon={faCheck} className="h-2.5 text-white" />
              </div>
            )}
          </div>
          <div className="mb-2 text-gray-500">{caption}</div>
        </div>
      );
    },
  );

  const FormName = sourceOptions[selectedSourceType].form;

  let modalTitle = "Import from Google Drive";
  if (requestedFieldName === "requestedNotion") {
    modalTitle = "Import from Notion";
  } else if (requestedFieldName === "requestedSlack") {
    modalTitle = "Import from Slack";
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-5 text-xs">
      <div className="flex flex-col space-y-2">
        <div className="font-semibold">
          How would you like to import content?
        </div>
        <div className="flex flex-row flex-wrap space-x-2 space-x-2">
          {sourceOptionComponents}
        </div>
      </div>
      {
        <FormName
          register={register}
          integrationEdges={integrationEdges}
          setIsLoading={setIsLoading}
          closeModal={closeModal}
          setRequestedFieldName={setRequestedFieldName}
        />
      }
      {nameComponent}
      {tagsComponent}
      <div className="flex flex-row items-end justify-end space-x-2">
        <button
          onClick={closeModal}
          className="flex flex-row items-center justify-center rounded-lg border border-modelit-purple px-4 py-2 font-medium text-modelit-purple hover:bg-gray-50 disabled:pointer-events-none disabled:opacity-75"
          disabled={isLoading}
        >
          <div className="flex flex-row space-x-2">
            <span>Cancel</span>
          </div>
        </button>
        <button
          type="submit"
          className="flex flex-row items-center justify-center rounded-lg border border-modelit-purple bg-modelit-purple px-4 py-2 font-medium text-white hover:bg-indigo-700 disabled:pointer-events-none disabled:opacity-75"
          disabled={isLoading}
        >
          <div className="flex flex-row items-center justify-center space-x-2">
            <span>{buttonLabel}</span>
            {isLoading && <Loading size="sm" />}
          </div>
        </button>
      </div>
      <Modal
        isOpen={!!requestedFieldName}
        onClose={() => setRequestedFieldName(null)}
        title={modalTitle}
        size="md"
      >
        <RequestForm requestFieldName={requestedFieldName} />
      </Modal>
    </form>
  );
};

export default AddDocumentForm;
