import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation } from "@apollo/client";
import {
  CREATE_FLOW_PREDICTION,
  CREATE_PREDICTION,
  UPDATE_FLOW_STEP_LOG,
} from "../../graphql/mutations";
import {
  AddDocumentType,
  DocumentSourceType,
  FlowStep,
  ParameterType,
  VoiceEdge,
} from "../../graphql/types";
import DragDropFile from "../utils/DragDropFile";
import Loading from "../utils/Loading";
import { Editor } from "@tiptap/react";
import Modal from "../utils/Modal";
import AddDocumentForm from "../modals/AddDocumentForm";
import googleLogo from "../../assets/google-logo.png";
import SerperResultList from "./SerperResultList";
import { interpolateString } from "../../utils/textHelpers";
import KnowledgeInput from "./KnowledgeInput";
import { SOCKETS_URL } from "../../config/constants";

export type KnowledgeDocInfo = {
  name?: string;
  sourceType: DocumentSourceType;
  source: string;
};

type Props = {
  flowStep: FlowStep;
  flowAlias: string;
  sessionDataHash: any;
  hasPreviousStep?: boolean;
  hasNextStep?: boolean;
  setToPreviousStep: () => void;
  setToNextStep: () => void;
  currentFlowStepLogId?: string | null;
  setCurrentFlowStepLogId: any;
  flowStepLadder: any;
  voiceEdges?: [VoiceEdge];
  editor?: Editor | null;
  setShowForm: any;
};

const SERPER_FLOW_ALIAS = "serper_results";

const FlowStepForm = ({
  flowStep,
  flowAlias,
  sessionDataHash,
  hasPreviousStep,
  hasNextStep,
  setToPreviousStep,
  setToNextStep,
  currentFlowStepLogId,
  setCurrentFlowStepLogId,
  flowStepLadder,
  voiceEdges,
  editor,
  setShowForm,
}: Props) => {
  const { userContentId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [output, setOutput] = useState("");
  const [outputSources, setOutputSources] = useState<any>([]);
  const [vote, setVote] = useState(0);
  const [isLoadingPrediction, setIsLoadingPrediction] = useState(false);
  const [isLoadingSerper, setIsLoadingSerper] = useState(false);
  const [isNewVoiceModalOpen, setIsNewVoiceModalOpen] = useState(false);
  const [serperInput, setSerperInput] = useState("");
  const [showSerperResults, setShowSerperResults] = useState(false);
  const [serperResults, setSerperResults] = useState([]);
  const [selectedKnowledgeDocs, setSelectedKnowledgeDocs] = useState<
    KnowledgeDocInfo[]
  >([]);
  const [channelId, setChannelId] = useState("");
  const [isStreamStart, setIsStreamStart] = useState(true);
  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(
    `${SOCKETS_URL}/${channelId}`,
  );

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const [createPrediction] = useMutation(CREATE_PREDICTION, {
    onError(err) {
      setIsLoadingPrediction(false);
    },
  });
  const [createFlowPrediction] = useMutation(CREATE_FLOW_PREDICTION, {
    onError(err) {
      setIsLoadingSerper(false);
    },
  });
  const [updateFlowStepLog] = useMutation(UPDATE_FLOW_STEP_LOG);

  const validContentId = userContentId || searchParams.get("contentId");

  useEffect(() => {
    sessionDataHash[flowAlias] = sessionDataHash[flowAlias] || {};
    sessionDataHash[flowAlias][flowStep.referenceId] =
      sessionDataHash[flowAlias][flowStep.referenceId] || {};
    const flowStepSession = sessionDataHash[flowAlias][flowStep.referenceId];
    reset(flowStepSession["input"]);
    setCurrentFlowStepLogId(flowStepSession.flowStepLogId);
    setOutput(
      flowStepSession["finalOutput"] ||
        flowStepSession["generatedOutput"]?.value ||
        "",
    );
    setOutputSources(flowStepSession["generatedOutput"]?.sources);
    setVote(flowStepSession["rating"]?.vote);
  }, [flowStep, sessionDataHash]);

  useEffect(() => {
    if (lastMessage?.data) {
      const value = JSON.parse(lastMessage.data)?.content?.data?.value;
      if (value) {
        if (isStreamStart) {
          setOutput(value);
          setIsStreamStart(false);
        } else {
          setOutput(output + value);
        }
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    if (channelId && readyState === ReadyState.OPEN) {
      const asyncPrediction = async () => {
        const values = getValues();
        const knowledgeInput = flowStep.inputSpec?.find(
          (paramSpec) => paramSpec.type === ParameterType.KNOWLEDGE,
        );
        if (knowledgeInput) {
          values[knowledgeInput.key] = selectedKnowledgeDocs;
        }
        const createPredictionRes = await createPrediction({
          variables: {
            flowStepId: flowStep.id,
            params: values,
            userContentId: validContentId,
            streamChannelId: channelId,
          },
        });
        sendJsonMessage({ type: "close" });
        const prediction =
          createPredictionRes?.data?.createPrediction?.prediction || "{}";
        const predictionJSON = JSON.parse(prediction);
        const flowStepLogId =
          createPredictionRes?.data?.createPrediction?.flowStepLogId;
        if (prediction) {
          sessionDataHash[flowAlias][flowStep.referenceId]["input"] = values;
          sessionDataHash[flowAlias][flowStep.referenceId]["generatedOutput"] =
            predictionJSON;
          sessionDataHash[flowAlias][flowStep.referenceId]["flowStepLogId"] =
            flowStepLogId;
          setCurrentFlowStepLogId(flowStepLogId);
          setIsLoadingPrediction(false);
          setOutput(predictionJSON.value);
          setOutputSources(predictionJSON.sources);
        }
      };
      asyncPrediction();
    }
  }, [readyState]);

  const onSubmit = async (data: any) => {
    setIsStreamStart(true);
    setIsLoadingPrediction(true);
    const newChannelId = uuidv4();
    setChannelId(newChannelId);
  };

  const handleNextPrevClick = async (cb: any) => {
    if (
      currentFlowStepLogId &&
      sessionDataHash[flowAlias][flowStep.referenceId]["finalOutput"] !== null
    ) {
      await updateFlowStepLog({
        variables: {
          input: {
            id: currentFlowStepLogId,
            finalOutputJson:
              sessionDataHash[flowAlias][flowStep.referenceId]["finalOutput"],
          },
        },
      });
    }
    cb();
  };

  const handleRatingClick = async (rating: number) => {
    if (currentFlowStepLogId) {
      const res = await updateFlowStepLog({
        variables: {
          input: {
            id: currentFlowStepLogId,
            rating: {
              vote: rating,
            },
          },
        },
      });
      if (res?.data?.updateFlowStepLog?.id) {
        setVote(rating);
      }
    }
  };

  const voiceOptions = voiceEdges?.map((voiceEdge) => ({
    value: voiceEdge.node.id,
    label: voiceEdge.node.name,
  }));

  const errorMessage = (key: string) => {
    if (!errors[key]) return;
    return (
      <div className="text-right text-xs italic text-red-500">
        This is a required field.
      </div>
    );
  };

  const handleSerperSearch = async () => {
    setIsLoadingSerper(true);
    const serperRes = await createFlowPrediction({
      variables: {
        flowAlias: SERPER_FLOW_ALIAS,
        params: { query: serperInput },
      },
    });
    const results = serperRes?.data?.createFlowPrediction?.prediction;
    if (results) {
      setSerperResults(JSON.parse(JSON.parse(results)));
      setShowSerperResults(true);
    }
    setIsLoadingSerper(false);
  };

  const handleSearchInputChange = (e: any) => {
    setSerperInput(e.target.value);
  };

  const handleSearchInputKeyDown = async (e: any) => {
    if (e.keyCode === 13) {
      handleSerperSearch();
    }
  };

  const handleSelectedKnowledgeDocClick = (
    knowledgeDocInfo: any,
    isChecked: boolean,
    isArray: boolean,
  ) => {
    if (isArray) {
      if (isChecked) {
        const foundIndex = selectedKnowledgeDocs.findIndex(
          (selectedKnowledgeDoc: KnowledgeDocInfo) =>
            selectedKnowledgeDoc.source === knowledgeDocInfo.source,
        );
        selectedKnowledgeDocs.splice(foundIndex, 1);
        setSelectedKnowledgeDocs([...selectedKnowledgeDocs]);
      } else {
        setSelectedKnowledgeDocs([...selectedKnowledgeDocs, knowledgeDocInfo]);
      }
    } else {
      const newSelected = knowledgeDocInfo ? [knowledgeDocInfo] : [];
      setSelectedKnowledgeDocs(newSelected);
    }
  };

  const searchBar = (
    <div className="flex flex-1 flex-row space-x-2">
      <div className="flex flex-1 flex-row items-center space-x-1 overflow-hidden rounded-lg border border-gray-300 px-2">
        <img src={googleLogo} className="h-4" />
        <input
          className="flex-1 rounded-lg px-1 py-2 text-sm font-medium placeholder-gray-400 outline-none"
          placeholder="Search online"
          value={serperInput}
          onChange={handleSearchInputChange}
          onKeyDown={handleSearchInputKeyDown}
        />
        {isLoadingSerper && (
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            size="sm"
            className="text-gray-700"
          />
        )}
      </div>
      <button
        onClick={handleSerperSearch}
        className="cursor-pointer rounded-xl bg-gray-200 px-4 text-sm font-medium hover:bg-gray-300 disabled:pointer-events-none disabled:opacity-75"
        disabled={serperInput === "" || isLoadingSerper}
      >
        Search
      </button>
    </div>
  );

  const inputComponents: [JSX.Element?] = [];

  flowStep?.inputSpec?.forEach((paramSpec) => {
    const {
      key,
      label,
      description,
      type,
      isRequired,
      placeholder,
      defaultValue,
      isArray,
      allowSearch,
    } = paramSpec;

    const interpolatedDefault =
      !sessionDataHash?.[flowAlias]?.[flowStep.referenceId]?.["input"]?.[key] &&
      defaultValue
        ? interpolateString(defaultValue, sessionDataHash[flowAlias])
        : null;

    let inputComponent = null;
    switch (type) {
      case ParameterType.STRING:
        inputComponent = (
          <textarea
            className="rounded-lg border border-gray-300 p-2.5 text-sm outline-none"
            rows={1}
            {...register(key, { required: isRequired })}
            placeholder={placeholder}
            defaultValue={interpolatedDefault}
          />
        );
        break;
      case ParameterType.VOICE:
        const defaultVoiceOption = voiceOptions?.find(
          (voiceOption) => voiceOption.value === interpolatedDefault,
        );
        inputComponent = (
          <div className="flex flex-row items-center justify-center space-x-4">
            <Controller
              control={control}
              rules={{ required: isRequired }}
              render={({ field: { onChange } }) => (
                <CreatableSelect
                  isClearable
                  options={voiceOptions}
                  placeholder={placeholder || "Type or select voice"}
                  classNames={{
                    container: (state) => "flex-1",
                    option: (state) => "!text-modelit-navy",
                  }}
                  onChange={(e: any) => {
                    if (e) {
                      onChange(e.value);
                    } else {
                      onChange(null);
                    }
                  }}
                  defaultValue={defaultVoiceOption}
                />
              )}
              name={key}
            />
            <div
              className="cursor-pointer rounded-lg border border-dashed border-gray-400 bg-white px-2 py-1 text-gray-400 hover:border-gray-500 hover:text-gray-500"
              onClick={() => setIsNewVoiceModalOpen(true)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </div>
        );
        break;
      case ParameterType.INTEGER:
        inputComponent = (
          <input
            className="rounded-lg border border-gray-300 p-2.5 text-sm outline-none"
            type="number"
            onKeyPress={(event) => {
              if (event.key === "." || event.key === "e") {
                event.preventDefault();
              }
            }}
            defaultValue={interpolatedDefault}
            {...register(key, { required: isRequired })}
          />
        );
        break;
      case ParameterType.NUMBER:
        inputComponent = (
          <input
            className="rounded-lg border border-gray-300 p-2.5 text-sm outline-none"
            type="number"
            onKeyPress={(event) => {
              if (event.key === "e") {
                event.preventDefault();
              }
            }}
            defaultValue={interpolatedDefault}
            {...register(key, { required: isRequired })}
          />
        );
        break;
      case ParameterType.BOOLEAN:
        inputComponent = (
          <Controller
            control={control}
            rules={{ required: isRequired }}
            render={({ field: { onChange } }) => (
              <Select
                options={[
                  // @ts-ignore
                  { value: true, label: "true" },
                  // @ts-ignore
                  { value: false, label: "false" },
                ]}
                theme={(theme: any) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#bae6fd",
                    primary25: "#f0f9ff",
                  },
                })}
                classNames={{
                  container: () =>
                    "!flex-1 !bg-gray-50 !border !border-gray-300 !text-gray-700 !text-sm !rounded-lg",
                  control: () => "!border-none !cursor-pointer",
                  option: () => "!text-gray-700 !cursor-pointer",
                }}
                required={true}
                defaultValue={interpolatedDefault || false}
                onChange={(e: any) => {
                  if (e) {
                    onChange(e.value);
                  } else {
                    onChange(null);
                  }
                }}
              />
            )}
            name={key}
          />
        );
        break;
      case ParameterType.DATETIME:
        inputComponent = (
          <Controller
            control={control}
            rules={{ required: isRequired }}
            render={({ field: { onChange, value } }) => (
              <DatePicker onChange={onChange} selected={value} />
            )}
            name={key}
            defaultValue={interpolatedDefault}
          />
        );
        break;
      case ParameterType.IMAGE:
        inputComponent = (
          <Controller
            control={control}
            rules={{ required: isRequired }}
            render={({ field }) => (
              <DragDropFile
                field={field}
                accept="image/jpeg, image/jpg, image/png"
              />
            )}
            name={key}
          />
        );
        break;
      case ParameterType.VIDEO:
        inputComponent = (
          <Controller
            control={control}
            rules={{ required: isRequired }}
            render={({ field }) => (
              <DragDropFile
                field={field}
                accept="video/mp4,video/x-m4v,video/*"
              />
            )}
            name={key}
          />
        );
        break;
      case ParameterType.KNOWLEDGE:
        if (interpolatedDefault) setSelectedKnowledgeDocs(interpolatedDefault);
        inputComponent = (
          <KnowledgeInput
            searchBar={allowSearch ? searchBar : null}
            selectedKnowledgeDocs={selectedKnowledgeDocs}
            handleSelectedKnowledgeDocClick={handleSelectedKnowledgeDocClick}
            isArray={isArray}
          />
        );
        break;
    }
    inputComponents.push(
      <div key={key} className="flex flex-1 flex-col">
        <label htmlFor={key} className="font-medium">
          {label}
          {isRequired && <span>*</span>}
        </label>
        <span className="pb-2 text-xs italic">{description}</span>
        {inputComponent}
        {errorMessage(key)}
      </div>,
    );
  });

  return (
    <div className="flex flex-1">
      {showSerperResults ? (
        <SerperResultList
          serperResults={serperResults}
          selectedKnowledgeDocs={selectedKnowledgeDocs}
          setShowSerperResults={setShowSerperResults}
          searchBar={searchBar}
          handleSelectedKnowledgeDocClick={handleSelectedKnowledgeDocClick}
        />
      ) : (
        <div className="flex flex-1 flex-col">
          <div className="flex flex-row items-center justify-between pb-8">
            {flowStepLadder || (
              <div className="font-medium">{flowStep.name}</div>
            )}
            <div>
              {hasPreviousStep && (
                <button
                  className="cursor-pointer rounded-xl border border-modelit-purple px-3 py-2 text-sm text-modelit-purple hover:border-indigo-800 hover:text-indigo-800"
                  onClick={() => handleNextPrevClick(setToPreviousStep)}
                >
                  Previous
                </button>
              )}
              {hasNextStep && (
                <button
                  className={`cursor-pointer rounded-xl border border-modelit-purple px-3 py-2 text-sm text-modelit-purple hover:border-indigo-800 hover:text-indigo-800 ${
                    !output && "pointer-events-none opacity-60"
                  }`}
                  onClick={() => handleNextPrevClick(setToNextStep)}
                >
                  Next
                </button>
              )}
            </div>
          </div>
          <div className="flex flex-1 flex-col text-sm">
            {!!inputComponents.length && (
              <div className="mb-6 flex flex-col space-y-6">
                <div className="font-medium text-gray-500">CONTEXT</div>
                <form className="flex-1 space-y-6">{inputComponents}</form>
              </div>
            )}
            {output && (
              <div className="flex flex-col space-y-4 border-t border-gray-300 py-6">
                <div className="font-medium text-gray-500">OUTPUT</div>
                <div className="flex flex-col rounded-lg border border-gray-300 bg-indigo-50">
                  <div className="flex flex-row justify-end space-x-3 px-3 py-2 text-gray-500">
                    <FontAwesomeIcon
                      icon={faThumbsUp}
                      size="lg"
                      className={`cursor-pointer ${
                        vote === 1 ? "text-blue-500" : "hover:text-gray-600"
                      }`}
                      onClick={() => handleRatingClick(1)}
                    />
                    <FontAwesomeIcon
                      icon={faThumbsUp}
                      rotation={180}
                      size="lg"
                      className={`cursor-pointer ${
                        vote === -1 ? "text-blue-500" : "hover:text-gray-600"
                      }`}
                      onClick={() => handleRatingClick(-1)}
                    />
                  </div>
                  <textarea
                    className="p-2.5 text-xs outline-none"
                    rows={10}
                    value={output}
                    onChange={(e: any) => {
                      const value = e.target.value;
                      sessionDataHash[flowAlias][flowStep.referenceId][
                        "finalOutput"
                      ] = value;
                      setOutput(value);
                    }}
                  />
                  <div className="flex flex-row justify-end space-x-3 px-3 py-2 text-sm">
                    <div
                      className="flex cursor-pointer items-center justify-center space-x-2 rounded-xl border border-modelit-purple px-3 py-1.5 font-medium text-modelit-purple hover:border-indigo-700 hover:text-indigo-700"
                      onClick={() => {
                        if (window.innerWidth < 1024) {
                          setShowForm(false);
                        }
                        editor?.commands.insertContent(output);
                      }}
                    >
                      <span>Add to editor</span>
                    </div>
                    <button
                      onClick={handleSubmit(onSubmit)}
                      className="flex flex-row items-center justify-center rounded-xl bg-modelit-purple px-3 py-1.5 text-white hover:bg-indigo-700 disabled:pointer-events-none disabled:opacity-75"
                      disabled={isLoadingPrediction}
                    >
                      <div className="flex flex-row items-center justify-center space-x-2">
                        <span>Regenerate</span>
                        {isLoadingPrediction && <Loading size="sm" />}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            )}
            {!!outputSources?.length && (
              <div className="mb-6 space-y-4">
                <div className="font-medium text-gray-500">SOURCES</div>
                <div className="flex flex-col space-y-2">
                  {outputSources.map((outputSource: any) => (
                    <a
                      href={outputSource.source}
                      className="line-clamp-1 underline"
                      target="_blank"
                    >
                      {outputSource.source_name}
                    </a>
                  ))}
                </div>
              </div>
            )}
            {!output && (
              <div className="my-6 flex flex-1 flex-col justify-end space-x-4 lg:flex-initial lg:flex-row">
                <button
                  onClick={handleSubmit(onSubmit)}
                  className="flex flex-row items-center justify-center rounded-xl bg-modelit-purple px-3 py-2 text-base font-medium text-white hover:bg-indigo-700 disabled:pointer-events-none disabled:opacity-75 lg:py-1.5 lg:text-sm"
                  disabled={isLoadingPrediction}
                >
                  <div className="flex flex-row items-center justify-center space-x-2">
                    <span>Generate</span>
                    {isLoadingPrediction && <Loading size="sm" />}
                  </div>
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      <Modal
        isOpen={isNewVoiceModalOpen}
        onClose={() => setIsNewVoiceModalOpen(false)}
        title="Add to voice"
        size="md"
      >
        <AddDocumentForm
          addDocumentType={AddDocumentType.NEW_VOICE}
          closeModal={() => setIsNewVoiceModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default FlowStepForm;
