import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  DELETE_USER_KNOWLEDGE_DOCUMENT,
  DELETE_VOICE,
  DELETE_VOICE_DOCUMENT,
  DELETE_USER_CONTENT,
  DELETE_USER_INTEGRATION,
} from "../../graphql/mutations";
import { toast } from "sonner";
import Loading from "../utils/Loading";
import { DeleteFormType } from "../../graphql/types";
import {
  GET_KNOWLEDGE_DOCUMENTS,
  GET_VOICE,
  GET_USER_CONTENTS,
  GET_USER_INTEGRATIONS,
} from "../../graphql/queries";

type Props = {
  deletionId: string;
  deleteFormType: DeleteFormType;
  closeModal: () => void;
};

const DeleteForm = ({ deletionId, deleteFormType, closeModal }: Props) => {
  const { id: voiceId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [deleteUserKnowledgeDocument] = useMutation(
    DELETE_USER_KNOWLEDGE_DOCUMENT,
    {
      refetchQueries: [GET_KNOWLEDGE_DOCUMENTS],
      onError(err) {
        setIsLoading(false);
      },
    },
  );
  const [deleteVoice] = useMutation(DELETE_VOICE, {
    onError(err) {
      setIsLoading(false);
    },
  });
  const [deleteVoiceDocument] = useMutation(DELETE_VOICE_DOCUMENT, {
    refetchQueries: [{ query: GET_VOICE, variables: { id: voiceId } }],
    onError(err) {
      setIsLoading(false);
    },
  });
  const [deleteUserContent] = useMutation(DELETE_USER_CONTENT, {
    refetchQueries: [GET_USER_CONTENTS],
    onError(err) {
      setIsLoading(false);
    },
  });
  const [deleteUserIntegration] = useMutation(DELETE_USER_INTEGRATION, {
    refetchQueries: [GET_USER_INTEGRATIONS],
    onError(err) {
      setIsLoading(false);
    },
  });
  const navigate = useNavigate();

  const handleDelete = async () => {
    setIsLoading(true);
    let res;
    let successMessage;
    let navigationUrl;
    switch (deleteFormType) {
      case DeleteFormType.DELETE_KNOWLEDGE_DOC:
        res = await deleteUserKnowledgeDocument({
          variables: {
            input: {
              primaryId: deletionId,
            },
          },
        });
        successMessage = "Knowledge has been deleted.";
        break;
      case DeleteFormType.DELETE_VOICE:
        res = await deleteVoice({
          variables: {
            input: {
              primaryId: deletionId,
            },
          },
        });
        successMessage = "Voice has been deleted.";
        navigationUrl = "/voices";
        break;
      case DeleteFormType.DELETE_VOICE_DOC:
        res = await deleteVoiceDocument({
          variables: {
            input: {
              primaryId: deletionId,
            },
          },
        });
        successMessage = "Source has been removed from this voice.";
        break;
      case DeleteFormType.DELETE_USER_CONTENT:
        res = await deleteUserContent({
          variables: {
            input: {
              primaryId: deletionId,
            },
          },
        });
        successMessage = "Content has been deleted.";
        break;
      case DeleteFormType.DELETE_USER_INTEGRATION:
        res = await deleteUserIntegration({
          variables: {
            input: {
              primaryId: deletionId,
            },
          },
        });
        successMessage = "Integration has been deleted.";
        break;
      default:
        break;
    }
    if (
      res?.data?.deleteUserKnowledgeDocument ||
      res?.data?.deleteVoice ||
      res?.data?.deleteVoiceDocument ||
      res?.data?.deleteUserContent
    ) {
      toast.success(successMessage);
    }
    setIsLoading(false);
    closeModal();
    if (navigationUrl) {
      navigate(navigationUrl);
    }
  };

  let deletionItem = "knowledge";
  if (deleteFormType === DeleteFormType.DELETE_VOICE) {
    deletionItem = "voice";
  } else if (deleteFormType === DeleteFormType.DELETE_VOICE_DOC) {
    deletionItem = "voice document";
  } else if (deleteFormType === DeleteFormType.DELETE_USER_CONTENT) {
    deletionItem = "content";
  } else if (deleteFormType === DeleteFormType.DELETE_USER_INTEGRATION) {
    deletionItem = "integration";
  }

  return (
    <div className="space-y-8 pt-2 text-gray-700">
      <div>Are you sure you want to delete this {deletionItem}?</div>
      <div className="flex flex-row items-end justify-end space-x-3 text-sm">
        <div
          className="flex cursor-pointer rounded-lg border border-modelit-purple px-6 py-2 text-center text-modelit-purple hover:bg-gray-50"
          onClick={closeModal}
        >
          Cancel
        </div>
        <div
          className={`flex cursor-pointer items-center justify-center rounded-lg border border-red-500 bg-red-500 px-6 py-2 text-white hover:border-red-700 hover:bg-red-700 ${
            isLoading && "pointer-events-none opacity-75"
          }`}
          onClick={handleDelete}
        >
          <div className="flex flex-row items-center justify-center space-x-2">
            <span>Delete</span>
            {isLoading && <Loading size="sm" />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteForm;
