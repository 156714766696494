export enum ParameterType {
  STRING = "STRING",
  INTEGER = "INTEGER",
  NUMBER = "NUMBER",
  BOOLEAN = "BOOLEAN",
  DATETIME = "DATETIME",
  VOICE = "VOICE",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  KNOWLEDGE = "KNOWLEDGE",
}

export enum DocumentType {
  BRAND = "BRAND",
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

export enum DocumentSourceType {
  URL = "URL",
  TEXT = "TEXT",
  FILE = "FILE",
  INTEGRATION = "INTEGRATION",
  KNOWLEDGE = "KNOWLEDGE",
}

export enum VisibilityType {
  VISIBLE = "VISIBLE",
  PROTECTED = "PROTECTED",
  HIDDEN = "HIDDEN",
}

export enum AddDocumentType {
  NEW_KNOWLEDGE_DOC = "NEW_KNOWLEDGE_DOC",
  NEW_VOICE = "NEW_VOICE",
  ADD_TO_VOICE = "ADD_TO_VOICE",
}

export enum DeleteFormType {
  DELETE_KNOWLEDGE_DOC = "DELETE_KNOWLEDGE_DOC",
  DELETE_VOICE = "DELETE_VOICE",
  DELETE_VOICE_DOC = "DELETE_VOICE_DOC",
  DELETE_USER_CONTENT = "DELETE_USER_CONTENT",
  DELETE_USER_INTEGRATION = "DELETE_USER_INTEGRATION",
}

export type ParameterSpec = {
  key: string;
  label: string;
  description: string;
  type: ParameterType;
  isArray: boolean;
  isRequired: boolean;
  defaultValue: string;
  placeholder: string;
  prefix: string;
  allowSearch: boolean;
}

export type FlowStepEdge = {
  node: FlowStep;
}

export type FlowStepsConnection = {
  edges: [FlowStepEdge]
}

export type FlowStep = {
  id: string;
  referenceId: string;
  app: string;
  action: string;
  name: string;
  description: string;
  inputSpec: [ParameterSpec]
  outputSpec: [ParameterSpec]
  params: string;
  flow: Flow;
}

export type FlowEdge = {
  node: Flow;
}

export type Flow = {
  id: string;
  alias: string;
  name: string;
  description: string;
  flowStepIds: [string];
  flowSteps: FlowStepsConnection;
  iconString: string;
  iconColor: string;
  createdAt: string;
  updatedAt: string;
}

export type PageInfo = {
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export type KnowledgeDocumentsConnection = {
  edges: [KnowledgeDocumentEdge];
  pageInfo: PageInfo;
}

export type KnowledgeDocumentEdge = {
  node: KnowledgeDocument;
  cursor: string;
}

export type KnowledgeDocument = {
  id: string;
  name: string;
  source: string;
  sourceType: string;
  tags: [UserKnowledgeDocumentTag];
  createdAt: string;
  updatedAt: string;
  userKnowledgeDocument: UserKnowledgeDocument;
}

export type UserKnowledgeDocument = {
  id: string;
  userIntegration: UserIntegration;
}

export type UserKnowledgeDocumentTag = {
  id: string;
  tag: string;
}

export type VoiceDocumentEdge = {
  node: VoiceDocument;
}

export type VoiceDocumentsConnection = {
  edges: [VoiceDocumentEdge];
}

export type VoiceDocument = {
  id: string;
  name: string;
  source: string;
  sourceType: string;
  text: string;
  createdAt: string;
  updatedAt: string;
}

export type VoiceEdge = {
  node: Voice;
}

export type Voice = {
  id: string;
  name: string;
  voiceDocuments: VoiceDocumentsConnection;
  createdAt: string;
  updatedAt: string;
}

export type UserContentEdge = {
  node: UserContent;
}

export type UserContent = {
  id: string;
  userId: string;
  name: string;
  lastFlowStep: FlowStep;
  output: string;
  createdAt: string;
  updatedAt: string;
}

export type FlowStepLogEdge = {
  node: FlowStepLog;
}

export type FlowStepLog = {
  id: string;
  userId: string;
  userContent: UserContent;
  flow: Flow;
  flowStep: FlowStep;
  inputJson: String;
  generatedOutputJson: String;
  finalOutputJson: String;
  rating: Rating;
  createdAt: String;
  updatedAt: String;
}

export type Rating = {
  id: string;
  vote: number;
}

export type AssistantEdge = {
  node: Assistant;
}

export type Assistant = {
  id: string;
  name: string;
  systemMessage: string;
  iconString: string;
  iconColor: string;
  createdAt: string;
  updatedAt: string;
}

export type ChatThreadEdge = {
  node: ChatThread;
}

export type ChatThread = {
  id: string;
  name: string;
  assistant: Assistant;
  chatMessages: ChatMessagesConnection;
  createdAt: string;
  updatedAt: string;
}

export type ChatMessagesConnection = {
  edges: [ChatMessageEdge];
}

export type ChatMessageEdge = {
  node: ChatMessage;
}

export type PredictionSource = {
  chunk?: string;
  source?: string;
  source_type?: string;
  knowledge_doc_id?: string;
  source_name?: string;
}

export type ChatMessage = {
  id?: string;
  displayContent?: string;
  sources?: never[] | [PredictionSource];
  sender?: string;
  createdAt?: string;
  updatedAt?: string;
}

export type IntegrationEdge = {
  node: ChatMessage;
}

export type Integration = {
  id?: string;
  name?: string;
  iconString?: string;
}

export type UserIntegrationsConnection = {
  edges: [UserIntegrationEdge];
  pageInfo: PageInfo;
}

export type UserIntegrationEdge = {
  node: UserIntegration;
}

export type UserIntegration = {
  id?: string;
  accountName?: string;
  integration?: Integration;
  createdAt: string;
  updatedAt: string;
}