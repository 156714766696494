import gql from "graphql-tag";

export const CREATE_FLOW = gql`
  mutation CreateFlow($input: FlowMutationInput!) {
    createFlow(input: $input) {
      id
    }
  }
`;

export const CREATE_KNOWLEDGE_DOCUMENT = gql`
  mutation CreateKnowledgeDocument($input: KnowledgeMutationInput!) {
    createKnowledgeDocument(
      input: $input
    ) {
      id
    }
  }
`;

export const DELETE_USER_KNOWLEDGE_DOCUMENT = gql`
  mutation DeleteUserKnowledgeDocumentMutation($input: DeleteMutationInput!) {
    deleteUserKnowledgeDocument(input: $input) {
      id
    }
  }
`

export const CREATE_VOICE = gql`
  mutation CreateVoice($input: VoiceMutationInput!) {
    createVoice(input: $input) {
      id
    }
  }
`;

export const CREATE_VOICE_DOCUMENT = gql`
  mutation CreateVoiceDocument($input: VoiceDocumentMutationInput!) {
    createVoiceDocument(input: $input) {
      id
    }
  }
`

export const DELETE_VOICE_DOCUMENT = gql`
  mutation DeleteVoiceDocument($input: DeleteMutationInput!) {
    deleteVoiceDocument(input: $input) {
      id
    }
  }
`

export const UPDATE_VOICE = gql`
  mutation UpdateVoice(
    $id: String!,
    $name: String,
    $voiceDocIds: [String],
  ) {
    updateVoice(
      id: $id,
      name: $name,
      voiceDocIds: $voiceDocIds
    ) {
      voice {
        id
        name
        voice_documents {
          source
          source_type
        }
      }
    }
  }
`;

export const DELETE_VOICE = gql`
  mutation DeleteVoice($input: DeleteMutationInput!) {
    deleteVoice(input: $input) {
      id
    }
  }
`;

export const CREATE_PREDICTION = gql`
  mutation CreatePrediction(
    $flowStepId: String!,
    $params: GenericScalar!,
    $userContentId: String,
    $streamChannelId: String,
  ) {
    createPrediction(
      flowStepId: $flowStepId,
      params: $params,
      userContentId: $userContentId,
      streamChannelId: $streamChannelId,
    ) {
      prediction
      flowStepLogId
    }
  }
`



export const CREATE_FLOW_PREDICTION = gql`
  mutation CreateFlowPrediction(
    $flowAlias: String!,
    $params: GenericScalar!,
    $chatThreadId: String,
    $streamChannelId: String,
  ) {
    createFlowPrediction(
      flowAlias: $flowAlias,
      params: $params,
      chatThreadId: $chatThreadId,
      streamChannelId: $streamChannelId,
    ) {
      prediction
    }
  }
`;

export const CREATE_USER_CONTENT = gql`
  mutation CreateUserContent($input: UserContentMutationInput!) {
    createUserContent(
      input: $input
    ) {
      id
      name
      output
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_USER_CONTENT = gql`
  mutation UpdateUserContent($input: UserContentMutationInput!) {
    updateUserContent(input: $input) {
      id
      name
      output
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_USER_CONTENT = gql`
  mutation DeleteUserContent($input: DeleteMutationInput!) {
    deleteUserContent(input: $input) {
      id
    }
  }
`;

export const UPDATE_FLOW_STEP_LOG = gql`
  mutation UpdateFlowStepLog($input: FlowStepLogMutationInput!) {
    updateFlowStepLog(input: $input) {
      id
    }
  }
`;

export const CREATE_UPCOMING_INVOICE = gql`
  mutation CreateUpcomingInvoice($priceId: String!) {
    createUpcomingInvoice(priceId: $priceId) {
      total
      nextPaymentAttempt
      currency
      amountDue
    }
  }
`;

export const CREATE_CHECKOUT_SESSION = gql`
  mutation CreateCheckoutSession($stripePriceId: String!) {
    createCheckoutSession(stripePriceId: $stripePriceId) {
      checkoutUrl
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription($input: SubscriptionMutationInput!) {
    updateSubscription(input: $input) {
      cancelAtPeriodEnd
      errors {
        field
        messages
      }
    }
  }
`;

export const CREATE_SUPPORT_TICKET = gql`
  mutation CreateSupportTicket($input: SupportTicketMutationInput!) {
    createSupportTicket(input: $input) {
      id
      createdAt
      updatedAt
      deletedAt
      isDeleted
      subject
      message
      status
      priority
      ticketType
      createdBy
      errors {
        field
        messages
      }
      clientMutationId
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UserMutationInput!) {
    updateUser(input: $input) {
      requestedCustomFlows
      requestedGoogleDrive
      requestedNotion
      requestedSlack
    }
  }
`;

export const CREATE_CHAT_THREAD = gql`
  mutation CreateChatThread($input: ChatThreadMutationInput!) {
    createChatThread(input: $input) {
      id
    }
  }
`;

export const UPDATE_CHAT_THREAD = gql`
  mutation UpdateChatThread($input: ChatThreadMutationInput!) {
    updateChatThread(input: $input) {
      id
      name
    }
  }
`;

export const CREATE_USER_INTEGRATION = gql`
  mutation CreateUserIntegration($input: UserIntegrationMutationInput!) {
    createUserIntegration(input: $input) {
      id
    }
  }
`;

export const DELETE_USER_INTEGRATION = gql`
  mutation DeleteUserIntegration($input: DeleteMutationInput!) {
    deleteUserIntegration(input: $input) {
      id
    }
  }
`;