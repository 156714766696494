import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faChevronUp,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { GET_KNOWLEDGE_DOCUMENTS } from "../../graphql/queries";
import {
  DocumentSourceType,
  KnowledgeDocumentEdge,
  KnowledgeDocumentsConnection,
  UserIntegrationEdge,
  UserIntegrationsConnection,
} from "../../graphql/types";
import { KnowledgeDocInfo } from "../flowDetail/FlowStepForm";
import Popup from "./Popup";

type Props = {
  popupText: string;
  userIntegrations: UserIntegrationsConnection;
  knowledgeDocuments: KnowledgeDocumentsConnection;
  selectedKnowledgeDocs: KnowledgeDocInfo[];
  handleSelectedKnowledgeDocClick: any;
};

const KnowledgeInputPopup = ({
  popupText,
  userIntegrations,
  knowledgeDocuments,
  selectedKnowledgeDocs,
  handleSelectedKnowledgeDocClick,
}: Props) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [getKnowledgeDocuments, { data: searchedKnowledgeData }] = useLazyQuery(
    GET_KNOWLEDGE_DOCUMENTS,
  );

  const handleSearchInputChange = (e: any) => {
    setSearchInput(e.target.value);
  };

  const handleSearchInputKeyDown = async (e: any) => {
    if (e.keyCode === 13) {
      await getKnowledgeDocuments({
        variables: { search: searchInput },
      });
    }
  };

  const popupFunction = (close: any) => {
    const knowledgeDocInfos: any = [];
    userIntegrations?.edges?.forEach(
      (userIntegrationEdge: UserIntegrationEdge) => {
        knowledgeDocInfos.push({
          name: (
            <div className="flex items-center space-x-1">
              <div className="flex h-4 w-4 items-center">
                <img
                  src={`/integrations/${
                    userIntegrationEdge.node.integration?.iconString ||
                    "google-drive-icon.png"
                  }`}
                />
              </div>
              <span>{userIntegrationEdge.node?.accountName}</span>
            </div>
          ), // not important
          source: userIntegrationEdge.node.id,
          sourceType: DocumentSourceType.INTEGRATION,
        });
      },
    );
    (
      knowledgeDocuments || searchedKnowledgeData?.knowledgeDocuments
    )?.edges?.forEach((knowledgeDocEdge: KnowledgeDocumentEdge) => {
      knowledgeDocInfos.push({
        name: knowledgeDocEdge.node.name,
        source: knowledgeDocEdge.node.id,
        sourceType: DocumentSourceType.KNOWLEDGE,
      });
    });

    return (
      <div className="absolute -top-40 z-50 flex h-40 w-fit flex-col space-y-3 overflow-auto rounded-md border bg-white p-3 text-xs shadow-md">
        <div className="flex w-full flex-row items-center space-x-1 rounded-md border border-gray-300 px-2">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="h-3 w-3 text-gray-300"
          />
          <input
            className="flex-1 rounded-md px-1 py-2 text-xs placeholder-gray-400 outline-none"
            placeholder="Search by name or tag"
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyDown={handleSearchInputKeyDown}
          />
        </div>
        <div>
          {knowledgeDocInfos?.map((knowledgeDocInfo: any, index: number) => {
            const isChecked = !!selectedKnowledgeDocs.find(
              (elem: any) => elem.source === knowledgeDocInfo.source,
            );
            const name = knowledgeDocInfo.name;
            knowledgeDocInfo.name = knowledgeDocInfo.name.toString();
            return (
              <div
                key={index}
                onClick={(e) => {
                  handleSelectedKnowledgeDocClick(
                    knowledgeDocInfo,
                    isChecked,
                    true,
                  );
                  e.preventDefault();
                }}
                className="flex cursor-pointer flex-row items-center rounded-lg py-1 pr-2 hover:bg-gray-50"
              >
                <div className="flex flex-row justify-center py-1 pr-2">
                  <div
                    className={`flex h-3 w-3 items-center justify-center rounded ${
                      isChecked ? "bg-modelit-purple" : "border border-gray-400"
                    }`}
                  >
                    {isChecked && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="h-2 text-white"
                      />
                    )}
                  </div>
                </div>
                <div className="flex">{name}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <Popup popupFunction={popupFunction}>
      <div className="flex cursor-pointer flex-row items-center space-x-1 text-xs font-medium text-modelit-purple hover:text-indigo-700">
        <span>{popupText}</span>
        <FontAwesomeIcon icon={faChevronUp} className="w-3" />
      </div>
    </Popup>
  );
};

export default KnowledgeInputPopup;
