import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { toast } from "sonner";
import debounce from "lodash.debounce";
import { API_URL } from "../config/constants";
import { TrackingEventType, track } from "../utils/trackingService";

const httpLink = createHttpLink({
  uri: `${API_URL}/api/graphql`,
});

const authLink = setContext((queryInfo, { headers }) => {
  const token = localStorage.getItem("modelit-token");
  track(TrackingEventType.GRAPHQL_QUERY, undefined, {
    query: queryInfo.operationName,
    variables: queryInfo.variables,
  });
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(debounce((errorObj) => {
  const { graphQLErrors, networkError } = errorObj;
  if (graphQLErrors && graphQLErrors.length) {
    const message = graphQLErrors[0].message;
    if (message.startsWith("[USER-FRIENDLY]:")) {
      const userFriendlyMessage = message.substring(17);
      console.error(userFriendlyMessage);
      toast.error(userFriendlyMessage);
      track(TrackingEventType.GRAPHQL_ERROR, undefined, {
        query: errorObj.operation.operationName,
        variables: errorObj.operation.variables,
        knownError: userFriendlyMessage,
      });
    } else {
      track(TrackingEventType.GRAPHQL_ERROR, undefined, {
        query: errorObj.operation.operationName,
        variables: errorObj.operation.variables,
        unknownError: message,
      });
      toast.error("Something went wrong. Please try again later.");
    }
  } else if (networkError) {
    console.error(`[Network error]: ${networkError.message}`);
    toast.error("Something went wrong. Please try again later.");
  }
}, 300));

const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          edges: {
            keyArgs: false,
            merge(existing = [], incoming) {
              return [...existing, ...incoming];
            },
          },
        }
      }
    }
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    mutate: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
  },
  credentials: "include",
});

export default client;