import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

type Props = {
  size?: SizeProp;
  color?: string;
  classList?: string;
};

const Loading = ({ size, color, classList }: Props) => {
  return (
    <div
      className={`flex flex-1 items-center justify-center ${classList || ""}`}
    >
      <FontAwesomeIcon
        icon={faSpinner}
        spin
        size={size || "2x"}
        color={color || ""}
      />
    </div>
  );
};

export default Loading;
