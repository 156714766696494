import { useLazyQuery } from "@apollo/client";
import { GET_FLOWS } from "../../graphql/queries";
import { FlowEdge } from "../../graphql/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faMagnifyingGlass,
  faWandSparkles,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Loading from "../utils/Loading";
import { useEffect, useState } from "react";

type Props = {
  getFlow: any;
  setShowPowerFlowList: any;
};

const IconWrapper = ({ children, bgColor }: any) => {
  return (
    <div
      className="mr-3 flex h-5 w-5 items-center justify-center rounded-full"
      style={{ backgroundColor: bgColor || "#17A7FF" }}
    >
      {children}
    </div>
  );
};

const PowerFlowList = ({ getFlow, setShowPowerFlowList }: Props) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [getFlows, { loading, data: flowData }] = useLazyQuery(GET_FLOWS);
  const flowEdges = flowData?.flows?.edges;

  useEffect(() => {
    getFlows();
  }, []);

  const flowList = flowEdges?.map((flowEdge: FlowEdge) => {
    const { id, alias, name, iconString, iconColor } = flowEdge.node;

    const emojiRegex = /\p{Emoji}/u;
    let icon = (
      <IconWrapper bgColor={iconColor}>
        <FontAwesomeIcon
          icon={faWandSparkles}
          color="white"
          className="h-2.5 w-2.5"
        />
      </IconWrapper>
    );
    if (iconString) {
      if (emojiRegex.test(iconString)) {
        icon = (
          <IconWrapper bgColor="white">
            <span className="text-[23px]">{iconString}</span>
          </IconWrapper>
        );
      } else if (iconString.includes(".png")) {
        icon = <img src={`/flows/${iconString}`} className="mr-3 h-5 w-5" />;
      } else {
        icon = (
          <IconWrapper bgColor={iconColor}>
            <FontAwesomeIcon
              icon={iconString as IconProp}
              color="white"
              className="h-2.5 w-2.5"
            />
          </IconWrapper>
        );
      }
    }

    return (
      <div
        key={id}
        className="flex cursor-pointer flex-row items-center rounded-lg p-2 hover:bg-gray-100"
        onClick={() => {
          getFlow({ variables: { alias } });
          setShowPowerFlowList(false);
        }}
      >
        <div className="flex items-center">{icon}</div>
        <div>
          <div className="line-clamp-1">{name}</div>
        </div>
      </div>
    );
  });

  const handleSearchInputChange = (e: any) => {
    setSearchInput(e.target.value);
  };

  const handleSearchInputKeyDown = async (e: any) => {
    if (e.keyCode === 13) {
      await getFlows({
        variables: { search: searchInput },
      });
    }
  };

  return (
    <div className="flex flex-1 flex-col space-y-10 overflow-y-auto px-6 py-4">
      <div className="flex flex-row items-center space-x-4 text-3xl font-medium">
        <button
          onClick={() => setShowPowerFlowList(false)}
          className="flex h-full w-7 cursor-pointer items-center justify-center rounded-lg border border-modelit-purple hover:bg-gray-50"
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="h-4 text-modelit-purple"
          />
        </button>
        <div>All flows</div>
      </div>
      <div className="flex w-full flex-row items-center space-x-1 overflow-hidden rounded-md border border-gray-300 px-2">
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className="h-3 w-3 text-gray-300"
        />
        <input
          className="flex-1 rounded-md px-1 py-2 text-sm placeholder-gray-400 outline-none"
          placeholder="Search by name or tag"
          value={searchInput}
          onChange={handleSearchInputChange}
          onKeyDown={handleSearchInputKeyDown}
        />
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="flex flex-col space-y-2">{flowList}</div>
      )}
    </div>
  );
};

export default PowerFlowList;
