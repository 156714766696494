import { useState } from "react";
import { useForm } from "react-hook-form";
import CreateFlowInputSection from "./CreateFlowStepInputSection";
import CreateFlowMetafieldSection from "./CreateFlowStepMetafieldSection";
import { ParameterType } from "../../graphql/types";

const regexInputPattern = /\{\{(\w+)\}\}/g;
const regexMetafieldPattern = /#\{(\w+)\}/g;

const CreateFlowStepSection = ({ flowStep }: any) => {
  const { referenceId, name, description, params, action } = flowStep;
  const {
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      referenceId,
      name,
      description,
      params,
      action,
    },
  });
  const [inputFieldHash, setInputFieldHash] = useState(flowStep.inputFieldHash);
  const [metafieldHash, setMetafieldHash] = useState(flowStep.metafieldHash);

  const systemPrompt = watch("params.systemPrompt");
  const prompt = watch("params.prompt");

  const handleFieldChange = (e: any) => {
    const { name: fieldName, value } = e.target;
    flowStep[fieldName] = value;
  };

  const handlePromptChange = (e: any) => {
    const { name: fieldName, value } = e.target;
    const systemPromptText =
      fieldName === "params.systemPrompt" ? value : systemPrompt;
    const promptText = fieldName === "params.prompt" ? value : prompt;
    const fullParamsText = systemPromptText + " " + promptText;
    const inputMatches = fullParamsText.match(regexInputPattern);
    const inputFieldHashNewFields =
      inputMatches?.reduce((accum: any, match: any, index: number) => {
        const matchWithoutBrackets = match.substring(2, match.length - 2);
        // @ts-ignore
        accum[matchWithoutBrackets] = inputFieldHash[matchWithoutBrackets] || {
          key: matchWithoutBrackets,
          label: "",
          description: "",
          type: ParameterType.STRING,
          isArray: true,
          isRequired: true,
          defaultValue: "",
          placeholder: "",
          prefix: "",
          ordering: index,
        };
        return accum;
      }, {}) || {};
    const metafieldMatches = fullParamsText.match(regexMetafieldPattern);
    const metafieldHashNewFields =
      metafieldMatches?.reduce((accum: any, match: any) => {
        const matchWithoutBrackets = match.substring(2, match.length - 1);
        // @ts-ignore
        accum[matchWithoutBrackets] = "";
        return accum;
      }, {}) || {};
    flowStep.params = {
      systemPrompt: systemPromptText,
      prompt: promptText,
    };
    flowStep.inputFieldHash = inputFieldHashNewFields;
    flowStep.metafieldHash = metafieldHashNewFields;
    setInputFieldHash(inputFieldHashNewFields);
    setMetafieldHash(metafieldHashNewFields);
  };

  const flowStepFields = [
    {
      key: "referenceId",
      title: "Reference ID",
      input: (
        <input
          className="rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-modelit-navy"
          {...register("referenceId", {
            onChange: handleFieldChange,
            required: true,
          })}
        />
      ),
      required: true,
    },
    {
      key: "name",
      title: "Name",
      input: (
        <input
          className="rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-modelit-navy"
          {...register("name", {
            onChange: handleFieldChange,
            required: true,
          })}
        />
      ),
      required: true,
    },
    {
      key: "description",
      title: "Description",
      input: (
        <textarea
          rows={2}
          className="rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-modelit-navy"
          {...register("description", {
            onChange: handleFieldChange,
          })}
        />
      ),
      required: false,
    },
    {
      key: "action",
      title: "Action",
      input: (
        <input
          className="rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-modelit-navy"
          {...register("action", {
            onChange: handleFieldChange,
          })}
        />
      ),
      required: true,
    },
    {
      key: "system_prompt",
      title: "System Prompt",
      input: (
        <textarea
          rows={4}
          className="rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-modelit-navy"
          {...register("params.systemPrompt", {
            onChange: handlePromptChange,
            required: true,
          })}
        />
      ),
      required: false,
    },
    {
      key: "prompt",
      title: "Prompt",
      input: (
        <textarea
          rows={4}
          className="rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-modelit-navy"
          {...register("params.prompt", {
            onChange: handlePromptChange,
            required: true,
          })}
        />
      ),
      required: true,
    },
  ];

  const errorMessage = (key: string) => {
    // @ts-ignore
    if (!errors[key]) return;
    return (
      <div className="text-right text-sm italic text-red-500">
        This is a required field.
      </div>
    );
  };

  const inputFieldList = Object.values(inputFieldHash);

  return (
    <div className="flex-1 overflow-y-auto">
      <div className="mb-8 space-y-6">
        {flowStepFields.map((flowStepField) => (
          <div key={flowStepField.key} className="flex flex-1 flex-col">
            <label className="mb-1">
              {flowStepField.title}
              {flowStepField.required && (
                <span className="text-red-500">*</span>
              )}
            </label>
            {flowStepField.input}
            {errorMessage(flowStepField.key)}
          </div>
        ))}
      </div>
      {!!inputFieldList.length && (
        <CreateFlowInputSection inputFieldList={inputFieldList} />
      )}
      {!!Object.keys(metafieldHash).length && (
        <CreateFlowMetafieldSection metafieldHash={metafieldHash} />
      )}
    </div>
  );
};

export default CreateFlowStepSection;
