import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AssistantEdge } from "../../graphql/types";
import { GET_ASSISTANTS, GET_CHAT_THREADS } from "../../graphql/queries";
import { CREATE_CHAT_THREAD } from "../../graphql/mutations";
import Icon from "../utils/Icon";

const AssistantList = () => {
  const navigate = useNavigate();
  const [getAssistants, { data: assistantData }] = useLazyQuery(GET_ASSISTANTS);
  const [createChatThread] = useMutation(CREATE_CHAT_THREAD, {
    refetchQueries: [GET_CHAT_THREADS],
  });

  useEffect(() => {
    getAssistants();
  }, []);

  const assistantList = assistantData?.assistants?.edges?.map(
    (assistantEdge: AssistantEdge) => {
      const { id, name, iconString, iconColor } = assistantEdge.node;

      return (
        <div
          key={id}
          className="flex w-full cursor-pointer flex-row items-center rounded-lg border px-3 py-2 hover:shadow-sm"
          onClick={async () => {
            const res = await createChatThread({
              variables: { input: { assistant: id } },
            });
            if (res?.data?.createChatThread?.id) {
              navigate(`/chat/${res.data.createChatThread.id}`);
            }
          }}
        >
          <Icon iconString={iconString} iconColor={iconColor} />
          <div className="line-clamp-1">{name}</div>
        </div>
      );
    },
  );

  return (
    <div className="flex flex-col items-center space-y-4 rounded-xl border px-8 py-6 shadow-sm">
      <span className="text-lg font-semibold">Choose an Assistant</span>
      <div className="flex w-full flex-col space-y-3">{assistantList}</div>
    </div>
  );
};

export default AssistantList;
