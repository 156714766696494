import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_KNOWLEDGE_DOCUMENTS,
  GET_COMPANY_USAGE,
  GET_USER_INTEGRATIONS,
} from "../graphql/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import AddDocumentForm from "../components/modals/AddDocumentForm";
import Loading from "../components/utils/Loading";
import Modal from "../components/utils/Modal";
import Table from "../components/utils/Table";
import {
  AddDocumentType,
  DeleteFormType,
  DocumentSourceType,
  KnowledgeDocumentEdge,
  UserIntegrationEdge,
} from "../graphql/types";
import NoKnowledgeIcon from "../assets/knowledgeBase/no-knowledge-icon.png";
import { timeAgo } from "../utils/textHelpers";
import DeleteForm from "../components/modals/DeleteForm";
import Alert from "../components/utils/Alert";

type TableDataType = {
  rowData: {
    Name: string;
    Source: JSX.Element | string;
    "Last modified": string | undefined;
    Tags: JSX.Element | null;
  };
  rowOnClick?: any;
  ellipsisPopup?: (close: any) => JSX.Element;
}[];

const KnowledgeBasePage = () => {
  const navigate = useNavigate();
  const { userIntegrationId } = useParams();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletionId, setDeletionId] = useState<string>();
  const [deletionFormType, setDeleteFormType] = useState<DeleteFormType>(
    DeleteFormType.DELETE_KNOWLEDGE_DOC,
  );
  const [searchInput, setSearchInput] = useState<string>("");
  const [knowledgeDocumentEdges, setKnowledgeDocumentEdges] = useState<
    KnowledgeDocumentEdge[]
  >([]);
  const [shouldFetchMoreKnowledge, setShouldFetchMoreKnowledge] =
    useState(true);

  const [getKnowledgeDocuments, { loading: loadingKnowledgeDocuments }] =
    useLazyQuery(GET_KNOWLEDGE_DOCUMENTS, {
      onCompleted: (data) => {
        const newKnowledgeDocumentEdges = data.knowledgeDocuments?.edges;
        if (newKnowledgeDocumentEdges) {
          if (!data.knowledgeDocuments?.pageInfo?.hasPreviousPage) {
            setKnowledgeDocumentEdges(newKnowledgeDocumentEdges);
          } else {
            setKnowledgeDocumentEdges([
              ...knowledgeDocumentEdges,
              ...newKnowledgeDocumentEdges,
            ]);
          }
        }
        if (!data.knowledgeDocuments?.pageInfo?.hasNextPage) {
          setShouldFetchMoreKnowledge(false);
        }
      },
      notifyOnNetworkStatusChange: true,
    });
  const [
    getUserIntegrations,
    { loading: loadingUserIntegrations, data: userIntegrationData },
  ] = useLazyQuery(GET_USER_INTEGRATIONS);
  const { data: companyUsageData } = useQuery(GET_COMPANY_USAGE);

  const handleSearchInputChange = (e: any) => {
    setSearchInput(e.target.value);
  };

  const handleSearchInputKeyDown = async (e: any) => {
    if (e.keyCode === 13) {
      await getKnowledgeDocuments({
        variables: { search: searchInput },
      });
    }
  };

  useEffect(() => {
    setKnowledgeDocumentEdges([]);
    if (userIntegrationId) {
      getKnowledgeDocuments({
        variables: { userIntegrationId },
      });
    } else {
      getUserIntegrations();
      getKnowledgeDocuments({
        variables: { excludeIntegrations: true },
      });
    }
  }, [userIntegrationId]);

  const remainingKnowledgeCount =
    companyUsageData?.company?.usage?.knowledgeDocument?.periodLimit !== null &&
    companyUsageData?.company?.usage?.knowledgeDocument?.periodUsage !== null &&
    companyUsageData?.company?.usage?.knowledgeDocument?.periodLimit -
      companyUsageData?.company?.usage?.knowledgeDocument?.periodUsage;

  const tableData: TableDataType = [];
  if (!userIntegrationId) {
    userIntegrationData?.userIntegrations?.edges?.forEach(
      (userIntegrationEdge: UserIntegrationEdge) => {
        tableData.push({
          rowData: {
            Name: userIntegrationEdge.node.accountName || "Untitled",
            Source: (
              <div className="flex h-6 w-6 items-center">
                <img
                  src={`/integrations/${
                    userIntegrationEdge.node.integration?.iconString ||
                    "google-drive-icon.png"
                  }`}
                />
              </div>
            ),
            "Last modified": timeAgo(
              userIntegrationEdge.node.updatedAt ||
                userIntegrationEdge.node.createdAt,
            ),
            Tags: null,
          },
          rowOnClick: () =>
            navigate(`/knowledge/integrations/${userIntegrationEdge.node.id}`),
          ellipsisPopup: (close: any) => (
            <div className="absolute -bottom-1 -right-4 z-40 flex max-w-fit translate-y-full flex-col rounded-md border bg-white p-2 text-sm shadow-md">
              <div
                onClick={() => {
                  close();
                  setDeletionId(userIntegrationEdge?.node?.id);
                  setDeleteFormType(DeleteFormType.DELETE_USER_INTEGRATION);
                  setIsDeleteModalOpen(true);
                }}
                className="flex flex-1 cursor-pointer rounded-md py-2 pl-2 pr-20 hover:bg-gray-50"
              >
                Delete
              </div>
            </div>
          ),
        });
      },
    );
  }

  knowledgeDocumentEdges.forEach((knowledgeDocumentEdge) => {
    let tags = null;
    const numTags = knowledgeDocumentEdge?.node?.tags?.length;
    if (numTags) {
      const firstTag = (
        <span className="rounded-xl border border-gray-300 px-2 py-1">
          {knowledgeDocumentEdge.node.tags[0].tag}
        </span>
      );
      tags =
        numTags > 1 ? (
          <div className="flex flex-row space-x-1">
            {firstTag}
            <span className="rounded-xl border border-gray-300 px-2 py-1">{`+${
              numTags - 1
            }`}</span>
          </div>
        ) : (
          firstTag
        );
    }
    let source: JSX.Element | string = "";

    if (knowledgeDocumentEdge.node.sourceType === DocumentSourceType.TEXT) {
      source = "TEXT";
    } else if (
      knowledgeDocumentEdge.node.sourceType === DocumentSourceType.URL
    ) {
      source = knowledgeDocumentEdge.node.source;
    } else if (
      knowledgeDocumentEdge.node.sourceType === DocumentSourceType.INTEGRATION
    ) {
      source = (
        <div className="flex items-center">
          <div className="flex h-6 w-6 items-center">
            <img
              src={`/integrations/${
                knowledgeDocumentEdge.node.userKnowledgeDocument
                  ?.userIntegration?.integration?.iconString ||
                "google-drive-icon.png"
              }`}
            />
          </div>
          <span>
            {
              knowledgeDocumentEdge.node.userKnowledgeDocument?.userIntegration
                ?.accountName
            }
          </span>
        </div>
      );
    }

    tableData.push({
      rowData: {
        Name: knowledgeDocumentEdge.node.name || "Untitled",
        Source: source,
        "Last modified": timeAgo(
          knowledgeDocumentEdge.node.updatedAt ||
            knowledgeDocumentEdge.node.createdAt,
        ),
        Tags: tags,
      },
      ellipsisPopup: (close: any) => (
        <div className="absolute -bottom-1 -right-5 z-40 flex max-w-fit translate-y-full flex-col rounded-md border bg-white p-2 text-sm shadow-md">
          <div
            onClick={() => {
              close();
              setDeletionId(
                knowledgeDocumentEdge.node.userKnowledgeDocument?.id,
              );
              setDeleteFormType(DeleteFormType.DELETE_KNOWLEDGE_DOC);
              setIsDeleteModalOpen(true);
            }}
            className="flex flex-1 cursor-pointer rounded-md py-2 pl-2 pr-20 hover:bg-gray-50"
          >
            Delete
          </div>
        </div>
      ),
    });
  });

  const showNoKnowledgeMessage =
    !loadingUserIntegrations &&
    !loadingKnowledgeDocuments &&
    !tableData?.length;

  const handleScroll = (e: any) => {
    if (shouldFetchMoreKnowledge) {
      const nearBottom =
        Math.abs(
          e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight),
        ) <= 400;
      if (nearBottom && !loadingKnowledgeDocuments) {
        const cursor =
          knowledgeDocumentEdges[knowledgeDocumentEdges.length - 1].cursor;
        if (userIntegrationId) {
          getKnowledgeDocuments({
            variables: { userIntegrationId, after: cursor },
          });
        } else {
          getUserIntegrations();
          getKnowledgeDocuments({
            variables: { excludeIntegrations: true, after: cursor },
          });
        }
      }
    }
  };

  return (
    <div
      className="flex flex-1 flex-col space-y-6 overflow-auto p-5 lg:p-10"
      onScroll={handleScroll}
    >
      {remainingKnowledgeCount !== false &&
        !isNaN(remainingKnowledgeCount) &&
        remainingKnowledgeCount <= 5 && (
          <Alert
            type={remainingKnowledgeCount <= 2 ? "danger" : "warn"}
            text={`You can add ${remainingKnowledgeCount} more references.`}
          />
        )}
      <div className="flex flex-row items-start justify-between">
        <div className="flex flex-col space-y-1">
          <span className="hidden text-2xl font-medium lg:flex">Knowledge</span>
          <span className="text-sm">
            Upload documents or information you want to reference in your
            outputs.
          </span>
        </div>
        <button
          className="my-3 hidden cursor-pointer rounded-xl bg-modelit-purple px-3 py-2 text-sm text-white hover:bg-indigo-700 lg:flex"
          onClick={() => setIsAddModalOpen(true)}
        >
          Add knowledge
        </button>
      </div>
      <div className="flex flex-1 flex-col space-y-8">
        <div className="flex w-full flex-row items-center space-x-1 overflow-hidden rounded-md border border-gray-300 px-2">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="h-3 w-3 text-gray-300"
          />
          <input
            className="flex-1 rounded-md px-1 py-2 text-sm placeholder-gray-400 outline-none"
            placeholder="Search by name or tag"
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyDown={handleSearchInputKeyDown}
          />
        </div>
        {!!tableData.length && <Table data={tableData} />}
        {(loadingUserIntegrations || loadingKnowledgeDocuments) && <Loading />}
        {showNoKnowledgeMessage && (
          <div className="flex flex-1 flex-col items-center justify-center space-y-1 text-center">
            <img src={NoKnowledgeIcon} className="h-20" />
            <div className="pt-1 text-lg font-medium">
              You haven't added any knowledge yet
            </div>
            <div className="text-gray-600">
              Add knowledge to easily use references when creating content
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        title="Add to knowledge"
        size="md"
      >
        <AddDocumentForm
          addDocumentType={AddDocumentType.NEW_KNOWLEDGE_DOC}
          closeModal={() => setIsAddModalOpen(false)}
        />
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete knowledge"
        size="sm"
      >
        <DeleteForm
          // @ts-ignore
          deletionId={deletionId}
          deleteFormType={deletionFormType}
          closeModal={() => setIsDeleteModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default KnowledgeBasePage;
