// @ts-nocheck
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { VoiceEdge } from "../../graphql/types";
import { CREATE_FLOW_PREDICTION } from "../../graphql/mutations";
import { BubbleMenu, EditorContent } from "@tiptap/react";
import { Editor } from "@tiptap/core";
import EditorPowerFunctionContainer from "../flowDetail/EditorPowerFunctionContainer";
import logoMark from "../../assets/logo-mark.png";

type Props = {
  editor?: Editor | null;
  initialContent?: string | null;
  voiceEdges: [VoiceEdge];
};

const TipTap = ({ editor, initialContent, voiceEdges }: Props) => {
  const [showPowerFunctions, setShowPowerFunctions] = useState(false);
  const [createFlowPrediction, { loading }] = useMutation(
    CREATE_FLOW_PREDICTION,
  );

  const updateSelectedTextWithFlowRes = async (
    flowAlias?: string,
    additionalParams?: any,
  ) => {
    const selectedText = editor?.state.doc.textBetween(
      editor.state.selection.from,
      editor.state.selection.to,
    );
    const createFlowPredictionRes = await createFlowPrediction({
      variables: {
        flowAlias: flowAlias,
        params: { content: selectedText, ...additionalParams },
      },
    });

    const prediction =
      createFlowPredictionRes?.data?.createFlowPrediction?.prediction || {};
    const predictionJSON = JSON.parse(prediction);
    editor
      ?.chain()
      .focus()
      .insertContentAt(
        {
          from: editor.state.selection.from,
          to: editor.state.selection.to,
        },
        predictionJSON.value,
      )
      .run();
  };

  useEffect(() => {
    editor?.commands.setContent(initialContent, {
      parseOptions: {
        preserveWhitespace: "full",
      },
    });
  }, [editor, initialContent]);

  return (
    <div
      id="tiptap"
      className={`flex flex-1 px-4 py-2 ${!!loading && "cursor-progress"}`}
    >
      {editor && (
        <BubbleMenu
          editor={editor}
          tippyOptions={{
            duration: 100,
            onHide: () => setShowPowerFunctions(false),
          }}
        >
          {showPowerFunctions ? (
            <EditorPowerFunctionContainer
              updateSelectedTextWithFlowRes={updateSelectedTextWithFlowRes}
              voiceEdges={voiceEdges}
            />
          ) : (
            <button
              onClick={() => setShowPowerFunctions(true)}
              className="flex flex-row items-center space-x-2 rounded-md border border-gray-200 bg-white p-2 text-xs"
            >
              <img src={logoMark} className="h-3" />
              <span>Ask Modelit</span>
            </button>
          )}
        </BubbleMenu>
      )}
      <div
        id="tiptap-child"
        className={`flex flex-1 ${loading && "pointer-events-none"}`}
      >
        <EditorContent editor={editor} />
      </div>
    </div>
  );
};

export default TipTap;
