import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

type Props = {
  title?: string | null;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  size: "sm" | "md";
};

const Modal = ({ children, isOpen, onClose, title, size }: Props) => {
  if (!isOpen) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 !z-50 flex items-center justify-center">
      <div
        className="fixed bottom-0 left-0 right-0 top-0 flex h-full w-full bg-black opacity-25"
        onClick={onClose}
      />
      <div className="!z-50 w-full lg:w-auto">
        <div
          className={`m-auto flex flex-col space-y-2 rounded-xl bg-white p-6 shadow-md ${
            size === "sm"
              ? "w-[20rem] lg:w-[30rem]"
              : "xs:w-[90%] m-auto w-11/12 sm:w-[600px] md:min-w-[680px]"
          }`}
        >
          <div className="flex flex-row items-start justify-between space-x-4">
            <div className="flex-1 pb-4 font-semibold">{title}</div>
            <div
              onClick={onClose}
              className="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-gray-300 p-2 text-white hover:bg-gray-400"
            >
              <FontAwesomeIcon icon={faX} className="!z-30 h-3 text-white" />
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
