import { useState } from "react";

type Props = {
  popupFunction: any;
  children: React.ReactNode;
};

const Popup = ({ popupFunction, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const close = () => {
    // Strange issue where component doesn't re-render without the timeout
    setTimeout(() => setIsOpen(false), 0);
  };

  return (
    <div>
      <div
        onClick={(e: any) => {
          e.stopPropagation();
          e.preventDefault();
          setIsOpen(true);
        }}
        className="relative"
      >
        {children}
        {isOpen && popupFunction(close)}
      </div>
      {isOpen && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            close();
          }}
          className="fixed bottom-0 left-0 right-0 top-0 z-30 flex h-full w-full opacity-0"
        />
      )}
    </div>
  );
};

export default Popup;
