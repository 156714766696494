import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Toggle from "react-toggle";
import { toast } from "sonner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  GET_COMPANY_SUBSCRIPTION,
  GET_STRIPE_PRODUCTS,
} from "../../graphql/queries";
import Loading from "../utils/Loading";
import Modal from "../utils/Modal";
import InvoiceForm from "../modals/InvoiceForm";
import { CREATE_CHECKOUT_SESSION } from "../../graphql/mutations";

const PlansTab = () => {
  const [isYearly, setIsYearly] = useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [ctaPriceNode, setCtaPriceNode] = useState();
  const [ctaText, setCtaText] = useState<string | null>("");
  const [invoicePlanName, setInvoicePlanName] = useState("");
  const { loading: loadingStripeProducts, data: stripeProductsData } =
    useQuery(GET_STRIPE_PRODUCTS);
  const { loading: loadingCompanySubscription, data: companySubscriptionData } =
    useQuery(GET_COMPANY_SUBSCRIPTION);
  const [createCheckoutSession] = useMutation(CREATE_CHECKOUT_SESSION);

  if (loadingStripeProducts || loadingCompanySubscription) return <Loading />;

  const navigateToStripeCheckout = async (stripePriceId: string) => {
    const res = await createCheckoutSession({
      variables: { stripePriceId },
    });
    if (res?.data?.createCheckoutSession?.checkoutUrl) {
      window.location.replace(res.data.createCheckoutSession.checkoutUrl);
    } else {
      toast.error("Something went wrong. Please try again later.");
    }
  };

  const activeProductId =
    companySubscriptionData?.company?.activePrice?.product?.id;
  let foundCurrentPlan = false;

  const planList = stripeProductsData?.products?.edges?.map(
    (productEdge: any, index: number) => {
      const { id, name, prices, features } = productEdge.node;
      const isBusinessNode = name === "Business";
      const monthlyPriceEdge = prices.edges.find(
        (priceEdge: any) => priceEdge.node.recurring.interval === "month",
      );
      const monthlyPriceAmount = Math.floor(
        monthlyPriceEdge?.node.unitAmount / 100,
      );
      const yearlyPriceEdge = prices.edges.find(
        (priceEdge: any) => priceEdge.node.recurring.interval === "year",
      );
      const yearlyPriceAmount = Math.floor(
        yearlyPriceEdge?.node.unitAmount / 100,
      );

      let pricingComponent = null;
      if (monthlyPriceAmount === 0) {
        pricingComponent = (
          <div className="flex h-6 items-end text-xl font-semibold leading-none">
            Free
          </div>
        );
      } else if (isBusinessNode) {
        pricingComponent = (
          <div className="flex h-6 items-end text-xl font-semibold leading-none">
            Contact for pricing
          </div>
        );
      } else {
        pricingComponent = (
          <div className="flex h-6 flex-row items-end justify-between">
            <div
              className={`${
                !isYearly || isNaN(yearlyPriceAmount)
                  ? "text-xl"
                  : "text-sm text-gray-500"
              } leading-none transition-all duration-500`}
            >
              {!isNaN(monthlyPriceAmount) && (
                <div>
                  <span className="font-semibold">${monthlyPriceAmount}</span>
                  /month
                </div>
              )}
            </div>
            <div
              className={`${
                isYearly ? "text-xl" : "text-sm text-gray-500"
              } leading-none transition-all duration-500`}
            >
              {!isNaN(yearlyPriceAmount) && (
                <div>
                  <span className="font-semibold">${yearlyPriceAmount}</span>
                  /year
                </div>
              )}
            </div>
          </div>
        );
      }

      const validFeatures = features || monthlyPriceEdge?.node?.features;
      const featureList = validFeatures?.map((feature: any, index: number) => (
        <div
          key={index}
          className="flex items-center space-x-2 text-xs font-medium"
        >
          <FontAwesomeIcon icon={faCheck} className="h-3 text-green-500" />
          <span>{feature.name}</span>
        </div>
      ));

      const isCurrentPlan = activeProductId === id;
      foundCurrentPlan = foundCurrentPlan || isCurrentPlan;
      const ctaPriceNode = isYearly
        ? yearlyPriceEdge?.node
        : monthlyPriceEdge?.node;
      let ctaText: string | null = null;
      if (isCurrentPlan) {
        ctaText = "Current plan";
      } else if (isBusinessNode) {
        ctaText = "Book demo now";
      } else {
        ctaText = `${
          !foundCurrentPlan ? "Downgrade" : "Upgrade"
        } to ${name.toLowerCase()}`;
      }

      return (
        <div key={index} className="flex flex-1 flex-col rounded-lg border p-4">
          <div className="mb-4 border-b pb-4 font-semibold">{name}</div>
          <div className="mb-5">{pricingComponent}</div>
          <button
            onClick={() => {
              if (isBusinessNode) {
                window.open(
                  "https://calendly.com/modelitai/modelit-demo",
                  "_blank",
                );
              } else if (companySubscriptionData?.company?.subscription) {
                setCtaPriceNode(ctaPriceNode);
                setCtaText(ctaText);
                setInvoicePlanName(name);
                setIsInvoiceModalOpen(true);
              } else {
                navigateToStripeCheckout(ctaPriceNode?.id);
              }
            }}
            className={`${
              isCurrentPlan
                ? "pointer-events-none bg-gray-100 text-black"
                : "cursor-pointer bg-modelit-purple text-white hover:bg-indigo-700"
            } mb-4 rounded-lg py-2 text-sm font-medium`}
          >
            {ctaText}
          </button>
          <div className="space-y-4">
            <div className="text-sm text-gray-500">INCLUDED</div>
            {featureList}
          </div>
        </div>
      );
    },
  );

  return (
    <div className="space-y-4">
      <div className="flex justify-end">
        <div className="flex items-center space-x-2 text-xs font-medium">
          <div>Monthly</div>
          <Toggle
            defaultChecked={isYearly}
            icons={false}
            onChange={(e) => setIsYearly(e.target.checked)}
          />
          <div>Yearly</div>
        </div>
      </div>
      <div className="grid flex-1 auto-rows-min gap-4 pb-8 sm:grid-cols-2 xl:grid-cols-3">
        {planList}
      </div>
      <Modal
        isOpen={isInvoiceModalOpen}
        onClose={() => setIsInvoiceModalOpen(false)}
        title="Review changes"
        size="sm"
      >
        <InvoiceForm
          priceNode={ctaPriceNode}
          planName={invoicePlanName}
          ctaText={ctaText}
          isYearly={isYearly}
          closeModal={() => setIsInvoiceModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default PlansTab;
