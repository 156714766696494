import CreateFlowMetafieldRow from "./CreateFlowStepMetafieldRow";

const CreateFlowMetafieldSection = ({ metafieldHash }: any) => {
  return (
    <div className="flex flex-col border-t border-dashed">
      {Object.keys(metafieldHash).map((metafieldKey: any) => (
        <CreateFlowMetafieldRow
          key={metafieldKey}
          metafieldHash={metafieldHash}
          metafieldKey={metafieldKey}
        />
      ))}
    </div>
  );
};

export default CreateFlowMetafieldSection;
