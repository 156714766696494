import CreateFlowInputRow from "./CreateFlowStepInputRow";

const CreateFlowInputSection = ({ inputFieldList }: any) => {
  return (
    <div className="flex flex-col border-t border-dashed">
      {inputFieldList.map((inputFieldProps: any) => (
        <CreateFlowInputRow
          key={inputFieldProps.key}
          inputFieldProps={inputFieldProps}
        />
      ))}
    </div>
  );
};

export default CreateFlowInputSection;
