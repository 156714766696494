import LoginForm from "../components/login/LoginForm";
import IntroCarousel from "../components/login/IntroCarousel";

const LoginPage = () => {
  return (
    <div className="flex h-full flex-1 flex-col lg:flex-row">
      <LoginForm />
      <IntroCarousel />
    </div>
  );
};

export default LoginPage;
