import { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import { KnowledgeDocInfo } from "./FlowStepForm";
import {
  GET_KNOWLEDGE_DOCUMENTS,
  GET_USER_INTEGRATIONS,
} from "../../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import {
  AddDocumentType,
  DocumentSourceType,
  KnowledgeDocumentEdge,
} from "../../graphql/types";
import KnowledgeInputPopup from "../utils/KnowledgeInputPopup";
import Modal from "../utils/Modal";
import AddDocumentForm from "../modals/AddDocumentForm";

type Props = {
  searchBar?: JSX.Element | null;
  selectedKnowledgeDocs: KnowledgeDocInfo[];
  handleSelectedKnowledgeDocClick: any;
  isArray?: boolean;
  placeholder?: string;
};

const KnowledgeInput = ({
  searchBar,
  selectedKnowledgeDocs,
  handleSelectedKnowledgeDocClick,
  isArray,
  placeholder,
}: Props) => {
  const [isNewKnowledgeModalOpen, setIsNewKnowledgeModalOpen] = useState(false);

  const [getUserIntegrations, { data: userIntegrationData }] = useLazyQuery(
    GET_USER_INTEGRATIONS,
  );
  const [getKnowledgeDocuments, { data: knowledgeData }] = useLazyQuery(
    GET_KNOWLEDGE_DOCUMENTS,
  );

  useEffect(() => {
    getUserIntegrations();
    getKnowledgeDocuments({
      variables: { excludeIntegrations: true },
    });
  }, []);

  if (!isArray) {
    const knowledgeOptions = knowledgeData?.knowledgeDocuments?.edges?.map(
      (knowledgeEdge: KnowledgeDocumentEdge) => ({
        value: knowledgeEdge.node.id,
        label: knowledgeEdge.node.name,
      }),
    );
    const defaultKnowledgeOption = knowledgeOptions?.find(
      (knowledgeOption: any) =>
        knowledgeOption.value === selectedKnowledgeDocs?.[0]?.source,
    );
    return (
      <div className="flex flex-row items-center justify-center space-x-4">
        <CreatableSelect
          isClearable
          options={knowledgeOptions}
          placeholder={placeholder || "Enter URL or select from knowledge base"}
          classNames={{
            container: (state) => "flex-1",
            option: (state) => "!text-modelit-navy",
          }}
          onChange={(e: any) => {
            handleSelectedKnowledgeDocClick(
              e && {
                source: e.value,
                sourceType: e.__isNew__
                  ? DocumentSourceType.URL
                  : DocumentSourceType.INTEGRATION,
              },
              false,
              false,
            );
          }}
          defaultValue={defaultKnowledgeOption}
        />
        <div
          className="cursor-pointer rounded-lg border border-dashed border-gray-400 bg-white px-2 py-1 text-gray-400 hover:border-gray-500 hover:text-gray-500"
          onClick={() => setIsNewKnowledgeModalOpen(true)}
        >
          <FontAwesomeIcon icon={faPlus} />
        </div>
        <Modal
          isOpen={isNewKnowledgeModalOpen}
          onClose={() => setIsNewKnowledgeModalOpen(false)}
          title="Add to knowledge"
          size="md"
        >
          <AddDocumentForm
            addDocumentType={AddDocumentType.NEW_KNOWLEDGE_DOC}
            closeModal={() => setIsNewKnowledgeModalOpen(false)}
          />
        </Modal>
      </div>
    );
  }

  const selectedKnowledgeDocComponents = selectedKnowledgeDocs.map(
    (selectedKnowledgeDoc: KnowledgeDocInfo, index: number) => (
      <div key={index} className="flex flex-row items-center space-x-2">
        <div
          onClick={() =>
            handleSelectedKnowledgeDocClick(selectedKnowledgeDoc, true, true)
          }
          className="flex h-4 w-4 items-center justify-center rounded bg-modelit-purple hover:bg-indigo-700"
        >
          <FontAwesomeIcon icon={faCheck} className="h-3 text-white" />
        </div>
        <a
          href={selectedKnowledgeDoc.source}
          target="_blank"
          className="text-sm underline"
        >
          {selectedKnowledgeDoc.name}
        </a>
      </div>
    ),
  );

  return (
    <div className="flex flex-col space-y-3">
      {searchBar}
      <KnowledgeInputPopup
        popupText="Add from my knowledge base"
        userIntegrations={userIntegrationData?.userIntegrations}
        knowledgeDocuments={knowledgeData?.knowledgeDocuments}
        selectedKnowledgeDocs={selectedKnowledgeDocs}
        handleSelectedKnowledgeDocClick={handleSelectedKnowledgeDocClick}
      />
      <div className="flex flex-col space-y-3">
        {selectedKnowledgeDocComponents}
      </div>
    </div>
  );
};

export default KnowledgeInput;
