// @ts-nocheck
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GET_VOICE } from "../graphql/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../components/utils/Modal";
import AddDocumentForm from "../components/modals/AddDocumentForm";
import Table from "../components/utils/Table";
import Loading from "../components/utils/Loading";
import {
  AddDocumentType,
  DeleteFormType,
  Voice,
  VoiceDocumentEdge,
} from "../graphql/types";
import { timeAgo } from "../utils/textHelpers";
import DeleteForm from "../components/modals/DeleteForm";
import Popup from "../components/utils/Popup";

const VoiceDetailPage = () => {
  const { id } = useParams();
  const [voice, setVoice] = useState<Voice>();
  const [isAddSourceModalOpen, setIsAddSourceModalOpen] = useState(false);
  const [isDeleteVoiceModalOpen, setIsDeleteVoiceModalOpen] = useState(false);
  const [isDeleteVoiceDocModalOpen, setIsDeleteVoiceDocModalOpen] =
    useState(false);
  const [deletionId, setDeletionId] = useState<string>();
  const [getVoice, { loading }] = useLazyQuery(GET_VOICE);
  const navigate = useNavigate();

  useEffect(() => {
    getVoice({
      variables: { id },
      onCompleted: async (res) => {
        const voice = res?.voice;
        if (!voice) {
          navigate("/voices");
        }
        setVoice(voice);
      },
    });
  }, []);

  const tableData = voice?.voiceDocuments?.edges.map(
    (voiceDocument: VoiceDocumentEdge, index: number) => ({
      rowData: {
        Name: voiceDocument.node.name || "Untitled",
        Type: voiceDocument.node.sourceType,
        "Last modified": timeAgo(
          voiceDocument.node.updatedAt || voiceDocument.node.createdAt,
        ),
      },
      rowOnClick: null,
      ellipsisPopup: (close: any) => (
        <div className="absolute -bottom-1 -right-5 z-40 flex max-w-fit translate-y-full flex-col rounded-md border bg-white p-2 text-sm shadow-md">
          <div
            onClick={() => {
              close();
              setDeletionId(voiceDocument.node.id);
              setIsDeleteVoiceDocModalOpen(true);
            }}
            className="flex flex-1 cursor-pointer rounded-md py-2 pl-2 pr-20 hover:bg-gray-50"
          >
            Delete
          </div>
        </div>
      ),
    }),
  );

  return (
    <div className="flex flex-1 flex-col overflow-auto px-6 py-8 lg:p-10">
      <div className="mb-14 flex items-start justify-between">
        <div className="flex flex-row items-center space-x-3 font-medium">
          <span
            onClick={() => navigate("/voices")}
            className="cursor-pointer text-2xl text-gray-500"
          >
            Voices
          </span>
          <FontAwesomeIcon
            icon={faChevronRight}
            className="h-4 text-gray-500"
          />
          <span className="text-2xl">{voice?.name}</span>
          <Popup
            popupFunction={(close: any) => (
              <div className="absolute -bottom-1 -right-4 z-40 flex max-w-fit translate-y-full flex-col rounded-md border bg-white p-2 text-sm shadow-md">
                <div
                  onClick={() => {
                    close();
                    setIsDeleteVoiceModalOpen(true);
                  }}
                  className="flex flex-1 cursor-pointer rounded-md py-2 pl-2 pr-20 hover:bg-gray-50"
                >
                  Delete
                </div>
              </div>
            )}
          >
            <FontAwesomeIcon
              icon={faEllipsisVertical}
              className="cursor-pointer text-gray-500 hover:text-gray-700"
            />
          </Popup>
        </div>
        <button
          className="hidden cursor-pointer rounded-xl bg-modelit-purple px-3 py-2 text-sm text-white hover:bg-indigo-700 lg:flex"
          onClick={() => setIsAddSourceModalOpen(true)}
        >
          Add a source
        </button>
      </div>
      {loading ? <Loading /> : <Table data={tableData} />}
      <Modal
        isOpen={isAddSourceModalOpen}
        onClose={() => setIsAddSourceModalOpen(false)}
        title="Add to voice"
      >
        <AddDocumentForm
          addDocumentType={AddDocumentType.ADD_TO_VOICE}
          closeModal={() => setIsAddSourceModalOpen(false)}
        />
      </Modal>
      <Modal
        isOpen={isDeleteVoiceModalOpen}
        onClose={() => setIsDeleteVoiceModalOpen(false)}
        title="Delete voice"
      >
        <DeleteForm
          deletionId={id}
          deleteFormType={DeleteFormType.DELETE_VOICE}
          closeModal={() => setIsDeleteVoiceModalOpen(false)}
        />
      </Modal>
      <Modal
        isOpen={isDeleteVoiceDocModalOpen}
        onClose={() => setIsDeleteVoiceDocModalOpen(false)}
        title="Delete voice document"
      >
        <DeleteForm
          deletionId={deletionId}
          deleteFormType={DeleteFormType.DELETE_VOICE_DOC}
          closeModal={() => setIsDeleteVoiceDocModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default VoiceDetailPage;
