// @ts-nocheck
import { useRef, useState } from "react";

const DragDropFile = ({ field, accept }) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      field.onChange({
        target: { value: e.dataTransfer.files[0], name: field.name },
      });
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      field.onChange({
        target: { value: e.target.files[0], name: field.name },
      });
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = (e) => {
    inputRef.current.click();
    e.preventDefault();
  };

  return (
    <div id="form-file-upload" onDragEnter={handleDrag}>
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        accept={accept}
        onChange={handleChange}
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={`${dragActive ? "drag-active" : ""} flex flex-col space-y-4`}
      >
        {field.value && <div>{field.value.name}</div>}
        <div>
          <p>Drag and drop your file here or</p>
          <button className="upload-button" onClick={onButtonClick}>
            Upload a file
          </button>
        </div>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </div>
  );
};

export default DragDropFile;
