import { createContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import {
  getAuth,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  User,
} from "firebase/auth";
import * as amplitude from "@amplitude/analytics-browser";
import jwt_decode from "jwt-decode";
import { API_URL } from "../config/constants";
import throttle from "lodash.throttle";
import { useLazyQuery } from "@apollo/client";
import { GET_USER } from "../graphql/queries";
import { TrackingEventType, track } from "../utils/trackingService";

type ContextType = {
  user: any;
  firebaseUser: any;
  isAdmin: boolean;
  logIn: () => void;
  logOut: () => void;
};

// @ts-ignore
export const AuthContext = createContext<ContextType>(null);

type Props = {
  children: React.ReactNode;
};

const provider = new GoogleAuthProvider();

const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = useState();
  const [firebaseUser, setFirebaseUser] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [getUser, { data: userData }] = useLazyQuery(GET_USER);

  const urlParams = new URLSearchParams(window.location.search);
  const redirectURI = urlParams.get("redirect_uri");
  const oauthState = urlParams.get("state");

  const auth = getAuth();
  const identifyEvent = new amplitude.Identify();

  const logIn = () => {
    track(TrackingEventType.ACTION, "LoginPage", {
      actionName: "login",
    });
    signInWithPopup(auth, provider);
  };

  const logOut = () => {
    track(TrackingEventType.ACTION, "LoginPage", {
      actionName: "logout",
    });
    localStorage.removeItem("modelit-token");
    // @ts-ignore
    setFirebaseUser(null);
    setIsAdmin(false);
    auth.signOut();
    window.location.href = "/login";
  };

  useEffect(() => {
    setUser(userData?.getUser);
  }, [userData]);

  const useStateChangeHandler = async ({ userRes }: { userRes: User }) => {
    const idToken = await userRes.getIdToken();
    const result = await axios.post(
      `${API_URL}/api/login/`,
      { code: idToken },
      { withCredentials: true },
    );
    const token = result.data.token;
    localStorage.setItem("modelit-token", token);
    const decodedToken = jwt_decode(token);

    // @ts-ignore
    amplitude.setUserId(decodedToken.user_id);
    identifyEvent.set("firebaseUid", userRes.uid);
    identifyEvent.set("email", userRes.email || "");

    await getUser();
    // @ts-ignore
    setFirebaseUser(userRes);
    // @ts-ignore
    setIsAdmin(decodedToken.role === "ADMIN");

    if (redirectURI && oauthState) {
      window.location.replace(
        `${redirectURI}?code=${idToken}&state=${oauthState}`,
      );
    }
  };

  const throttledChangeHandler = useMemo(
    () => throttle(useStateChangeHandler, 1000),
    [],
  );

  onAuthStateChanged(auth, async (userRes) => {
    if (userRes && !firebaseUser) {
      throttledChangeHandler({
        userRes,
      });
    } else if (!userRes) {
      localStorage.removeItem("modelit-token");
    }
  });

  return (
    <AuthContext.Provider
      value={{ user, firebaseUser, isAdmin, logIn, logOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
