import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faWandSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconWrapper = ({ children, bgColor, isResponsive }: any) => {
  return (
    <div
      className={`mr-3 flex h-7 w-7 items-center justify-center rounded-full ${
        isResponsive && "sm:h-10 sm:w-10 md:h-6 md:w-6"
      }`}
      style={{ backgroundColor: bgColor || "#17A7FF" }}
    >
      {children}
    </div>
  );
};

type Props = {
  iconString: string;
  iconColor?: string;
  isResponsive?: boolean;
};

const Icon = ({ iconString, iconColor, isResponsive }: Props) => {
  const emojiRegex = /\p{Emoji}/u;
  let icon = (
    <IconWrapper bgColor={iconColor} isResponsive={isResponsive}>
      <FontAwesomeIcon
        icon={faWandSparkles}
        color="white"
        className={`h-3.5 w-3.5 ${
          isResponsive && "sm:h-6 sm:w-6 md:h-3.5 md:w-3.5"
        }`}
      />
    </IconWrapper>
  );
  if (iconString) {
    if (emojiRegex.test(iconString)) {
      icon = (
        <IconWrapper bgColor="white">
          <span
            className={`text-[28px] ${
              isResponsive && "sm:text-4xl md:text-[24px]"
            }`}
          >
            {iconString}
          </span>
        </IconWrapper>
      );
    } else if (iconString.includes(".png")) {
      icon = (
        <img
          src={`/flows/${iconString}`}
          className={`mr-3 h-7 w-7 ${
            isResponsive && "sm:h-9 sm:w-9 md:h-6 md:w-6"
          }`}
        />
      );
    } else {
      icon = (
        <IconWrapper bgColor={iconColor}>
          <FontAwesomeIcon
            icon={iconString as IconProp}
            color="white"
            className={`h-3.5 w-3.5 ${
              isResponsive && "sm:h-6 sm:w-6 md:h-3.5 md:w-3.5"
            }`}
          />
        </IconWrapper>
      );
    }
  }

  return icon;
};

export default Icon;
