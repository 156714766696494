import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_VOICES, GET_COMPANY_USAGE } from "../graphql/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import AddDocumentForm from "../components/modals/AddDocumentForm";
import Loading from "../components/utils/Loading";
import Modal from "../components/utils/Modal";
import Table from "../components/utils/Table";
import { AddDocumentType, DeleteFormType, VoiceEdge } from "../graphql/types";
import NoVoicesIcon from "../assets/voices/no-voices-icon.png";
import { timeAgo } from "../utils/textHelpers";
import DeleteForm from "../components/modals/DeleteForm";
import Alert from "../components/utils/Alert";

const VoicesPage = () => {
  const navigate = useNavigate();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletionId, setDeletionId] = useState<string>();
  const [searchInput, setSearchInput] = useState<string>("");
  const [getVoices, { loading, data: voicesData }] = useLazyQuery(GET_VOICES);
  const { data: companyUsageData, refetch: refetchCompanyUsageData } =
    useQuery(GET_COMPANY_USAGE);

  useEffect(() => {
    getVoices();
  }, []);

  const handleSearchInputChange = (e: any) => {
    setSearchInput(e.target.value);
  };

  const handleSearchInputKeyDown = async (e: any) => {
    if (e.keyCode === 13) {
      await getVoices({
        variables: { search: searchInput },
      });
    }
  };

  const remainingVoicesCount =
    companyUsageData?.company?.usage?.voice?.periodLimit !== null &&
    companyUsageData?.company?.usage?.voice?.periodUsage !== null &&
    companyUsageData?.company?.usage?.voice?.periodLimit -
      companyUsageData?.company?.usage?.voice?.periodUsage;

  const tableData = voicesData?.voices?.edges?.map((voice: VoiceEdge) => ({
    rowData: {
      Name: voice.node.name || "Untitled",
      "Total sources":
        voice.node.voiceDocuments.edges.length === 1
          ? "1 source"
          : `${voice.node.voiceDocuments.edges.length} sources`,
      "Last modified": timeAgo(voice.node.updatedAt || voice.node.createdAt),
    },
    rowOnClick: () => {
      navigate(`/voices/${voice.node.id}`);
    },
    ellipsisPopup: (close: any) => (
      <div className="absolute -bottom-1 -right-5 z-40 flex max-w-fit translate-y-full flex-col rounded-md border bg-white p-2 text-sm shadow-md">
        <div
          onClick={() => {
            close();
            setDeletionId(voice.node.id);
            setIsDeleteModalOpen(true);
          }}
          className="flex flex-1 cursor-pointer rounded-md py-2 pl-2 pr-20 hover:bg-gray-50"
        >
          Delete
        </div>
      </div>
    ),
  }));

  const showNoVoicesMessage = !loading && !tableData?.length;

  return (
    <div className="flex flex-1 flex-col space-y-6 overflow-auto p-5 lg:p-10">
      {remainingVoicesCount !== false &&
        !isNaN(remainingVoicesCount) &&
        remainingVoicesCount <= 5 && (
          <Alert
            type={remainingVoicesCount <= 2 ? "danger" : "warn"}
            text={`You can add ${remainingVoicesCount} more voices.`}
          />
        )}
      <div className="flex flex-row items-start justify-between">
        <div className="flex flex-col space-y-1">
          <span className="hidden text-2xl font-medium lg:flex">Voices</span>
          <span className="text-sm">
            Create unique voices to make sure your content stays true to your
            brand.
          </span>
        </div>
        <button
          className="my-3 hidden cursor-pointer rounded-xl bg-modelit-purple px-3 py-2 text-sm text-white hover:bg-indigo-700 lg:flex"
          onClick={() => setIsCreateModalOpen(true)}
        >
          New voice
        </button>
      </div>
      <div className="flex flex-1 flex-col space-y-8">
        <div className="flex w-full flex-row items-center space-x-1 overflow-hidden rounded-md border border-gray-300 px-2 lg:w-1/2">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="h-3 w-3 text-gray-300"
          />
          <input
            className="flex-1 rounded-md px-1 py-2 text-sm placeholder-gray-400 outline-none"
            placeholder="Search by name or tag"
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyDown={handleSearchInputKeyDown}
          />
        </div>
        {loading ? <Loading /> : <Table data={tableData} />}
        {showNoVoicesMessage && (
          <div className="flex flex-1 flex-col items-center justify-center space-y-1 text-center">
            <img src={NoVoicesIcon} className="h-20" />
            <div className="pt-1 text-lg font-medium">
              You haven't created a voice yet
            </div>
            <div className="text-gray-600">
              Create voices to create on-brand content
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={isCreateModalOpen}
        onClose={() => {
          refetchCompanyUsageData();
          setIsCreateModalOpen(false);
        }}
        title="Create a voice"
        size="md"
      >
        <AddDocumentForm
          addDocumentType={AddDocumentType.NEW_VOICE}
          closeModal={() => {
            refetchCompanyUsageData();
            setIsCreateModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete voice"
        size="sm"
      >
        <DeleteForm
          // @ts-ignore
          deletionId={deletionId}
          deleteFormType={DeleteFormType.DELETE_VOICE}
          closeModal={() => {
            refetchCompanyUsageData();
            setIsDeleteModalOpen(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default VoicesPage;
