import * as amplitude from "@amplitude/analytics-browser";

export enum TrackingEventType {
  ACTION = "action",
  ERROR = "error",
  GRAPHQL_QUERY = "graphqlQuery",
  GRAPHQL_ERROR = "graphqlError",
  NETWORK_ERROR = "networkError",
}

export function track(actionType: TrackingEventType, component?: string, metadata?: {}) {
  amplitude.track(actionType, {
    component,
    ...metadata
  });
}