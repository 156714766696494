import gql from "graphql-tag";

export const GET_FLOWS = gql`
  query GetFlows($nameContains: String, $search: String) {
    flows(name_Contains: $nameContains, search: $search) {
      edges {
        node {
          id
          name
          alias
          description
          iconColor
          iconString
          createdAt
        }
      }
    }
  }
`

export const GET_FLOW = gql`
  query GetFlow($alias: String!) {
    flow(alias: $alias) {
      id
      name
      alias
      description
      flowStepIds
      flowSteps {
        edges {
          node {
            id
            referenceId
            name
            description
            inputSpec
          }
        }
      }
      iconColor
      iconString
      createdAt
    }
  }
`

export const GET_KNOWLEDGE_DOCUMENTS = gql`
  query GetKnowledgeDocuments(
    $nameContains: String,
    $search: String,
    $excludeIntegrations: Boolean,
    $userIntegrationId: UUID
  ) {
    knowledgeDocuments(
      name_Contains: $nameContains,
      search: $search,
      excludeIntegrations: $excludeIntegrations,
      userIntegrationId: $userIntegrationId
    ) {
      edges {
        node {
          id
          name
          source
          sourceType
          createdAt
          updatedAt
          userKnowledgeDocument {
            id
            userIntegration {
              id
              accountName
              integration {
                iconString
              }
            }
          }
        }
        cursor
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_VOICES = gql`
  query GetVoices($nameContains: String, $search: String) {
    voices(name_Contains: $nameContains, search: $search) {
      edges {
        node {
          id
          name
          voiceDocuments {
            edges {
              node {
                id
              }
            }
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`

export const GET_VOICE = gql`
  query GetVoice($id: UUID!) {
    voice(id: $id) {
      id
      name
      voiceDocuments {
        edges {
          node {
            id
            name
            source
            sourceType
            text
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`

export const GET_USER_CONTENTS = gql`
  query GetUserContents($nameContains: String, $search: String) {
    userContents(name_Contains: $nameContains, search: $search) {
      edges {
        node {
          id
          name
          lastFlowStep {
            flow {
              name
            }
          }
          createdAt
          updatedAt  
        }
      }
    }
  }
`

export const GET_USER_CONTENT = gql`
  query GetUserContent($id: UUID!) {
    userContent(id: $id) {
      id
      name
      lastFlowStep {
        id
        flow {
          alias
        }
      }
      output
      createdAt
      updatedAt
    }
  }
`

export const GET_LATEST_FLOW_STEP_LOGS_FOR_CONTENT = gql`
  query getLatestFlowStepLogsForContent($flowAlias: String!, $userContentId: String!) {
    getLatestFlowStepLogsForContent(flowAlias: $flowAlias, userContentId: $userContentId) {
      edges {
        node {
          id
          flowStep {
            referenceId
            flow {
              alias
            }
          }
          inputJson
          generatedOutputJson
          finalOutputJson
          rating {
            id
            vote
          }
        }
      }
    }
  }
`

export const GET_STRIPE_PRODUCTS = gql`
  query GetStripeProducts {
    products {
      edges {
        node {
          id
          name
          description
          features
          prices {
            edges {
              node {
                id
                currency
                unitAmountDecimal
                unitAmount
                recurring
                tiers
                metadata
                features
              }
            }
          }
        }
      }
    }
  }
`

export const GET_COMPANY_USAGE = gql`
  query CompanyUsage {
    company {
      usage {
        aiAction {
          periodUsage
          periodLimit
          currentPeriodEnd
          currentPeriodStart
        }
        knowledgeDocument {
          periodUsage
          periodLimit
          currentPeriodEnd
          currentPeriodStart
        }
        voice {
          periodUsage
          periodLimit
          currentPeriodEnd
          currentPeriodStart
        }
      }
    }
  }
`

export const GET_COMPANY_SUBSCRIPTION = gql`
  query Company {
    company {
      activePrice {
        unitAmount
        id
        product {
          id
        }
      }
      subscription {
        id
        cancelAtPeriodEnd
        status
        trialStart
        trialEnd
        currentPeriodEnd
        currentPeriodStart
        price {
          id
          unitAmount
          unitAmountDecimal
          product {
            id
            name
          }
        }
        plan {
          interval
        }
      }
    }
  }
`

export const GET_USER = gql`
  query GetUser {
    getUser {
      id
      requestedCustomFlows
      requestedGoogleDrive
      requestedNotion
      requestedSlack
    }
  }
`

export const GET_ASSISTANTS = gql`
  query GetAssistants($nameContains: String, $search: String) {
    assistants(name_Contains: $nameContains, search: $search) {
      edges {
        node {
          id
          name
          iconString
          iconColor 
        }
      }
    }
  }
`

export const GET_CHAT_THREADS = gql`
  query GetChatThreads($nameContains: String, $search: String) {
    chatThreads(name_Contains: $nameContains, search: $search) {
      edges {
        node {
          id
          name
          assistant {
            name
            iconString
            iconColor
          }
          createdAt
          updatedAt  
        }
      }
    }
  }
`

export const GET_CHAT_THREAD = gql`
  query GetChatThread($id: UUID!) {
    chatThread(id: $id) {
      id
      name
      assistant {
        name
        iconString
        iconColor
      }
      chatMessages {
        edges {
          node {
            id
            displayContent
            sender
            createdAt
          }
        }
      }
      createdAt
      updatedAt  
    }
  }
`

export const GET_INTEGRATIONS = gql`
  query GetIntegrations($nameContains: String, $search: String) {
    integrations(name_Contains: $nameContains, search: $search) {
      edges {
        node {
          id
          name
          iconString
        }
      }
    }
  }
`

export const GET_USER_INTEGRATIONS = gql`
  query GetUserIntegrations($nameContains: String, $search: String) {
    userIntegrations(name_Contains: $nameContains, search: $search) {
      edges {
        node {
          id
          accountName
          integration {
            name
            iconString
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`