import { useContext, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_CHAT_THREAD,
  GET_KNOWLEDGE_DOCUMENTS,
  GET_USER_INTEGRATIONS,
} from "../../graphql/queries";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { v4 as uuidv4 } from "uuid";
import { CREATE_FLOW_PREDICTION } from "../../graphql/mutations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { ChatMessageEdge } from "../../graphql/types";
import { KnowledgeDocInfo } from "../flowDetail/FlowStepForm";
import KnowledgeInputPopup from "../utils/KnowledgeInputPopup";
import { AuthContext } from "../../providers/AuthProvider";
import Loading from "../utils/Loading";
import Icon from "../utils/Icon";
import { ReactComponent as ChatSubmitIcon } from "../../assets/chat/chat-submit.svg";
import { SOCKETS_URL } from "../../config/constants";

type Props = {
  chatThreadId: string;
  setChatThreadName: any;
};

const ChatThreadWindow = ({ chatThreadId, setChatThreadName }: Props) => {
  const { firebaseUser } = useContext(AuthContext);
  const bottomRef = useRef(null);
  const [chatMessageEdges, setChatMessageEdges] = useState<ChatMessageEdge[]>(
    [],
  );
  const [messageInput, setMessageInput] = useState<string>("");
  const [selectedKnowledgeDocs, setSelectedKnowledgeDocs] = useState<
    KnowledgeDocInfo[]
  >([]);
  const [channelId, setChannelId] = useState("");
  const [isStreamStart, setIsStreamStart] = useState(true);
  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(
    `${SOCKETS_URL}/${channelId}`,
  );

  const [getUserIntegrations, { data: userIntegrationData }] = useLazyQuery(
    GET_USER_INTEGRATIONS,
  );
  const [getKnowledgeDocuments, { data: knowledgeData }] = useLazyQuery(
    GET_KNOWLEDGE_DOCUMENTS,
  );
  const [getChatThread, { data: chatThreadData }] =
    useLazyQuery(GET_CHAT_THREAD);
  const [createFlowPrediction, { loading: loadingPrediction }] = useMutation(
    CREATE_FLOW_PREDICTION,
  );

  useEffect(() => {
    getUserIntegrations();
    getKnowledgeDocuments({
      variables: { excludeIntegrations: true },
    });
  }, []);

  useEffect(() => {
    if (lastMessage?.data) {
      const value = JSON.parse(lastMessage.data)?.content?.data?.value;
      if (value) {
        if (isStreamStart) {
          const chatMessagesWithAIResponse = [
            {
              node: {
                displayContent: value,
                sender: "AI",
                sources: [],
              },
            },
            ...chatMessageEdges,
          ];
          setChatMessageEdges(chatMessagesWithAIResponse);
          setIsStreamStart(false);
        } else {
          const lastMessageValue = chatMessageEdges[0].node.displayContent;
          const updatedMessageValue = lastMessageValue + value;
          chatMessageEdges[0].node.displayContent = updatedMessageValue;
          setChatMessageEdges([...chatMessageEdges]);
        }
      }
    }
  }, [lastMessage, readyState]);

  useEffect(() => {
    const asyncHandler = async () => {
      const chatThreadRes = await getChatThread({
        variables: { id: chatThreadId },
      });
      setChatThreadName(chatThreadRes?.data?.chatThread?.name);
      setChatMessageEdges(
        chatThreadRes?.data?.chatThread?.chatMessages?.edges || [],
      );
    };
    asyncHandler();
  }, [chatThreadId]);

  useEffect(() => {
    if (chatMessageEdges.length) {
      // @ts-ignore
      bottomRef.current?.scrollIntoView({
        behavior: "instant",
        block: "end",
      });
    }
  }, [chatMessageEdges.length]);

  useEffect(() => {
    if (channelId && readyState === ReadyState.OPEN) {
      const asyncPrediction = async () => {
        const lastHumanMessage = chatMessageEdges?.[0]?.node?.displayContent;
        const createFlowPredictionRes = await createFlowPrediction({
          variables: {
            flowAlias: "assistant_chat",
            params: {
              content: lastHumanMessage,
              references: selectedKnowledgeDocs,
            },
            chatThreadId,
            streamChannelId: channelId,
          },
        });
        sendJsonMessage({ type: "close" });
        const prediction =
          createFlowPredictionRes?.data?.createFlowPrediction?.prediction || {};
        const predictionJSON = JSON.parse(prediction);
        const chatMessagesWithAIResponse = [
          {
            node: {
              displayContent: predictionJSON.value,
              sources: predictionJSON.sources,
              sender: "AI",
            },
          },
          ...chatMessageEdges,
        ];
        setChatMessageEdges(chatMessagesWithAIResponse);
        setIsStreamStart(false);
      };
      asyncPrediction();
    }
  }, [readyState]);

  const handleChatSubmit = async () => {
    if (!messageInput) return;
    const chatMessagesWithNewUserMessage = [
      {
        node: {
          displayContent: messageInput,
          sender: "Human",
          sources: [],
        },
      },
      ...chatMessageEdges,
    ];
    setChatMessageEdges(chatMessagesWithNewUserMessage);
    setMessageInput("");
    setIsStreamStart(true);
    const newChannelId = uuidv4();
    setChannelId(newChannelId);
  };

  const handleMessageInputKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      handleChatSubmit();
    }
  };

  const handleSelectedKnowledgeDocClick = (
    knowledgeDocInfo: any,
    isChecked: boolean,
  ) => {
    if (isChecked) {
      const foundIndex = selectedKnowledgeDocs.findIndex(
        (selectedKnowledgeDoc: KnowledgeDocInfo) =>
          selectedKnowledgeDoc.source === knowledgeDocInfo.source,
      );
      selectedKnowledgeDocs.splice(foundIndex, 1);
      setSelectedKnowledgeDocs([...selectedKnowledgeDocs]);
    } else {
      setSelectedKnowledgeDocs([...selectedKnowledgeDocs, knowledgeDocInfo]);
    }
  };

  const referencePopupText = selectedKnowledgeDocs.length
    ? `Chatting with ${selectedKnowledgeDocs.length} reference${
        selectedKnowledgeDocs.length > 1 ? "s" : ""
      }`
    : "Chat with references";

  const chatMessageComponents = [];
  for (let i = chatMessageEdges.length - 1; i >= 0; i--) {
    const chatMessageEdge = chatMessageEdges[i];
    const isHuman = chatMessageEdge.node?.sender === "Human";
    let icon = (
      <div className="h-7 w-7">
        <Icon
          iconString={chatThreadData?.chatThread?.assistant?.iconString}
          iconColor={chatThreadData?.chatThread?.assistant?.iconColor}
        />
      </div>
    );
    if (isHuman) {
      icon = firebaseUser ? (
        <img src={firebaseUser.photoURL} className="h-7 w-7 rounded-full" />
      ) : (
        <FontAwesomeIcon
          icon={faUserCircle}
          className="w-7 text-gray-600"
          size="xl"
        />
      );
    }

    const uniquePairs = Array.from(
      new Set(
        chatMessageEdge.node?.sources?.map((obj) =>
          JSON.stringify({ sourceName: obj.source_name, source: obj.source }),
        ),
      ),
      // @ts-ignore
    ).map(JSON.parse);

    chatMessageComponents.push(
      <div
        key={i}
        className={`flex flex-col space-y-4 px-6 py-6 text-sm lg:px-20 ${
          !isHuman && "bg-indigo-50"
        }`}
      >
        <div className="flex flex-row space-x-4">
          {icon}
          <span>{chatMessageEdge.node?.displayContent}</span>
        </div>
        {!!uniquePairs.length && (
          <div className="flex flex-row">
            <div className="mr-4 w-7" />
            <div className="grid grid-cols-3 gap-3">
              {uniquePairs.map((pair) => (
                <div
                  className="line-clamp-1 max-w-[280px] cursor-pointer overflow-hidden rounded border border-gray-400 bg-gray-50 px-2 py-1 hover:bg-gray-100"
                  onClick={() =>
                    pair.source &&
                    window.open(pair.source, "_blank", "noreferrer")
                  }
                >
                  {pair.sourceName}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>,
    );
  }

  return (
    <div className="flex h-full flex-1 flex-col justify-between">
      <div className="flex h-full flex-1 flex-col overflow-y-auto">
        {chatMessageComponents}
        <div ref={bottomRef} />
      </div>
      <div className="space-y-4 px-6 py-6 lg:px-20">
        <div
          className={`flex w-full flex-row items-center space-x-1 overflow-hidden rounded-md border border-gray-300 px-4 ${
            loadingPrediction && "bg-gray-100"
          }`}
        >
          <input
            className="w-full rounded-md px-1 py-2 text-sm placeholder-gray-400 outline-none disabled:pointer-events-none"
            placeholder="Message Modelit"
            value={messageInput}
            disabled={loadingPrediction}
            onChange={(e) => setMessageInput(e.target.value)}
            onKeyDown={handleMessageInputKeyDown}
          />
          {loadingPrediction ? (
            <Loading size="sm" color="#6b7280" />
          ) : (
            <ChatSubmitIcon
              className="h-4 hover:cursor-pointer"
              onClick={handleChatSubmit}
            />
          )}
        </div>
        <KnowledgeInputPopup
          popupText={referencePopupText}
          userIntegrations={userIntegrationData?.userIntegrations}
          knowledgeDocuments={knowledgeData?.knowledgeDocuments}
          selectedKnowledgeDocs={selectedKnowledgeDocs}
          handleSelectedKnowledgeDocClick={handleSelectedKnowledgeDocClick}
        />
      </div>
    </div>
  );
};

export default ChatThreadWindow;
