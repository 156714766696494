import FlowList from "../components/flows/FlowList";

const FlowsPage = () => {
  return (
    <div className="flex flex-1 flex-col space-y-6 overflow-auto p-5 lg:p-10">
      <FlowList />
    </div>
  );
};

export default FlowsPage;
