import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import debounce from "lodash.debounce";
import { useLazyQuery, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronLeft,
  faArrowLeft,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { GET_CHAT_THREADS } from "../graphql/queries";
import { ChatThreadEdge } from "../graphql/types";
import AssistantList from "../components/chat/AssistantList";
import ChatThreadWindow from "../components/chat/ChatThreadWindow";
import { UPDATE_CHAT_THREAD } from "../graphql/mutations";

const ChatPage = () => {
  const navigate = useNavigate();
  const { chatThreadId } = useParams();
  const [chatThreadName, setChatThreadName] = useState("");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [chatThreadSearchInput, setChatThreadSearchInput] =
    useState<string>("");

  const [getChatThreads, { data: chatThreadsData }] =
    useLazyQuery(GET_CHAT_THREADS);
  const [updateChatThread] = useMutation(UPDATE_CHAT_THREAD, {
    refetchQueries: [GET_CHAT_THREADS],
  });

  useEffect(() => {
    const async = async () => {
      const data = await getChatThreads();
      if (!chatThreadId) {
        const latestChatThreadId =
          data?.data?.chatThreads?.edges?.[0]?.node?.id;
        if (latestChatThreadId) {
          navigate(`/chat/${latestChatThreadId}`);
        }
      }
    };
    async();
  }, []);

  const nameChangeHandler = async ({
    updatedName,
  }: {
    updatedName: string;
  }) => {
    if (updatedName) {
      await updateChatThread({
        variables: {
          input: {
            id: chatThreadId,
            name: updatedName,
          },
        },
      });
    }
  };

  const debouncedNameChangeHandler = useMemo(
    () => debounce(nameChangeHandler, 300),
    [chatThreadId],
  );

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleChatThreadSearchInputKeyDown = async (e: any) => {
    if (e.keyCode === 13) {
      await getChatThreads({
        variables: { search: chatThreadSearchInput },
      });
    }
  };

  const chatThreadList = chatThreadsData?.chatThreads?.edges?.map(
    (chatThreadEdge: ChatThreadEdge, index: number) => {
      const selectedStyles =
        chatThreadId === chatThreadEdge?.node?.id
          ? "bg-indigo-100 !text-modelit-purple shadow-sm"
          : "";

      return (
        <li
          key={index}
          onClick={() => navigate(`/chat/${chatThreadEdge?.node?.id}`)}
          className={`flex cursor-pointer flex-row items-center rounded-md py-2 pl-2 hover:bg-gray-100 ${selectedStyles}`}
        >
          <span>{chatThreadEdge?.node?.name}</span>
        </li>
      );
    },
  );

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <div className="flex h-14 flex-row items-center justify-between border-b border-gray-200 bg-white px-6 py-4">
        <div className="-ml-2 flex flex-row items-center space-x-2">
          <FontAwesomeIcon
            icon={faBars}
            className="h-3.5 cursor-pointer text-gray-700 hover:text-black lg:w-0"
            onClick={toggleMobileMenu}
          />
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="w-0 cursor-pointer overflow-hidden text-gray-600 hover:text-black lg:w-3.5"
            onClick={() => navigate("/")}
          />
          <span className="text-lg font-medium">Chat</span>
        </div>
        {chatThreadId && (
          <input
            className="text-center text-gray-600 outline-none"
            onChange={(e) => {
              setChatThreadName(e.target.value);
              debouncedNameChangeHandler({ updatedName: e.target.value });
            }}
            value={chatThreadName}
          />
        )}
        <button
          className="flex cursor-pointer items-center rounded-xl bg-modelit-purple px-3 py-2 text-sm font-medium text-white hover:bg-indigo-700"
          onClick={() => navigate("/chat")}
        >
          New chat
        </button>
      </div>
      <div className="flex flex-1 flex-row overflow-hidden">
        {isMobileMenuOpen && (
          <div
            onClick={toggleMobileMenu}
            className="fixed bottom-0 left-0 right-0 top-0 z-30 bg-black opacity-25"
          />
        )}
        <div
          className={`fixed left-0 top-0 z-50 flex h-full flex-col overflow-y-auto bg-white lg:static lg:w-80 ${
            isMobileMenuOpen ? "w-80" : "w-0"
          } transition-all duration-500`}
        >
          <div className="flex flex-1 flex-col justify-between border-r border-gray-200 p-4">
            <div className="flex flex-1 flex-col">
              <div className="my-3 flex w-full flex-row items-center space-x-1 rounded-md border border-gray-300 px-2">
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className="h-3 w-3 text-gray-300"
                />
                <input
                  className="w-full rounded-md px-1 py-2 text-sm placeholder-gray-400 outline-none"
                  placeholder="Search chats by name"
                  value={chatThreadSearchInput}
                  onChange={(e) => setChatThreadSearchInput(e.target.value)}
                  onKeyDown={handleChatThreadSearchInputKeyDown}
                />
              </div>
              <ul className="space-y-3 py-4">{chatThreadList}</ul>
            </div>
            <div
              className="mb-2 flex cursor-pointer flex-row items-center space-x-1.5 text-sm text-modelit-purple hover:text-indigo-700 lg:hidden"
              onClick={() => navigate("/")}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="h-2.5 cursor-pointer"
              />
              <span>Return to home</span>
            </div>
          </div>
        </div>
        <div className="flex flex-1 overflow-hidden">
          {!chatThreadId ? (
            <div className="flex flex-1 items-center justify-center">
              <AssistantList />
            </div>
          ) : (
            <ChatThreadWindow
              chatThreadId={chatThreadId}
              setChatThreadName={setChatThreadName}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatPage;
