import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import { DocumentSourceType } from "../../graphql/types";

type Props = {
  serperResults: any;
  selectedKnowledgeDocs: any;
  setShowSerperResults: any;
  searchBar: JSX.Element;
  handleSelectedKnowledgeDocClick: any;
};

const SerperResultList = ({
  serperResults,
  selectedKnowledgeDocs,
  setShowSerperResults,
  searchBar,
  handleSelectedKnowledgeDocClick,
}: Props) => {
  const serperResultComponents = serperResults.map(
    (serperResult: any, index: number) => {
      const { title, link, snippet } = serperResult;
      const knowledgeDocInfo = {
        name: title,
        source: link,
        sourceType: DocumentSourceType.URL,
      };
      const isChecked = !!selectedKnowledgeDocs.find(
        (elem: any) => elem.source === link,
      );

      return (
        <a
          key={link}
          href={link}
          target="_blank"
          className="flex cursor-pointer flex-row rounded-lg border border-gray-300 py-1 pr-2 hover:bg-gray-50"
        >
          <div className="flex flex-row justify-center px-3 py-1">
            <div
              onClick={(e) => {
                handleSelectedKnowledgeDocClick(
                  knowledgeDocInfo,
                  isChecked,
                  true,
                );
                e.preventDefault();
              }}
              className={`flex h-4 w-4 items-center justify-center rounded ${
                isChecked ? "bg-modelit-purple" : "border border-gray-400"
              }`}
            >
              {isChecked && (
                <FontAwesomeIcon icon={faCheck} className="h-3 text-white" />
              )}
            </div>
          </div>
          <div className="flex flex-1 flex-col">
            <div className="text-modelit-purple">{`${
              index + 1
            }. ${title}`}</div>
            <div className="text-xs text-gray-600">{link}</div>
            <div className="line-clamp-1 text-sm">{snippet}</div>
          </div>
        </a>
      );
    },
  );

  return (
    <div className="space-y-6">
      <div className="flex flex-row space-x-2">
        <button
          onClick={() => setShowSerperResults(false)}
          className="rounded-xl border border-modelit-purple px-2.5 text-modelit-purple hover:border-indigo-800 hover:text-indigo-800"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        {searchBar}
      </div>
      <div className="flex flex-col space-y-4">
        <div className="text-sm font-medium text-gray-500">RESULTS</div>
        <div className="flex flex-col space-y-4">{serperResultComponents}</div>
      </div>
    </div>
  );
};

export default SerperResultList;
