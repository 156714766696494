import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

type Props = {
  type: "warn" | "danger";
  text: React.ReactNode;
};

const Alert = ({ type, text }: Props) => {
  const navigate = useNavigate();

  return (
    <div
      className={`${
        type === "warn" ? "bg-orange-100" : "bg-red-100"
      } flex items-center space-x-2 rounded-lg p-4 text-sm font-medium`}
      role="alert"
    >
      <FontAwesomeIcon
        icon={type === "warn" ? faExclamationTriangle : faLock}
        className={`${type === "warn" ? "text-orange-400" : "text-red-400"}`}
      />
      <div>
        {text}{" "}
        <a
          onClick={() => {
            navigate(`/settings/plans`);
          }}
          className="cursor-pointer font-semibold text-blue-600 hover:underline"
        >
          Upgrade now
        </a>{" "}
        for unlimited.
      </div>
    </div>
  );
};

export default Alert;
