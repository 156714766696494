import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_FLOWS } from "../../graphql/queries";
import Loading from "../../components/utils/Loading";
import { FlowEdge } from "../../graphql/types";
import Modal from "../utils/Modal";
import RequestFlowCreationForm from "../modals/RequestFlowCreationForm";
import Icon from "../utils/Icon";

type Props = {
  isHomePage?: boolean;
};

const FlowList = ({ isHomePage }: Props) => {
  const navigate = useNavigate();
  const { loading, data } = useQuery(GET_FLOWS);
  const [isRequestFlowCreationModalOpen, setIsRequestFlowCreationModalOpen] =
    useState(false);

  const flowData = data?.flows?.edges;
  const flowEdges = isHomePage ? flowData?.slice(0, 6) : flowData;

  const flowList = flowEdges?.map((flowEdge: FlowEdge) => {
    const { id, alias, name, description, iconString, iconColor } =
      flowEdge.node;

    return (
      <div
        key={id}
        className="flex cursor-pointer flex-row items-center rounded-lg border px-2 py-1 hover:border-modelit-purple hover:shadow-md sm:h-16 md:h-36 md:flex-col md:items-start md:space-y-2 md:p-4"
        onClick={() => navigate(`/flows/${alias}`)}
      >
        <div className="flex items-center">
          <Icon
            iconString={iconString}
            iconColor={iconColor}
            isResponsive={true}
          />
        </div>
        <div>
          <div className="line-clamp-1">{name}</div>
          <div className="line-clamp-1 text-sm text-gray-500 sm:line-clamp-2">
            {description}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="flex flex-1 flex-col">
      <div className="mb-3 flex flex flex-row items-center justify-between space-x-4 sm:mb-4">
        <span className="text-2xl font-medium">
          {isHomePage ? "Shortcuts" : "Flows"}
        </span>
        {isHomePage ? (
          <a
            href="/flows"
            className="mb-1 cursor-pointer text-sm text-modelit-purple hover:underline"
          >
            See all flows
          </a>
        ) : (
          <button
            className="my-3 hidden cursor-pointer rounded-xl bg-modelit-purple px-3 py-2 text-sm text-white hover:bg-indigo-700 lg:flex"
            onClick={() => setIsRequestFlowCreationModalOpen(true)}
          >
            New flow
          </button>
        )}
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-4 sm:pb-8 md:grid-cols-3">
          {flowList}
        </div>
      )}
      <Modal
        isOpen={isRequestFlowCreationModalOpen}
        onClose={() => setIsRequestFlowCreationModalOpen(false)}
        title="Create custom flow"
        size="md"
      >
        <RequestFlowCreationForm />
      </Modal>
    </div>
  );
};

export default FlowList;
