import ContentList from "../components/contentList/ContentList";

const ContentListPage = () => {
  return (
    <div className="flex flex-1 flex-col space-y-6 overflow-auto p-5 lg:p-10">
      <ContentList />
    </div>
  );
};

export default ContentListPage;
