import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const tabData = [
  {
    title: "Plans and billing",
    path: "/plans",
  },
  {
    title: "Integrations",
    path: "/integrations",
  },
  {
    title: "Profile details",
    path: "/profile",
  },
];

const SettingsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;
  const [currentRoute, setCurrentRoute] = useState(pathname);

  useEffect(() => {
    const pathname = location.pathname;
    setCurrentRoute(pathname);
  }, [location]);

  const tabs = tabData.map((tab, index) => (
    <div
      key={index}
      className={`${
        currentRoute.includes(tab.path) &&
        "border-b border-modelit-purple text-modelit-purple"
      } cursor-pointer hover:text-modelit-purple`}
      onClick={() => navigate(`/settings${tab.path}`)}
    >
      {tab.title}
    </div>
  ));

  return (
    <div className="flex flex-1 flex-col overflow-auto p-5 lg:p-10">
      <div className="mb-6 hidden text-2xl font-medium lg:flex">Settings</div>
      <div className="mb-6 flex flex-row space-x-6 text-sm">{tabs}</div>
      <Outlet />
    </div>
  );
};

export default SettingsPage;
