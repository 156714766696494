import FlowList from "../components/flows/FlowList";
import ContentList from "../components/contentList/ContentList";

const HomePage = () => {
  return (
    <div className="flex flex-1 flex-col space-y-6 overflow-auto p-5 sm:space-y-4 lg:p-10">
      <FlowList isHomePage={true} />
      <ContentList isHomePage={true} />
    </div>
  );
};

export default HomePage;
