import { useContext } from "react";
import { useMutation } from "@apollo/client";
import { GET_USER } from "../../graphql/queries";
import { UPDATE_USER } from "../../graphql/mutations";
import { AuthContext } from "../../providers/AuthProvider";
import Loading from "../utils/Loading";

type Props = {
  requestFieldName: string;
};

const RequestForm = ({ requestFieldName }: Props) => {
  const { user } = useContext(AuthContext);

  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    variables: {
      input: {
        [requestFieldName]: true,
      },
    },
    refetchQueries: [GET_USER],
  });

  if (loading) return null;

  return (
    <div className="flex flex-col p-2">
      {user?.[requestFieldName] ? (
        <div className="flex flex-col items-center space-y-6">
          <div className="flex flex-col text-center font-semibold">
            You're on the waitlist!
          </div>
          <div className="text-5xl">🎉</div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center space-y-3">
          <div className="text-center font-semibold">
            Coming soon! Request to be added to waitlist now!
          </div>
          <button
            onClick={() => updateUser()}
            className="flex flex-row items-center justify-center space-x-2 rounded-lg border border-modelit-purple bg-modelit-purple px-4 py-2 font-medium text-white hover:bg-indigo-700 disabled:pointer-events-none disabled:opacity-75"
            disabled={loading}
          >
            <span>Add me to waitlist</span>
            {loading && <Loading size="sm" />}
          </button>
        </div>
      )}
    </div>
  );
};

export default RequestForm;
