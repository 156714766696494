import { useState } from "react";
import WorkflowsImage from "../../assets/login/intro-workflows.svg";
import VoicesImage from "../../assets/login/intro-voices.png";
import CultureImage from "../../assets/login/intro-culture.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const carouselItems = [
  {
    item: (
      <div className="flex h-full items-center justify-center">
        <img src={WorkflowsImage} className="h-56 p-2 md:h-full" />
      </div>
    ),
    title: "Supercharged workflows",
    caption: "Streamline your content creation",
  },
  {
    item: (
      <div className="relative flex h-full items-center justify-center">
        <div className="w-3/4">
          <img src={VoicesImage} className="h-full w-full p-2" />
        </div>
        <img src={CultureImage} className="absolute bottom-0 left-auto p-2" />
      </div>
    ),
    title: "Tailored to you",
    caption:
      "Personalize it with your voice and knowledge to generate content that's uniquely you",
  },
];

const items = carouselItems.map((carouselItem, index) => (
  <div key={index} className="flex h-full items-center justify-center">
    {carouselItem.item}
  </div>
));

const IntroCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentItem = carouselItems[currentIndex];

  const indicators = carouselItems.map((_, index) => (
    <div
      key={index}
      className={`h-1.5 w-1.5 cursor-pointer rounded-full bg-white hover:opacity-75 ${
        index !== currentIndex && "opacity-50"
      }`}
      onClick={() => setCurrentIndex(index)}
    />
  ));

  return (
    <div className="flex flex-1 flex-col items-center justify-center overflow-hidden bg-[radial-gradient(ellipse_at_bottom_right,_var(--tw-gradient-stops))] from-blue-500 to-blue-950">
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        onChange={(index) => setCurrentIndex(index)}
        selectedItem={currentIndex}
        className="flex items-center justify-center overflow-hidden"
      >
        {items}
      </Carousel>
      <div className="flex h-20 max-w-md flex-col items-center space-y-1 text-white md:mt-12">
        <span className="text-2xl font-medium">{currentItem.title}</span>
        <span className="text-center text-sm">{currentItem.caption}</span>
      </div>
      <div className="my-3 flex flex-row space-x-1">{indicators}</div>
    </div>
  );
};

export default IntroCarousel;
